import SiteWrapper from "../../SiteWrapper.react";
import React from "react";
import '../../css/ProjectDetail.css';
import {useFormik} from "formik";
import {Container, Card, Box, TextField, FormControl, InputLabel, Button, Stack, Select, MenuItem, Grid} from "@mui/material";
import {TrueffortButton, TrueffortSelect, TrueffortTextField} from "../../MUIstyle/MUICustom";
import {Save as SaveIcon, Cancel as CancelIcon} from '@mui/icons-material';
import ProjectElementService from "../../services/projectElement.service";
import {toast} from "react-toastify";


export type NewTaskForm = {
    idTaskType: number,
    estimatedDays: number,
    estimatedTime: number
};

export type NewElementForm = {
    idElementType: number,
    name: string,
};

// @ts-ignore
function AddElementForm({t, formState, setFormState, elementTypes, project, _fillProjectInfo}) {
    const projectElementService = React.useMemo(() => new ProjectElementService(), []);

    const validate = (values: NewElementForm) => {
        let errors: any = {};
        if (!values.idElementType) {
            errors.idElementType = "Required";
        }
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            idElementType: 0,
            name: "",
            notes: ""
        },
        validate: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values: any) => {
        let newProjectElement = {
            name: values.name,
            notes: values.notes,
            elementType: {
                idElementType: values.idElementType,
                projectType: {
                    idProjectType: project.idProjectType
                }
            },
            project: {
                idProject: project.idProject
            }
        }
        // @ts-ignore
        projectElementService.create(newProjectElement).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                setFormState({...formState, showFormAddElement: false, isCreating: false});
                toast.success(t("project_page.msg_success_pe_add"));
            }
            _fillProjectInfo();
        });
    }

    const handleCancel = () => {
        formik.resetForm();
        setFormState({...formState, isCreating: false, isEditing: false, showFormAddElement: false})
    }


    return (
        <Card sx={{padding: "12px", marginTop: "1rem", overflow: "unset", marginBottom: "1rem"}} className="background-gray">
            <h6>{t("project_tasks_page.new_element")}</h6>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TrueffortTextField
                    id="idTxNewElementName"
                    size="small"
                    fullWidth
                    sx={{background: "white"}}
                    placeholder={t("project_page.elem_name_placeholder")}
                    labelstring={t("Name")}
                    requiredlabel={true}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                />
              </Grid>
              <Grid item xs={4}>
                <TrueffortSelect
                  id="idSlNewElementType"
                  size="small"
                  fullWidth
                  requiredlabel={true}
                  labelstring={t("project_tasks_page.select_element")}
                  name="idElementType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.idElementType}
                  error={formik.errors?.idElementType && formik.touched?.idElementType ? t(formik.errors.idElementType) : null}
                >
                  {elementTypes.map((elemType: any) =>
                            <MenuItem key={elemType.idElementType} value={elemType.idElementType}>
                                {elemType.name}
                            </MenuItem>
                        )}
                </TrueffortSelect>
              </Grid>
              <Grid item xs={8}>
                <TrueffortTextField
                    id="idNotes"
                    size="small"
                    fullWidth
                    placeholder={t('project_page.elem_notes_placeholder')}
                    labelstring={t('project_page.elem_notes')}
                    name="notes"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    error={formik.errors?.notes && formik.touched?.notes ? t(formik.errors.notes) : null}
                    multiline
                    minRows={2}
                 />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems: 'end', justifyContent:'end'}}>
              <TrueffortButton id="idBtnSaveElement" color="primary" variant="outlined" size="small" onClick={() => formik.handleSubmit()}>
                    <SaveIcon />
                </TrueffortButton>
                <div style={{paddingRight:'0.2em'}}></div>
                <TrueffortButton id="idBtnSaveElement" color="secondary" variant="outlined" size="small" onClick={handleCancel}>
                    <CancelIcon />
                </TrueffortButton>
              </Grid>
            </Grid>
        </Card>
    );
}

export default AddElementForm;