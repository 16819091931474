import React from "react";
import {Card, Stack, Typography, Paper, Box} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import '../../css/Dashboard.css'
import '../../css/hint.min.css'
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import SummaryActivityService from "../../services/summaryActivity.service";
import {dateToDateLocale} from "../../util/GlobalFunctions";
import TableProductivityCard from "./TableProductivityCard";
import CardEmpty from "../custom/CardEmpty";
import { processArrayTimeline } from "../../util/ActivitiesProcessorUtil";
import { useState, useMemo, useEffect } from "react";

function DashboardTimeLine({listAllActivities, listActivitiesOnlyBrowsers, idSelectedProject, showLoader, startDate, endDate, 
    users, selectedUsers, componentDownloadExcel, showProductivityRange, range, idProfile, inputSearch, listCategories, objectUsers, listProfiles, schedulesByUser}) {
  let appsWeb = JSON.parse(localStorage.getItem('INSTANCE_INFO'));
  const idsAppsWeb = appsWeb.applicationsWeb.map(app => app.idApplication);
  const {t} = useTranslation();
  const [idUser, setIdUser] = useState(selectedUsers.length > 0 ? selectedUsers[0].idUser : "");
  const [recordsTimeline, setRecordsTimeline] = useState([]);
  const [recordsTimelineAux, setRecordsTimelineAux] = useState([]);
  const [apexChartDataSeries, setApexChartDataSeries] = useState([{data: []}]);
  const summaryActivityService = useMemo(() => new SummaryActivityService(), []);
  const colors = ["#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a",
      "#ffff99", "#b15928"];

   // LLamar a la función para procesar el arreglo, sin ir al servicio
   useEffect(() => {
    setRecordsTimeline(processArrayTimeline(listCategories, listAllActivities, users,
        idProfile, idUser, inputSearch, objectUsers, listProfiles, 
        schedulesByUser, startDate, endDate, listActivitiesOnlyBrowsers, idsAppsWeb));
  }, [listAllActivities, inputSearch, idUser, idProfile, users]);

  useEffect(() =>{
    let la = [];
    recordsTimeline.forEach(u => {
      if (showProductivityRange) {
        let rangoCalculado = Math.ceil(((u.productiveTime + u.nonProductiveTime) / u.totalTimeRegistred) * 100);
        if (rangoCalculado >= range[0] && rangoCalculado <= range[1]) {
            la.push(u);
        }
      } else {
        la.push(u);
      }
    });
    let listAux = [];
    if (selectedUsers.length > 0) {
      la.forEach(u => {
        let exist = selectedUsers.find(us => us.idUser == u.idUser);
        if (exist) { listAux.push({...u, showMoreProductive:true, showMoreNotProductive:true});}
      });
    } else {
      listAux = la.map(u => {return {...u, showMoreProductive:true, showMoreNotProductive:true}});
    }
    setRecordsTimelineAux(listAux);
    if(selectedUsers.length == 1) { 
      setIdUser(selectedUsers[0].idUser); 
    } else {
      setIdUser("");
    }
    //console.log(listAux);
  }, [recordsTimeline, selectedUsers, showProductivityRange, range]);

  useEffect(() => {
    let start = dateToDateLocale(startDate).toISOString();
    let end = dateToDateLocale(endDate).toISOString();
    if (idUser == "" || start.slice(0, 10) != end.slice(0, 10)) { // TODOS LOS USUARIOS
      processRecordsToApexChart();
    } else {    // UN USUARIO SELECCIONADO
      fillActivitiesDetailByUser();
    }
  }, [recordsTimelineAux]);

  const fillActivitiesDetailByUser = () => {
    let start = dateToDateLocale(startDate).toISOString();
    let end = dateToDateLocale(endDate).toISOString();
    let currentDate = dateToDateLocale(new Date()).toISOString();
    summaryActivityService.findDetailByUser(start.slice(0, 10), end.slice(0, 10), currentDate.slice(0, 10), idUser, idSelectedProject).then(({data: response}) => {
      // PROCESAR LOS REGISTROS DIFERENTE QUE LOS RECORDS TIMELINE
      processRecordsDetailToApexChart(response);
    });
  }

  const processRecordsDetailToApexChart = (arrActvities) => {
    let arrData = [];
    let us = users.find(u => u.idUser == idUser);
    //console.log(arrActvities);
    // TODO: DETERMINAR RELEVANCIA POR CADA REGISTRO
    let arrActvitiesAux = [];
    if (arrActvities.length > 1) {
      arrActvitiesAux.push(arrActvities[0])
      for (let i = 1; i < arrActvities.length; i++) {
        if (arrActvities[i].idApplication == arrActvitiesAux[arrActvitiesAux.length - 1].idApplication && arrActvities[i].startTime <= (arrActvities[i-1].endTime + 3000)) {
          arrActvitiesAux[arrActvitiesAux.length - 1].endTime = arrActvities[i].endTime;
        } else {
          arrActvitiesAux[arrActvitiesAux.length - 1].totalSeconds = (arrActvitiesAux[arrActvitiesAux.length - 1].endTime - arrActvitiesAux[arrActvitiesAux.length - 1].startTime) / 1000;
          arrActvitiesAux.push(arrActvities[i]);
        }
      }
      arrActvitiesAux[arrActvitiesAux.length - 1].totalSeconds = (arrActvitiesAux[arrActvitiesAux.length - 1].endTime - arrActvitiesAux[arrActvitiesAux.length - 1].startTime) / 1000;
    }
    // SE TIENEN QUE AGRUPAR LAS APLICACIONES POR NAME
    let arrGrouped = {};
    for (let {idApplication, applicationName, applicationIconUrl, applicationIdImage, ...fields} of arrActvitiesAux) {
      if (!arrGrouped[applicationName]) arrGrouped[applicationName] = [];
      arrGrouped[applicationName].push({
        ...fields, "idApplication": idApplication
      });
    }
    //console.log(arrGrouped);

    Object.keys(arrGrouped).map(function (key, ind) {
      let arrDataApp = [];
      arrGrouped[key].map((act) => {
        arrDataApp.push({x: us.name + " " + us.lastName, 
          y: [act.startTime, act.startTime + (act.totalSeconds * 1000)], fillColor: colors[ind], name:key});
      });
      arrData.push({name: key, data: arrDataApp});
    });
    setApexChartDataSeries(arrData);
  }

  const processRecordsToApexChart = () => {
    let arrData = [];
    let multipleUsers = recordsTimelineAux.length > 1;
    //let series = [];
    if (!multipleUsers) {
      // LOS REGISTROS NO TIENEN STATTIME/ENDTIME SI SON DE DÍAS ANTERIORES, TIENEN STARTDATE PERO ES UN STRING CON FORMATO aaaa-mm-dd
      recordsTimelineAux.forEach((usr) => {
        usr.listReelevantApplications.forEach((app) => {
          app.activities.forEach((act) => {
            arrData.push({x: usr.name, y: [act.startTime, act.startTime + act.totalSeconds * 1000],
              fillColor: "#78B737", name: usr.name});
          });
        });
        usr.listNonReelevantApplications.forEach((app) => {
          app.activities.forEach((act) => {
            let startDateAsDate = stringAAAAMMDDToDate(act.startDate);
            arrData.push({x: usr.name, y: [act.startTime != null ? act.startTime : startDateAsDate.getTime(),
              act.startTime != null ? (act.startTime + act.totalSeconds * 1000) : (startDateAsDate.getTime() + act.totalSeconds * 1000)],
              fillColor: "#E4555A", name: usr.name});
          });
        });
      });
    } else {
      let dates = [];
      let start = new Date(startDate);
      while (start <= endDate) {
        let dateString = dateToDateLocale(start).toISOString().slice(0,10);
        dates.push({d: dateString, actR: null, actNR: null});
        start.setDate(start.getDate() + 1);
      }
      dates.forEach((obj, ind) => {
        //let s = 0;
        let init = null;
        let last = 0;
        let first = 1690502658928*1000;
        recordsTimelineAux.forEach((usr, i) => {
          //let s = 0;
          usr.listReelevantApplications.forEach((app, j) => {
            app.activities.forEach((act) => {
              if (act.startDate == obj.d) {
                if (init == null) {
                  dates[ind]['act'] = act;
                }
                first = act.startTime < first ? act.startTime : first;
                last = act.endTime > last ? act.endTime : last;
              }
            });
          });
          usr.listNonReelevantApplications.forEach((app) => {
            app.activities.forEach((act) => {
              if (act.startDate == obj.d) {
                if (init == null) {
                  dates[ind]['act'] = act;
                }
                first = act.startTime < first ? act.startTime : first;
                last = act.endTime > last ? act.endTime : last;
              }
            });
          });
        });
        if (dates[ind]['act']) {
          arrData.push({x: "-", y: [first, last], fillColor: "#78B737", name: "-"});
        }
      });
    }
    setApexChartDataSeries([{data: arrData}]);
  }

  /**
   * Convierte una cadena con formato aaaa-mm-dd a Date
   * @param str
   */
  function stringAAAAMMDDToDate(str) {
    var parts = str.split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  let chartData = {
    legend: [],
    options: {
      chart: {
        height: 350,
        type: 'rangeBar',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          autoSelected: 'zoom'
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '90%',
          rangeBarGroupRows: true
        }
      },
      xaxis: {
        type: 'datetime',
        forceNiceScale: true
      },
      legend: {
        position: 'right'
      },
      colors: colors,
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w, y1, y2}) {
          let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const fromDatetime = new Date(y1).toISOString().slice(0, 19);
          const toDatetime = new Date(y2).toISOString().slice(0, 19);
          return (
            "<span style='color: " + data.fillColor + ";font-weight: bold'>" + data.name + "</span>" +
            fromDatetime + "<br> <div style='text-align: center;'>-</div> <br>" + toDatetime + " "
          )
        }
      }
    }
  };

  return (
    <div>
      {!showLoader && <Paper elevation={0} rounded sx={{ padding: "8px", marginTop: '1em', background: "#EFEFEF", overflow: "unset" }}>
        <Grid container spacing={1} >
          <Grid item xs={12}>
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} justifyContent='space-between'>
              <Typography variant='h5' color='#07074E' >
                  {t("Overview")}
              </Typography>
              <div>{componentDownloadExcel}</div>
            </Stack>
          </Grid>
          {recordsTimeline.length == 0 &&
            <Grid item xs={12}>  
              <CardEmpty> <div>{t("msg.info.empty_response")}</div></CardEmpty>
            </Grid>
          }
          {recordsTimeline.length != 0 &&
            <Grid item xs={12} >
              <Card sx={{ overflowX: 'scroll' }}>
                <Box sx={{ minWidth: '720px' }}>
                  <Chart
                    id={"chartTimeline"}
                    options={chartData.options}
                    series={apexChartDataSeries}
                    type="rangeBar"
                    height={280}
                    width={"100%"}
                  />
                </Box>
              </Card>
            </Grid>
          }
          
          {recordsTimelineAux.length != 0 &&
            <Grid item xs={12} >
              <TableProductivityCard
                id={"timelineTableUser"}
                recordsTimeline={recordsTimelineAux}
                idUser={""}
                showLoader={false}
                acceptClick={true}
                showAll={true}
                startDate={startDate}
                endDate={endDate}
                idProfile={idProfile}
                idSelectedProject={idSelectedProject}
              />
            </Grid>
            }
        </Grid>
      </Paper>}
    </div>
  )
}

export default DashboardTimeLine;
