import * as React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect, HashRouter} from 'react-router-dom';
import {
    ForgotPasswordPage, LoginPage, RegisterPage, Error403, Empty,
    Instances, GlobalReport, HomePage, LogbookPage, ConfigurationCategory,
    EstimationPage, ProjectTypesPage, ProjectDetailPage, ProjectDailyPage,
    Configuration, Dashboard, PricingCardsPage, ChangePassword, Profile,
    ProjectsExternalAPI, APIProjectsInfo, LicensePage
} from "./pages";
import "./css/App.scss";
import "./css/Global.scss";
import {useAuthContext} from "./contexts/AuthenticationContext";
import i18n from "i18next";
import {User} from "./util/Interfaces";
import {
    ACCESS_TOKEN_KEY, EXCHANGE_KEY, ID_INSTANCE_SPINGERE, ID_INSTANCE_TYPE_CORPORATE,
    ID_INSTANCE_TYPE_INDIVIDUAL, ID_ROLE_ADMIN, MIN_LEVEL_LEADER, MIN_LEVEL_USER, PROJECT_ID_KEY, 
    PROJECT_KEY, PROJECT_OBJ_KEY, STRIPE_KEY, TIME_FORMAT, USER_INFO_KEY, USER_LANGUAGE_KEY
} from "./util/Constants";
import InstanceService from "../src/services/instance.service";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "./MUIstyle/MUICustom";
import ConfigurationCatalogs from "./pages/ConfigurationCatalogsPage";


// import ProjectAzure from "./pages/ProjectAzure.react";

// @ts-ignore
library.add(fab, fas);

function App() {
    const {user} = useAuthContext() as { user: User };
    const [instance, setInstance] = React.useState(user.instance);
    const instanceService = React.useMemo(() => new InstanceService(), []);
    React.useEffect(() => {
        localStorage.setItem("PK_STRIPE", STRIPE_KEY);
        localStorage.setItem("EXCHANGEAPI_KEY", EXCHANGE_KEY);
        const setSavedLanguage = async () => {
            const currentLanguage = await localStorage.getItem(USER_LANGUAGE_KEY);
            await i18n.changeLanguage(currentLanguage || "en");
        }
        setSavedLanguage();
        let id = setInterval(() => fillProtected(), 300000 );
        return () => clearInterval(id);
    }, []);

    const fillProtected = () => {
      if (user?.instance !== undefined && user?.instance !== null) {
        instanceService.get(user?.instance.idInstance).then((response:any) => {
          if (response.data.success) {
            setInstance(user.instance);
          }
        }).catch((e:any) => console.log(e));
      } else {
        setInstance(undefined);
      }
    }

    React.useEffect(() => {
      const savePrefs = async () => {
        try {
          const currentSavedToken = await localStorage.getItem(ACCESS_TOKEN_KEY) || "";
          const currentSavedLanguage = await localStorage.getItem(USER_LANGUAGE_KEY) || "";
          const currentSavedUser = await localStorage.getItem(USER_INFO_KEY) as string;

          if (user && JSON.stringify(user) !== currentSavedUser) {
            var cloneUser:any = Object.assign({}, user);
            cloneUser["projectsInfo"] = [];
            cloneUser["teams"] = [];
            const userPreferencesToSave = JSON.stringify(cloneUser);
            await localStorage.setItem(USER_INFO_KEY, userPreferencesToSave);
            //console.log("Saving user preferences...", user);
            //await localStorage.setItem(PROJECTS_INFO_KEY, JSON.stringify(user.projectsInfo));
          }
          if (user.langCode && currentSavedLanguage !== user.langCode) {
            localStorage.setItem(USER_LANGUAGE_KEY, user.langCode);
            //console.log("Saving user USER_LANGUAGE_KEY..." + user.langCode);
          }
          if (user.token !== currentSavedToken) {
            localStorage.setItem(ACCESS_TOKEN_KEY, user.token);
            //console.log("Saving user ACCESS_TOKEN_KEY..." + user.token);
            window.location.reload();
          }
        } catch (e) {
          // ignore error
          console.log("#Error SavingPreferences " + e)
        }
      };
      savePrefs();
    }, [user]);

    const ProtectedRoute = ({component: Component, ...rest}: any) => {
      let allowAccess = true;
      const features = user?.instance?.license ? user?.instance?.license?.features : {};
      //console.log(user);
      //console.log(instance);
      if (user.instance?.license == null || user.instance?.license == undefined) {
        localStorage.removeItem(USER_INFO_KEY);
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(PROJECT_KEY);
        localStorage.removeItem(PROJECT_ID_KEY);
        localStorage.removeItem(PROJECT_OBJ_KEY);
        localStorage.removeItem(TIME_FORMAT);
        allowAccess = false;
        //console.log("1");
      } else if ((user.instance == undefined || user.instance == null) && (instance == undefined || instance == null)) {
        allowAccess = false;
        //console.log("2");
      } else {
        if (user.instance?.idInstance != ID_INSTANCE_SPINGERE && ["/instances", "/licenses"].includes(rest.path)) {
          allowAccess = false;
          //console.log("3");
        } else if (user.instance?.instanceType?.idInstanceType == ID_INSTANCE_TYPE_INDIVIDUAL && 
          ["/estimation", "/dashboard", "/configurationCategory", "/configurationUsers", "/instances", 
          "/projectTypes", "/globalReport", "/interoperability", "/apiProjectDetails", "/logbook",
          "/daily"].includes(rest.path) && !["/"].includes(rest.path)) {
          allowAccess = false;
          //console.log("4");
        } else if (user.instance?.instanceType?.idInstanceType == ID_INSTANCE_TYPE_CORPORATE &&
          (!user.instance?.activeByPayment && ( (user.instance?.trial != null && user.instance?.trial?.isExpired) || 
            user.instance?.trial == null || user.instance?.trial == undefined) ) &&
              ["/estimation", "/dashboard", "/configurationCategory", "/configurationUsers", "/instances", 
              "/projectTypes", "/globalReport", "/interoperability", "/apiProjectDetails", "/instances", "/logbook", "/daily"].includes(rest.path) && !["/"].includes(rest.path)){
          allowAccess = false;
          //console.log("5");
        } else if(user.instance?.instanceType?.idInstanceType == ID_INSTANCE_TYPE_CORPORATE && user.role?.accessLevel == MIN_LEVEL_LEADER && 
          ["/projectTypes", "/instances", "/interoperability", "/apiProjectDetails", "/configurationCategory", "/logbook"].includes(rest.path)) {
          allowAccess = false;
          //console.log("6");
          //console.log("allowAcess3");
        } else if(user.instance?.instanceType?.idInstanceType == ID_INSTANCE_TYPE_CORPORATE && user.role?.accessLevel == MIN_LEVEL_USER &&
          ["/projectTypes", "/instances", "/configurationUsers", "/estimation", "/interoperability", "/apiProjectDetails",
          "/configurationCategory", "/logbook"].includes(rest.path)) {
            allowAccess = false;
            //console.log("7");
        } else if (['/projectDetail'].includes(rest.path) && !features?.detailProject) {
          allowAccess = false;
          //console.log("8");
        } else if(['/estimation'].includes(rest.path) && !features?.estimationProject) {
          allowAccess = false;
          //console.log("9");
        } else if(['/configurationUsers'].includes(rest.path) && !(features?.users || features.manageWorkSchedules)) {
          allowAccess = false;
          //console.log("10");
        } else if(['/dashboard'].includes(rest.path) && !(features?.reportProductivityToolsUsed ||
            features?.productivityUsers || features?.reportTimeline)) {
          allowAccess = false;
          //console.log("11");
        } else if(['/globalReport'].includes(rest.path) && !(features.userProductivityReportItemsPerformed || 
            features.worksheetsUsers || features.reportBenchmark || features.globalReportProjectsStatus || 
              features.reportProjectsEvaluation)) {
          allowAccess = false;
          //console.log("12");
        } else if(['/daily'].includes(rest.path) && !(features.dailyProjectTracking || features.dailyProjectTrackingDragAndDrop)) {
          allowAccess = false;
          //console.log("13");
        } else if(['/interoperability'].includes(rest.path) && !(features.interoperabilityAll)) {
          allowAccess = false;
          //console.log("14");
        } else if(['/logbook'].includes(rest.path) && !(features.logTrueffort)) {
          allowAccess = false;
          //console.log("15");
        } else if(['/catalogs'].includes(rest.path) && user?.role.accessLevel < ID_ROLE_ADMIN) {
          allowAccess = false;
        }
      }

      return (
        <Route
            {...rest}
            render={(props: any) =>
                user.token && allowAccess ? (
                    <Component {...props} />
                ) : user.token && !allowAccess ? <Redirect
                to={{pathname: "/403",
                    state: {from: props.location}}}
                /> :(
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location},
                        }}
                    />
                )
            }
        />
      );
    };

    return (
        <ThemeProvider theme={globalTheme}>
        <HashRouter>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={'/home'} />
                    </Route>
                    <ProtectedRoute exact path="/home" component={HomePage}/>
                    <ProtectedRoute exact path="/estimation" component={EstimationPage}/>
                    <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
                    <ProtectedRoute exact path="/profile" component={Profile}/>
                    <ProtectedRoute exact path="/configurationUsers" component={Configuration}/>
                    <ProtectedRoute exact path="/configurationCategory" component={ConfigurationCategory}/>
                    <ProtectedRoute path="/projectTypes" component={ProjectTypesPage} />
                    <ProtectedRoute path="/globalReport" component={GlobalReport} />
                    <ProtectedRoute exact path="/interoperability" component={ProjectsExternalAPI} />
                    <ProtectedRoute exact path="/apiProjectDetails" component={APIProjectsInfo} />
                    <ProtectedRoute exact path="/instances" component={Instances} />
                    <ProtectedRoute exact path="/projectDetail" component={ProjectDetailPage}/>
                    <ProtectedRoute exact path="/logbook" component={LogbookPage}/>
                    <ProtectedRoute exact path="/licenses" component={LicensePage} />
                    <ProtectedRoute exact path="/daily" component={ProjectDailyPage}/>
                    <ProtectedRoute exact path="/catalogs" component={ConfigurationCatalogs} />
                    <Route exact path="/pricing" component={PricingCardsPage}/>
                    <Route exact path="/403" component={Error403}/>
                    <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
                    <Route exact path="/change-password" component={ChangePassword}/>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register" component={RegisterPage}/>
                    <Route exact path="/empty-page" component={Empty}/>
                </Switch>
            </Router>
        </HashRouter>
        </ThemeProvider>
    );
}

export default App;