import {
  Grid, Menu, MenuItem, Tab, Tabs, Card, TablePagination, CardContent, Paper
} from "@mui/material";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { ResponsivePie } from '@nivo/pie'
import {useTranslation} from "react-i18next";
import DashboardAppWindowsTRUE from "../DashboardAppWindowsTRUE";
import {processActivitiesForDashboardGeneral, processActivitiesForPieChart} from "../../util/ActivitiesProcessorUtil";
import {cutValueFormated, formatSeconds} from "../../util/GlobalFunctions";
import DashboardAppSitesTRUE from "./DashboardAppSitesTRUE";
import DashboardCategoryAppTRUE from "../DashboardCategoryAppTRUE";
import DashboardCategorySitesTRUE from "./DashboardCategorySitesTRUE";
import { KeyboardArrowDown, Language, TrendingDown, TrendingUp } from "@mui/icons-material";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import { TrueffortNavButton } from "../../MUIstyle/MUICustom";
import { COLORS_NOT_RELEVANT, COLORS_RELEVANT } from "../../util/Constants";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import { useState, useEffect } from "react";

function DashboardGeneral ({startDate, endDate, idUser, idProfile, idSelectedProject, handleDecideRelevant,
  listSites, listProfiles, users, listCategories, setRefreshCategory, listAllActivities, 
  listActivitiesOnlyBrowsers, setRelevant, inputSearch, showLoader, handleChangeCategory,
  handleChangeCategoryNonRelevant, componentDownloadExcel, handleProfileRelevant, listCategoriesSites, 
  setRefreshCategorySite, setRefreshPage}) {
    //console.log(listAllActivities);
  let appsWeb = JSON.parse(localStorage.getItem('INSTANCE_INFO'));
  const idsAppsWeb = appsWeb.applicationsWeb.map(app => app.idApplication);
  //console.log(idsAppsWeb);
  const { user } = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const [activitiesFiltered, setActivitiesFiltered] = useState([]);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [totalSecondsIrrelevant, setTotalSecondsIrrelevant] = useState(0);
  const [dataPieRelevant, setDataPieRelevant] = useState([]);
  const [dataPieRelevantCategorySite, setDataPieRelevantCategorySite] = useState([]);
  const [dataPieNotRelevant, setDataPieNotRelevant] = useState([]);
  const [dataPieNotRelevantCategorySite, setDataPieNotRelevantCategorySite] = useState([]);    
  const [idSelectedCategory1, setIdSelectedCategory1] = useState("");
  const [nameSelectedCategory1, setNameSelectedCategory1] = useState("");
  const [idSelectedCategory2, setIdSelectedCategory2] = useState("");
  const [nameSelectedCategory2, setNameSelectedCategory2] = useState("");
  const [idSelectedCategorysite1, setIdSelectedCategorysite1] = useState("");
  const [nameSelectedCategorysite1, setNameSelectedCategorysite1] = useState("");
  const [idSelectedCategorysite2, setIdSelectedCategorysite2] = useState("");
  const [nameSelectedCategorysite2, setNameSelectedCategorysite2] = useState("");
  const [totalTimePie1, setTotalTimePie1] = useState(0);
  const [totalTimePie2, setTotalTimePie2] = useState(0);
  const [chartData, setChartData] = useState({"name": t("dashboard.relevant"), 
    "color": "hsl(107, 70%, 50%)", "children": []});
  const [chartDataCategory, setChartDataCategory] = useState({"name": t("dashboard.relevant"), 
    "color": "hsl(107, 70%, 50%)", "children": []});
  const [chartDataNotRelevant, setChartDataNotRelevant] = useState({"name": t("dashboard.no_relevant"), 
    "color": "hsl(107, 70%, 50%)", "children": []});
  const [chartDataCategoryNotRelevant, setChartDataCategoryNotRelevant] = useState({
    "name": t("dashboard.no_relevant"), "color": "hsl(107, 70%, 50%)", "children": []});
  const [anchorElemFilter, setAnchorElemFilter] = useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [anchorElemIrrelevantCategory, setAnchorElemIrrelevantCategory] = useState(null);
  const openOptionsElemIrrelevantCategory = Boolean(anchorElemIrrelevantCategory);
  const [anchorElemCategorySites, setAnchorElemCategorySites] = useState(null);
  const openOptionsCategorySites = Boolean(anchorElemCategorySites);
  const [anchorElemIrrelevantCategorySites, setAnchorElemIrrelevantCategorySites] = useState(null);
  const openOptionsIrrelevantCategorySites = Boolean(anchorElemIrrelevantCategorySites);
  const [menuNumber, setMenuNumber] = useState(0);
  const [tools, setTools] = useState(0);
  let ROWS_PER_PAGE = 4;
  const [rowsPerPageCategoryApps, setRowsPerPageCategoryApps] = useState(ROWS_PER_PAGE);
  const [categoryAppsPage, setCategoryAppsPage] = useState(0);
  const [categoryAppsCount, setCategoryAppsCount] = useState(0);//Cantidad de categorias
  const [rowsPerPageIrrelevantCategoryApps, setRowsPerPageIrrelevantCategoryApps] = useState(ROWS_PER_PAGE);
  const [irrelevantCategoryAppsPage, setIrrelevantCategoryAppsPage] = useState(0);
  const [irrelevantCategoryAppsCount, setIrrelevantCategoryAppsCount] = useState(0);//Cantidad de categorias
  const [rowsPerPageCategorySites, setRowsPerPageCategorySites] = useState(ROWS_PER_PAGE);
  const [categorySitesPage, setCategorySitesPage] = useState(0);
  const [categorySitesCount, setCategorySitesCount] = useState(0);//Cantidad de categorias
  const [rowsPerPageIrrelevantCategorySites, setRowsPerPageIrrelevantCategorySites] = useState(ROWS_PER_PAGE);
  const [irrelevantCategorySitesPage, setIrrelevantCategorySitesPage] = useState(0);
  const [irrelevantCategorySitesCount, setIrrelevantCategorySitesCount] = useState(0);//Cantidad de categorias
  const [sites, setSites] = useState([]);

  const handleChangeTools = (event, val) => {
    setTools(val);
  };

  useEffect(() => {
    if (idSelectedCategory1 == "") {
      setCategoryAppsCount(categoriesFiltered.filter(category => category.isRelevantCategory && category.totalSeconds > 0).length);
    }
    if(idSelectedCategory2 == "") {
      setIrrelevantCategoryAppsCount(categoriesFiltered.filter(category => !category.isRelevantCategory && category.totalSeconds > 0).length);
    }
  }, [categoriesFiltered])

  useEffect(() => {
    //console.log(dataPieRelevant);
    if (idSelectedCategorysite1 == "") {
      setCategorySitesCount(dataPieRelevantCategorySite.filter(cat_ => cat_.totalSeconds > 0).length);
    } else {
      setCategorySitesCount(dataPieRelevant.filter(activity_ => activity_.totalSeconds > 0).length);
    }
  }, [dataPieRelevantCategorySite, dataPieRelevant])

  useEffect(() => {
    if (idSelectedCategorysite2 == "") {
      setIrrelevantCategorySitesCount(dataPieNotRelevantCategorySite.filter(cat_ => cat_.totalSeconds > 0).length);
    } else {
      setIrrelevantCategorySitesCount(dataPieNotRelevant.filter(activity_ => activity_.totalSeconds > 0).length);
    }
  }, [dataPieNotRelevantCategorySite, dataPieNotRelevant])

  useEffect(() => {
    fillChartData();
  }, [listAllActivities, idUser, idProfile, startDate, endDate, inputSearch, listProfiles, users, sites, listCategories]);

  useEffect(() => {
    fillPieSites();
    //fillPieSitesNotRelevant();
  }, [listActivitiesOnlyBrowsers, idUser, idProfile, startDate, endDate, inputSearch, listProfiles, users, listCategoriesSites]);

  useEffect(() => {
    fillPieSites();
  }, [idSelectedCategorysite1, idSelectedCategorysite2]);


  useEffect(() => {
    processChartData();
    processChartDataCategories();
  }, [activitiesFiltered, categoriesFiltered, idSelectedCategory1, idSelectedCategory2]);

  const fillChartData = () => {
    //console.log("fillChartData");
    let arr = processActivitiesForDashboardGeneral(listAllActivities, listCategories, listProfiles, 
      "", idProfile, idUser, inputSearch, users, idsAppsWeb, listActivitiesOnlyBrowsers, sites);
    //console.log(arr);
    setActivitiesFiltered(arr.applications);
    setCategoriesFiltered(arr.categories);
  }

  const processChartDataCategories = () => {
    let arr = categoriesFiltered.slice();
    // Llenar datos para la gráfica
    let arrChildren = [];
    let arrChildrenNotRelevant = [];
    let totalSecondsAux = 0;
    let totalSecondsIrrelevantAux = 0;
    arr.forEach((cat, k) => {
      if (cat.totalSeconds > 0) {
        let item = {"name": cat.categoryName, "color": "hsl(177, 70%, 50%)", "loc": cat.totalSeconds};
        if (cat.isRelevantCategory) {
          // FILTRO DE CATEGORÍA
          if (idSelectedCategory1 != "") {
            if (cat.idCategory == idSelectedCategory1) {
              arrChildren.push(item);
              totalSecondsAux += cat.totalSeconds;
            }
          } else {
            arrChildren.push(item);
            totalSecondsAux += cat.totalSeconds;
          }
        } else {
          // FILTRO DE CATEGORÍA
          if (idSelectedCategory2 != "") {
            if (cat.idCategory == idSelectedCategory2) {
              arrChildrenNotRelevant.push(item);
              totalSecondsIrrelevantAux += cat.totalSeconds;
            }
          } else {
            arrChildrenNotRelevant.push(item);
            totalSecondsIrrelevantAux += cat.totalSeconds;
          }
        }
      }
    });
    setTotalSeconds(totalSecondsAux);
    setTotalSecondsIrrelevant(totalSecondsIrrelevantAux);
    setChartDataCategory({...chartDataCategory, children: arrChildren});
    setChartDataCategoryNotRelevant({...chartDataCategoryNotRelevant, children: arrChildrenNotRelevant});
  }

  const processChartData = () => {
    let arr = activitiesFiltered.slice();
    // Llenar datos para la gráfica
    let arrChildren = [];
    let arrChildrenNotRelevant = [];
    let totalSecondsAux = 0;
    let totalSecondsIrrelevantAux = 0;
    arr.forEach((act, k) => {
      if (act.totalSeconds > 0) {
        let item = {"name": act.application.name, "color": "hsl(177, 70%, 50%)", "loc": act.totalSeconds};
        if (act.isRelevant) {
          // FILTRO DE CATEGORÍA
          if (idSelectedCategory1 != "") {
            if (appExistsInCategory(act.application.idApplication, idSelectedCategory1)) {
              arrChildren.push(item);
              totalSecondsAux += act.totalSeconds;
            }
          } else {
            arrChildren.push(item);
            totalSecondsAux += act.totalSeconds;
          }
        } else {
          // FILTRO DE CATEGORÍA
          if (idSelectedCategory2 != "") {
              if (appExistsInCategory(act.application.idApplication, idSelectedCategory2)) {
                arrChildrenNotRelevant.push(item);
                totalSecondsIrrelevantAux += act.totalSeconds;
              }
          } else {
            arrChildrenNotRelevant.push(item);
            totalSecondsIrrelevantAux += act.totalSeconds;
          }
        }
      }
    });
    //console.log(arrChildrenNotRelevant);
    setTotalSeconds(totalSecondsAux);
    setTotalSecondsIrrelevant(totalSecondsIrrelevantAux);
    setChartData({...chartData, children: arrChildren});
    setChartDataNotRelevant({...chartDataNotRelevant, children: arrChildrenNotRelevant});
  }

  const appExistsInCategory = (idApp, idCat) => {
    if (idCat == "") {
      return true;
    } else if (idCat != "0") {
      let selectedCat = listCategories.find((el) => el.idCategory == idCat);
      if (Array.isArray(selectedCat.applications)) {
        let findAppInCat = selectedCat.applications.find((el) => el.idApplication == idApp);
        if (typeof findAppInCat != "undefined") {
          return true;
        }
      }
      return false;
    } else {  // SIN CATEGORÍA
      let existsInCat = false;
      listCategories.forEach((c, i) => {
        if (Array.isArray(c.applications)) {
          let findAppInCat = c.applications.find((el) => el.idApplication == idApp);
          if (typeof findAppInCat != "undefined") {
            existsInCat = true;
            return;
          }
        }
      });
      if (!existsInCat) { // No existe en ninguna categoría = existe en "sin categoría"
        return true;
      }
      return false;
    }
  }

  const fillPieSites = () => {
    let arrPie = processActivitiesForPieChart(listActivitiesOnlyBrowsers, listCategoriesSites, listProfiles,
      idSelectedCategorysite1, idSelectedCategorysite2, idProfile, idUser, users, listSites);
    if (arrPie) {
      //console.log("sitesRelevant");
      //console.log(arrPie);
      setSites(arrPie.sites);
      setDataPieRelevantCategorySite(arrPie.categorySites.filter((cs) => cs.isRelevant));
      if (idSelectedCategorysite1 != "") {
        setDataPieRelevant(arrPie.categorySites.filter((s) => s.isRelevant && s.idCategorysite == idSelectedCategorysite1).map((c) => c.sites).flat());
        setTotalTimePie1(arrPie.categorySites.filter((s) => s.isRelevant && s.idCategorysite == idSelectedCategorysite1).map((c) => c.sites).flat().reduce((ac,cu) => ac + cu.totalSeconds, 0));
      } else {
        setDataPieRelevant(arrPie.sites.filter((s) => s.isRelevant));
        setTotalTimePie1(arrPie.sites.filter((s) => s.isRelevant).reduce((ac,cu) => ac + cu.totalSeconds, 0));
      }
      
      setDataPieNotRelevantCategorySite(arrPie.categorySites.filter((cs) => !cs.isRelevant));
      if (idSelectedCategorysite2 != "") {
        setDataPieNotRelevant(arrPie.categorySites.filter((s) => !s.isRelevant && s.idCategorysite == idSelectedCategorysite2).map((c) => c.sites).flat());
        setTotalTimePie2(arrPie.categorySites.filter((s) => !s.isRelevant && s.idCategorysite == idSelectedCategorysite2).map((c) => c.sites).flat().reduce((ac,cu) => ac + cu.totalSeconds, 0));
      } else {
        setDataPieNotRelevant(arrPie.sites.filter((s) => !s.isRelevant));
        setTotalTimePie2(arrPie.sites.filter((s) => !s.isRelevant).reduce((ac,cu) => ac + cu.totalSeconds, 0));
      }
    }
  }

  // Select categoría, relevantes.
  const handleChangeCategory1 = (id, name) => {
    setAnchorElemFilter(null);
    setIdSelectedCategory1(id);
    setNameSelectedCategory1(name);
    handleChangeCategory(id, name);
    if (id != "") {
      setCategoryAppsCount(activitiesFiltered.filter(activity_ => activity_.isRelevant && activity_.totalSeconds > 0
        && appExistsInCategory(activity_.application.idApplication, id)).length);
    }
  }

  // Select categoría, relevantes.
  const handleChangeCategory2 = (id, name) => {
    setAnchorElemIrrelevantCategory(null);
    setIdSelectedCategory2(id);
    setNameSelectedCategory2(name);
    handleChangeCategoryNonRelevant(id, name);
    if (id != "") {
      setIrrelevantCategoryAppsCount(activitiesFiltered.filter(activity_ => !activity_.isRelevant && activity_.totalSeconds > 0
        && appExistsInCategory(activity_.application.idApplication, idSelectedCategory2)).length);
    }
  }

  const handleChangeCategorysite1 = (id, name) => {
    //console.log("handleChangeCategorysite1 => " + id + ", name => " + name);
    setAnchorElemCategorySites(null);
    setIdSelectedCategorysite1(id);
    setNameSelectedCategorysite1(name);
    if (id == "") {
      setCategorySitesCount(dataPieRelevantCategorySite.filter(category => category.isRelevantCategory && category.totalSeconds > 0).length);
    } else {
      setCategorySitesCount(dataPieRelevant.filter(activity_ => activity_.totalSeconds > 0).length);
    }
  }

  const handleChangeCategorysite2 = (id, name) => {
    //console.log("handleChangeCategorysite2 => " + id + ", name => " + name);
    setAnchorElemIrrelevantCategorySites(null);
    setIdSelectedCategorysite2(id);
    setNameSelectedCategorysite2(name);
    //console.log(dataPieNotRelevant);
    if (id == "") {
      setIrrelevantCategorySitesCount(dataPieNotRelevantCategorySite.filter(cat_ => cat_.totalSeconds > 0).length);
    } else {
      setIrrelevantCategorySitesCount(dataPieNotRelevant.filter(activity_ => activity_.totalSeconds > 0).length);
    }
  }

  const getResponsiveTreeMap = (data, colors) => {
    return (
      <ResponsiveTreeMap
        data={data}
        leavesOnly={true}
        identity="name"
        value="loc"
        valueFormat=".02s"
        innerPadding={8}
        colors={colors}
        nodeOpacity={.9}
        labelSkipSize={30}
        labelTextColor={"#FFFFFF"}
        enableParentLabel={true}
        tooltip={({node}) => (
          <div className="tooltip-pie">
            <div style={{fontSize: ".9em", borderLeft: "solid 1em" + node.color, paddingLeft: ".5em"}}>
              {node.id +":"}
              <strong style={{paddingLeft: '0.5em'}}>
                  {formatSeconds(node.value, false, false, false)}
              </strong>
            </div>
          </div>
        )}
        label={function (e) { return e.id }}
        orientLabel={false}
        margin={{top: 10, right: 10, bottom: 10, left: 10}}
        borderColor={{from: 'color', modifiers: [['darker', 0.1]]}}
        borderWidth={1}
        theme={{
          tooltip: {
            container: {
              opacity: '.9',
            },
          },
        }}
      />
    );
  }

  const getDataPieSites = (data, colors) => {
    return (
      <ResponsivePie
        data={data}
        cornerRadius={2}
        sortByValue={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        tooltip={(e) => {
          return <div className="tooltip-pie">
            <div style={{fontSize: ".9em", borderLeft: "solid 1em" + e.datum.color, paddingLeft: ".5em"}}>
              {e.datum.id + ":"}
              <strong style={{paddingLeft:'0.5em'}}>{formatSeconds(e.datum.value, false, false, false)}</strong>
            </div>
          </div>;
        }}
        colors={colors}
      />
    );
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const handleClickMenuIrrelevantCategory = (event, value) => {
    setAnchorElemIrrelevantCategory(event.currentTarget);
    setMenuNumber(value);
  }

  const handleClickMenuSites = (event, value) => {
    setAnchorElemCategorySites(event.currentTarget);
    setMenuNumber(value);
  }

  const handleClickMenuIrrelevantCategorySites = (event, value) => {
    setAnchorElemIrrelevantCategorySites(event.currentTarget);
    setMenuNumber(value);
  }

  const handleChangeCategoryAppsRowsPerPage = (event) => {
    setRowsPerPageCategoryApps(parseInt(event.target.value, 10));
    setCategoryAppsPage(0);
  }
  
  const handleChangeCategoryAppsPage = (event, newPage) => {
    setCategoryAppsPage(newPage);
  }

  const handleChangeIrrelevantCategoryAppsRowsPerPage = (event) => {
    setRowsPerPageIrrelevantCategoryApps(parseInt(event.target.value, 10));
    setIrrelevantCategoryAppsPage(0);
  }
  
  const handleChangeIrrelevantCategoryAppsPage = (event, newPage) => {
    setIrrelevantCategoryAppsPage(newPage);
  }

  const handleChangeCategorySitesRowsPerPage = (event) => {
    setRowsPerPageCategorySites(parseInt(event.target.value, 10));
    setCategorySitesPage(0);
  }

  const handleChangeCategorySitesPage = (event, newPage) => {
    setCategorySitesPage(newPage);
  }

  const handleChangeIrrelevantCategorySitesRowsPerPage = (event) => {
    setRowsPerPageIrrelevantCategorySites(parseInt(event.target.value, 10));
    setIrrelevantCategorySitesPage(0);
  }

  const handleChangeIrrelevantCategorySitesPage = (event, newPage) => {
    setIrrelevantCategorySitesPage(newPage);
  }

  return (
    <Paper elevation={0} sx={{ padding: '12px', bgcolor: showLoader ? 'transparent' : '#F5F5F5', marginTop:'1em' }} >
      {!showLoader &&
        <Grid container spacing={1} xs={12}>
          <Grid id={"idTabsTools"} item xl={10} md={10} sm xs={12}>
            <Tabs
              variant='scrollable'
              scrollButtons='auto'
              value={tools}
              onChange={handleChangeTools}
              aria-label="basic tabs example"
            >
              <Tab id="idTabAll" sx={{minHeight:'0px'}} label={t("dashboard.all")} />
              <Tab id="idTabRelevant" icon={<TrendingUp />} iconPosition="start"  sx={{minHeight:'0px'}}
                label={chartData.children.length + " " +t("dashboard.relevant") + " - " + formatSeconds(totalSeconds, true, false, true)} />
              <Tab id="idTabOthers" icon={<TrendingDown />} iconPosition="start" sx={{minHeight:'0px'}}
                label={chartDataNotRelevant.children.length + " " + t("dashboard.others") + " - " + formatSeconds(totalSecondsIrrelevant, true, false, true)} />
            </Tabs>
          </Grid>
          <Grid item xl={2} md={2} sm xs={12}>{componentDownloadExcel}</Grid>
        </Grid>
      }
      {!showLoader &&
        <Grid container spacing={1} sx={styleMarginTop} >
          {(tools == 0 || tools == 1) && 
          <Grid item xl={6} md={6} xs={12}>
            <Card >
              <CardContent >
                <div style={styleChartDiv}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8} md={9} lg={9}>
                      <h6 id="idTitleChartRelevantApps" className="chart-title">{t("dashboard.applications_relevant") + " " + formatSeconds(totalSeconds, true, false, true) + ""}</h6>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <TrueffortNavButton
                          id="idSelectedRelevantCategory"
                          size="small"
                          fullWidth
                          variant="contained"
                          onClick={(event) => handleClickMenu(event, 1)}
                          className={idSelectedCategory1 != "" ? "active" : ""}
                      >
                          <CustomIconTrueffortScreen icon={<Language />} />
                          {idSelectedCategory1 != "" ? cutValueFormated(nameSelectedCategory1,13) : t("categories")}
                          <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                      </TrueffortNavButton>
                      <Menu id="idElementsCategory"
                          anchorEl={anchorElemFilter}
                          open={openOptions}
                          onClose={() => setAnchorElemFilter(null)}
                      >
                        {menuNumber == 1 && <div>
                          <MenuItem id="idCategory_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategory1('', t("categories"))}>
                            {t("dashboard.category_all")}
                          </MenuItem>
                          {listCategories.map((c, i) => (
                            <MenuItem id={"idCategory_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategory1(c.idCategory, c.name)}>
                              {c.name}
                            </MenuItem>
                          ))}
                        </div>}
                      </Menu>
                    </Grid>
                  </Grid>
                  {chartData.children.length > 0 && idSelectedCategory1 != "" && getResponsiveTreeMap(chartData, COLORS_RELEVANT)}
                  {chartDataCategory.children.length > 0 && idSelectedCategory1 == "" && getResponsiveTreeMap(chartDataCategory, COLORS_RELEVANT)}
                </div>
                <h6 id="idCategoriesSection_0" className="chart-title">{idSelectedCategory1 == "" ? t("categories") : nameSelectedCategory1}</h6>
                {idSelectedCategory1 == "" &&
                  <div key={"_0"} style={styleList}>
                    {categoriesFiltered.filter(category => category.isRelevantCategory && category.totalSeconds > 0).slice(
                      categoryAppsPage * rowsPerPageCategoryApps,
                      categoryAppsPage * rowsPerPageCategoryApps + rowsPerPageCategoryApps).map((cat, ind) =>
                        <div id={"idRelevantCategory_"+ind} className="list-category-parent" key={cat.idCategory} style={{ minWidth: '370px' }} >
                          <DashboardCategoryAppTRUE
                            id={"idRelevantCategoryApp_"+ind}
                            category={cat}
                            startDate={startDate} endDate={endDate}
                            idUser={idUser}
                            idProfile={idProfile}
                            idSelectedProject={idSelectedProject}
                            totalSeconds={totalSeconds}
                            handleDecideRelevant={handleDecideRelevant}
                            users={users}
                            listProfiles={listProfiles}
                            listCategories={listCategories}
                            setRefreshCategory={setRefreshCategory}
                            className={"div-app-windows-clean"}
                            isLarge={true}
                            handleProfileRelevant={handleProfileRelevant}
                            makeRelevant={false}
                            setRefreshPage={setRefreshPage}
                            asRelevant={true}
                          />
                        </div>
                    )}
                  </div>
                }
                {idSelectedCategory1 != "" &&
                  <div key={"_1"} style={styleList}>
                    {activitiesFiltered.filter(activity_ => activity_.isRelevant && activity_.totalSeconds > 0
                        && appExistsInCategory(activity_.application.idApplication, idSelectedCategory1)).slice(
                          categoryAppsPage * rowsPerPageCategoryApps,
                          categoryAppsPage * rowsPerPageCategoryApps + rowsPerPageCategoryApps
                        ).map((activity, a) =>
                        <div id={"idRelevantAppParent_"+a} className="list-app-parent">
                          <DashboardAppWindowsTRUE 
                              id={"idRelevantApp_"+a}
                              key={activity.application.idApplication} 
                              activity={activity}
                              startDate={startDate} endDate={endDate}
                              idUser={idUser}
                              idProfile={idProfile}
                              idSelectedProject={idSelectedProject}
                              totalSeconds={totalSeconds}
                              handleDecideRelevant={handleDecideRelevant}
                              users={users}
                              listProfiles={listProfiles}
                              listCategories={listCategories}
                              setRefreshCategory={setRefreshCategory}
                              className={"div-app-windows"}
                              isLarge={true}
                              handleProfileRelevant={handleProfileRelevant}
                              makeRelevant={false}
                              setRefreshPage={setRefreshPage}
                              asRelevant={true}
                          />
                        </div>
                    )}
                  </div>
                }
                <TablePagination
                  id="idPaginationRelevantApps"
                  labelRowsPerPage={t("elements.pagination.label")}
                  rowsPerPageOptions={[4, 8, 12]}
                  component="div"
                  onRowsPerPageChange={handleChangeCategoryAppsRowsPerPage}
                  onPageChange={handleChangeCategoryAppsPage}
                  count={categoryAppsCount}
                  rowsPerPage={rowsPerPageCategoryApps}
                  page={categoryAppsPage}
                />
              </CardContent>
            </Card>
          </Grid>}
          {(tools == 0 || tools == 2) &&
          <Grid item xl={6} md={6} xs={12} >
            <Card >
              <CardContent >
                <div style={styleChartDiv}>
                  <Grid container spacing={1}>
                      <Grid item xs={12} sm={8} md={9} lg={9}>
                          <h6 id="idIrrelevantCategories" className="chart-title">{t("dashboard.applications_others") + " " + formatSeconds(totalSecondsIrrelevant, true, false, true)}</h6>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                          <TrueffortNavButton
                              id="idSelectedIrrelevantCategory"
                              size="small"
                              fullWidth
                              variant="contained"
                              onClick={(event) => handleClickMenuIrrelevantCategory(event, 2)}
                              className={idSelectedCategory2 != "" ? "active" : ""}>
                              <CustomIconTrueffortScreen icon={<Language />}/>
                              {idSelectedCategory2 != "" ? cutValueFormated(nameSelectedCategory2,13) : t("categories")}
                              <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                          </TrueffortNavButton>
                          <Menu id="idElementsDashboardsIrrelevant"
                              anchorEl={anchorElemIrrelevantCategory}
                              open={openOptionsElemIrrelevantCategory}
                              onClose={() => setAnchorElemIrrelevantCategory(null)}
                          >
                              {menuNumber == 2 && <div>
                                  <MenuItem id="idIrrelevantCategory_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategory2('', t("categories"))}>
                                      {t("dashboard.category_all")}
                                  </MenuItem>
                                  {listCategories.map((c, i) => (
                                      <MenuItem id={"idIrrelevantCategory_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategory2(c.idCategory, c.name)}>
                                          {c.name}
                                      </MenuItem>
                                  ))}
                              </div>}
                          </Menu>
                      </Grid>
                  </Grid>
                  {chartDataNotRelevant.children.length > 0 && idSelectedCategory2 != "" && getResponsiveTreeMap(chartDataNotRelevant, COLORS_NOT_RELEVANT)}
                  {chartDataCategoryNotRelevant.children.length > 0 && idSelectedCategory2 == "" && getResponsiveTreeMap(chartDataCategoryNotRelevant, COLORS_NOT_RELEVANT)}
                </div>
                <h6 id="idIrrelevantCategoriesSection" className="chart-title">{idSelectedCategory2 == "" ? t("dashboard.no_relevant") : nameSelectedCategory2}</h6>
                {idSelectedCategory2 == "" &&
                  <div key={"chart_cat_3"} style={styleList}>
                      {categoriesFiltered.filter(category => !category.isRelevantCategory && category.totalSeconds > 0).slice(
                          irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps,
                          irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps + rowsPerPageIrrelevantCategoryApps).map((cat, ind) =>
                              <div id={"idIrrelevantCategories_"+ind} className="list-category-parent irrelevant" key={cat.idCategory} style={{ minWidth: '370px' }} >
                                  <div>
                                      <DashboardCategoryAppTRUE
                                          id={"idIrrelevantCategoryApp_"+ind}
                                          category={cat}
                                          startDate={startDate} endDate={endDate}
                                          idUser={idUser}
                                          idProfile={idProfile}
                                          idSelectedProject={idSelectedProject}
                                          totalSeconds={totalSecondsIrrelevant}
                                          handleDecideRelevant={setRelevant}
                                          users={users}
                                          listProfiles={listProfiles}
                                          listCategories={listCategories}
                                          setRefreshCategory={setRefreshCategory}
                                          className={"div-app-windows-clean"}
                                          isLarge={true}
                                          handleProfileRelevant={handleProfileRelevant}
                                          makeRelevant={true}
                                          setRefreshPage={setRefreshPage}
                                          asRelevant={false}
                                      />
                                  </div>
                              </div>
                      )}
                  </div>
                }
                {idSelectedCategory2 != "" &&
                  <div key={"chart_app_3"} style={styleList}>
                    {activitiesFiltered.filter(activity_ => !activity_.isRelevant && activity_.totalSeconds > 0
                      && appExistsInCategory(activity_.application.idApplication, idSelectedCategory2)).slice(
                        irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps,
                        irrelevantCategoryAppsPage * rowsPerPageIrrelevantCategoryApps + rowsPerPageIrrelevantCategoryApps
                      ).map((activity, a) =>
                      <div id={"idIrrelevantAppParent_"+a} className="list-app-parent irrelevant" style={{ minWidth: '370px' }}>
                        <DashboardAppWindowsTRUE 
                          id={"idIrrelevantApp_"+a}
                          key={activity.application.idApplication} 
                          activity={activity}
                          startDate={startDate} 
                          endDate={endDate}
                          idUser={idUser}
                          idProfile={idProfile}
                          idSelectedProject={idSelectedProject}
                          totalSeconds={totalSecondsIrrelevant}
                          handleDecideRelevant={setRelevant}
                          users={users}
                          listProfiles={listProfiles}
                          listCategories={listCategories}
                          setRefreshCategory={setRefreshCategory}
                          className={"div-app-windows"}
                          isLarge={true}
                          handleProfileRelevant={handleProfileRelevant}
                          setRefreshPage={setRefreshPage}
                          makeRelevant={true}
                          asRelevant={false}
                        />
                      </div>
                    )}
                  </div>
                }
                <TablePagination
                  id="idPaginationIrrelevantApps"
                  labelRowsPerPage={t("elements.pagination.label")}
                  rowsPerPageOptions={[4, 8, 12]}
                  component="div"
                  onRowsPerPageChange={handleChangeIrrelevantCategoryAppsRowsPerPage}
                  onPageChange={handleChangeIrrelevantCategoryAppsPage}
                  count={irrelevantCategoryAppsCount}
                  rowsPerPage={rowsPerPageIrrelevantCategoryApps}
                  page={irrelevantCategoryAppsPage}
                />
              </CardContent>
            </Card>
          </Grid>}
          {features.categorizeWebSites && (tools == 0 || tools == 1) &&
          <Grid item xl={6} md={6} xs={12}>
              <Card >
                <CardContent >
                  <div style={styleChartDiv}>
                    <Grid container spacing={1} /*sx={styleMarginTop}*/>
                      <Grid item xs={12} sm={8} md={9} lg={9}>
                          <h6 id="idTitleChartRelevantSites" className="chart-title">{t("dashboard.relevant_sites") + " " + formatSeconds(totalTimePie1, true, false, true)}</h6>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                          <TrueffortNavButton
                              id="idSelectedRelevantSitesCategory"
                              size="small"
                              fullWidth
                              variant="contained"
                              onClick={(event) => handleClickMenuSites(event, 3)}
                              className={idSelectedCategorysite1 != "" ? "active" : ""}
                          >
                              <CustomIconTrueffortScreen icon={<Language />} />
                              {idSelectedCategorysite1 != "" ? cutValueFormated(nameSelectedCategorysite1,13) : t("categories")}
                              <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                          </TrueffortNavButton>
                          <Menu id="idElementsCategorySites"
                              anchorEl={anchorElemCategorySites}
                              open={openOptionsCategorySites}
                              onClose={() => setAnchorElemCategorySites(null)}
                          >
                              {menuNumber == 3 && <div>
                                  <MenuItem id="idCategorySites_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategorysite1('', t("categories"))}>
                                      {t("dashboard.category_all")}
                                  </MenuItem>
                                  {listCategoriesSites.map((c, i) => (
                                      <MenuItem id={"idCategorySites_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategorysite1(c.idCategorysite, c.name)}>
                                          {c.name}
                                      </MenuItem>
                                  ))}
                              </div>}
                          </Menu>
                      </Grid>
                    </Grid>
                    {idSelectedCategorysite1 == "" && getDataPieSites(dataPieRelevantCategorySite, COLORS_RELEVANT)}
                    {idSelectedCategorysite1 != "" && getDataPieSites(dataPieRelevant, COLORS_RELEVANT)}
                  </div>
                  <h6 id="idCategoriesSitesSection" className="chart-title">{idSelectedCategorysite1 == "" ? t("dashboard.relevant_sites") : nameSelectedCategorysite1}</h6>
                  {idSelectedCategorysite1 == "" &&
                    <div key={"_0"}>
                      <div style={styleList}>
                        {dataPieRelevantCategorySite.filter(cat_ => cat_.totalSeconds > 0).slice(
                          categorySitesPage * rowsPerPageCategorySites,
                          categorySitesPage * rowsPerPageCategorySites + rowsPerPageCategorySites).map((cat, a) =>
                            <div id={"idRelevantSitesCategoryParent_"+a} className={"list-category-parent"} key={cat.idCategorysite} style={{ minWidth: '370px' }} >
                              <DashboardCategorySitesTRUE
                                  id={"idRelevantSitesCategory_"+a}
                                  catSite={cat}
                                  idUser={idUser}
                                  idProfile={idProfile}
                                  totalSeconds={totalTimePie1}
                                  listUsers={users}
                                  makeRelevant={false}
                                  setRefreshPage={setRefreshPage}
                                  listProfiles={listProfiles}
                                  listCategories={listCategoriesSites}
                                  setRefreshCategorySite={setRefreshCategorySite}
                                  listAllActivities={listActivitiesOnlyBrowsers}
                                  className={"div-app-windows-clean"}
                              />
                          </div>
                        )}
                    </div>
                  </div>
                  }
                  {idSelectedCategorysite1 != "" &&
                    <div key={"_1"} style={styleList}>
                      {dataPieRelevant.filter(activity_ => activity_.totalSeconds > 0).slice(
                        categorySitesPage * rowsPerPageCategorySites,
                        categorySitesPage * rowsPerPageCategorySites + rowsPerPageCategorySites).map((activity, a) =>
                          <div id={"idRelevantSiteParent_"+a} className="list-app-parent" style={{ minWidth: '370px' }} >
                            <DashboardAppSitesTRUE
                              id={"idRelevantSite_"+a}
                              key={activity.idSite}
                              activity={activity}
                              idUser={idUser}
                              idProfile={idProfile}
                              totalSeconds={totalTimePie1}
                              listUsers={users}
                              makeRelevant={false}
                              setRefreshPage={setRefreshPage}
                              listProfiles={listProfiles}
                              listCategories={listCategoriesSites}
                              setRefreshCategorySite={setRefreshCategorySite}
                              listAllActivities={listActivitiesOnlyBrowsers}
                              className={"div-app-windows"}
                            />
                          </div>
                      )}
                    </div>
                  }
                <TablePagination
                  id="idPaginationRelevantSites"
                  labelRowsPerPage={t("elements.pagination.label")}
                  rowsPerPageOptions={[4,8,12]}
                  component={"div"}
                  onRowsPerPageChange={handleChangeCategorySitesRowsPerPage}
                  onPageChange={handleChangeCategorySitesPage}
                  count={categorySitesCount}
                  rowsPerPage={rowsPerPageCategorySites}
                  page={categorySitesPage}
                />
                </CardContent>
              </Card>
          </Grid>}
          {features.categorizeWebSites && (tools == 0 || tools == 2) &&
          <Grid item xl={6} md={6} xs={12}>
              <Card >
                <CardContent >
                  <div style={styleChartDiv}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={9} lg={9}>
                            <h6 id="idIrrelevantCategoriesSites" className="chart-title">{t("dashboard.sites_others") + " " + formatSeconds(totalTimePie2, true, false, true) + ""}</h6>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <TrueffortNavButton
                                id="idSelectedIrrelevantCategorySites"
                                size="small"
                                fullWidth
                                variant="contained"
                                onClick={(event) => handleClickMenuIrrelevantCategorySites(event, 4)}
                                className={idSelectedCategorysite2 != "" ? "active" : ""}
                            >
                                <CustomIconTrueffortScreen icon={<Language />} />
                                {idSelectedCategorysite2 != "" ? cutValueFormated(nameSelectedCategorysite2,13) : t("categories")}
                                <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                            </TrueffortNavButton>
                            <Menu id="idElementsIrrelevantCategorySites"
                                anchorEl={anchorElemIrrelevantCategorySites}
                                open={openOptionsIrrelevantCategorySites}
                                onClose={() => setAnchorElemIrrelevantCategorySites(null)}
                            >
                              {menuNumber == 4 && <div>
                                <MenuItem id="idCategoryIrrelevantSite_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategorysite2('', t("categories"))}>
                                  {t("dashboard.category_all")}
                                </MenuItem>
                                {listCategoriesSites.map((c, i) => (
                                  <MenuItem id={"idCategoryIrrelevantSite_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeCategorysite2(c.idCategorysite, c.name)}>
                                    {c.name}
                                  </MenuItem>
                                ))}
                              </div>}
                            </Menu>
                        </Grid>
                    </Grid>
                    {idSelectedCategorysite2 == "" && getDataPieSites(dataPieNotRelevantCategorySite, COLORS_NOT_RELEVANT)}
                    {idSelectedCategorysite2 != "" && getDataPieSites(dataPieNotRelevant, COLORS_NOT_RELEVANT)}
                  </div>
                  <h6 id="idIrrelevantCategoriesSitesSection" className="chart-title">{idSelectedCategorysite2 == "" ? t("dashboard.no_relevant_sites") : nameSelectedCategorysite2}</h6>
                  {idSelectedCategorysite2 == "" &&
                    <div key={"_0"}>
                      <div style={styleList}>
                        {dataPieNotRelevantCategorySite.filter(cat_ => cat_.totalSeconds > 0).slice(
                          irrelevantCategorySitesPage * rowsPerPageIrrelevantCategorySites,
                          irrelevantCategorySitesPage * rowsPerPageIrrelevantCategorySites + rowsPerPageIrrelevantCategorySites
                        ).map((cat, a) =>
                          <div id={"idIrrelevantSitesCategories_"+a} className={"list-category-parent irrelevant"} key={cat.idCategorysite} style={{ minWidth: '370px' }} >
                            <DashboardCategorySitesTRUE catSite={cat}
                              idUser={idUser}
                              idProfile={idProfile}
                              totalSeconds={totalTimePie2}
                              listUsers={users}
                              makeRelevant={true}
                              setRefreshPage={setRefreshPage}
                              listProfiles={listProfiles}
                              listCategories={listCategoriesSites}
                              setRefreshCategorySite={setRefreshCategorySite}
                              listAllActivities={listActivitiesOnlyBrowsers}
                              className={"div-app-windows-clean"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  {idSelectedCategorysite2 != "" &&
                    <div key={"_1"} style={styleList}>
                      {dataPieNotRelevant.filter(activity_ => activity_.totalSeconds > 0).slice(
                          irrelevantCategorySitesPage * rowsPerPageIrrelevantCategorySites,
                          irrelevantCategorySitesPage * rowsPerPageIrrelevantCategorySites + rowsPerPageIrrelevantCategorySites
                      ).map((activity, a) =>
                        <div id={"idIrrelevantSiteParent_"+a} className="list-app-parent irrelevant" style={{ minWidth: '370px' }}>
                          <DashboardAppSitesTRUE 
                            id={"idIrrelevantSite_"+a}
                            key={activity.idSite} 
                            activity={activity}
                            idUser={idUser}
                            idProfile={idProfile}
                            totalSeconds={totalTimePie2}
                            listUsers={users}
                            makeRelevant={true}
                            setRefreshPage={setRefreshPage}
                            listProfiles={listProfiles}
                            listCategories={listCategoriesSites}
                            setRefreshCategorySite={setRefreshCategorySite}
                            listAllActivities={listActivitiesOnlyBrowsers}
                            className={"div-app-windows"}
                          />
                        </div>
                      )}
                    </div>
                  }
                  <TablePagination
                    id="idPaginationIrrelevantSites"
                    labelRowsPerPage={t("elements.pagination.label")}
                    rowsPerPageOptions={[4,8,12]}
                    component={"div"}
                    onRowsPerPageChange={handleChangeIrrelevantCategorySitesRowsPerPage}
                    onPageChange={handleChangeIrrelevantCategorySitesPage}
                    count={irrelevantCategorySitesCount}
                    rowsPerPage={rowsPerPageIrrelevantCategorySites}
                    page={irrelevantCategorySitesPage}
                  />
                </CardContent>
              </Card>
          </Grid>}
        </Grid>
      }
    </Paper>
  );
}

const styleList = {
  overflow:"auto", 
  height: "16em"
}

const styleMarginTop = {
  marginTop: '0.5em'
}

const styleChartDiv = {
  height: "370px",
  marginBottom: "4.5em"
}

export default DashboardGeneral;
