import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {User} from "../../util/Interfaces";
import {useProjectPlan, useProjectPlanDispatch} from "./ProjectPlanContext";
import React from "react";
import ProjectTypeService from "../../services/projectType.service";
import ElementTypeService from "../../services/elementType.service";
import EstimationsService from "../../services/estimations.service";
import ProjectService from "../../services/project.service";
import UserService from "../../services/user.service";
import {MIN_LEVEL_ADMIN} from "../../util/Constants";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {HelperDialog, TrueffortButton, TrueffortChip, TrueffortConfirmAlert} from "../../MUIstyle/MUICustom";
import {
    Box,
    Card,
    CardContent,
    Collapse,
    Container,
    Grid, IconButton,
    Paper,
    Stack,
    SvgIcon,
    TextField,
    Typography
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import GenerateProjectForm from "./GenerateProjectForm";
import {EditNote, ExpandMore as ExpandMoreICon, KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import {DurationTypeContext, UsersContext} from "./EstimationPageUtils";
import EstimationDetailTable from "./EstimationDetailTable";
import {Theme, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { dateToDateLocale, formatSeconds } from "../../util/GlobalFunctions";
import {helperDialogs} from "../../util/HelperDialogs";
import HelpIcon from "@mui/icons-material/Help";
import { useState, useEffect, useMemo } from "react";

export default function EstimationPageManager({ formHelperDialogsConfig }: { formHelperDialogsConfig: { activeHelperDialogIndex: number, handleActiveHelperChange: () => void, handleClose: () => void } }) {
    const {t} = useTranslation();
    const {user} = useAuthContext() as { user: User };

    const projectPlan = useProjectPlan();
    //console.log(JSON.stringify(projectPlan));
    const projectPlanDispatch = useProjectPlanDispatch();
    const [showMessageWarning, setShowMessageWarning] = useState(JSON.stringify(projectPlan) != '{}');
    const theme: Theme = useTheme();
    const matches: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const belowSM: boolean = useMediaQuery(theme.breakpoints.down('sm'));

    const [isSaving, setIsSaving] = useState(false);
    const [detail, setDetail] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [statisticalRange, setStatisticalRange] = useState(false);
    const [durationFocused, setDurationFocused] = useState(false);
    const [duration, setDuration] = useState(
        projectPlan === null || projectPlan.estimatedDays === undefined ? 0 :
            projectPlan.estimatedDays
    );
    if (projectPlan.estimatedDays !== undefined && duration !== projectPlan.estimatedDays && !durationFocused) {
        setDuration(projectPlan.estimatedDays);
    }
    const [projectTypes, setProjectTypes] = useState([]);
    const [idProjectTypeBase, setIdProjectTypeBase] = useState(null);
    const [projectTypeBase, setProjectTypeBase] = useState(null);
    const [elementTypes, setElementTypes] = useState([]);
    const [users, setUsers] = useState([]);
    const [detailedEstimate, setDetailedEstimate] = useState("");
    const [leaders, setLeaders] = useState([]);
    const [activeDetailDialogIndex, setActiveDetailDialogIndex] = useState(-1);

    const projectTypeService = useMemo(() => new ProjectTypeService(), []);
    const elementTypeService = useMemo(() => new ElementTypeService(), []);
    const estimationsService = useMemo(() => new EstimationsService(), []);
    const projectService = useMemo(() => new ProjectService(), []);
    const userService = useMemo(() => new UserService(), []);

    const adjustedDurationMin = projectPlan.elementTypes === undefined ? 0 :
        statisticalRange ? detailedEstimate.duration_min :
            projectPlan.elementTypes.reduce(
                (acc: number, elemType: any) => {
                    let biggerProjectElemDuration = elemType.projectElements.reduce(
                        (acc2: number, projectElem: any) =>
                            acc2 < projectElem.estimatedDays ? projectElem.estimatedDays : acc2,
                        0
                    );

                    return acc < biggerProjectElemDuration ? biggerProjectElemDuration : acc;
                    },
                0
            );
    const adjustedDurationMax = projectPlan.elementTypes === undefined ? 0 :
        statisticalRange ? detailedEstimate.duration_max :
            projectPlan.elementTypes.reduce((acc: number, elemType: any) =>
                    acc + elemType.projectElements.reduce((acc2: number, pT: any) => acc2 + pT.estimatedDays, 0),
                0
            );
    const projectTimeMin = (projectPlan === null || projectPlan.elementTypes === undefined) ? 0 :
        projectPlan.elementTypes.reduce((acc: number, elemType: any) => {
            let min = elemType.projectElements.reduce(
                (acc2: number, elem: any) =>
                    acc2 < elem.estimatedDays ? elem.estimatedDays : acc2,
                0
            );

            return acc < min ? min : acc;
        }, 0);

    useEffect(() => {
        fillProjectTypes();
        if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
            fillLeaders();
        }
    }, []);

    useEffect(() => {
      //console.log('hook users');
      //console.log(projectPlan);
      if (JSON.stringify(projectPlan) != '{}') {
        if (searchForCeroValues().length > 0) {
          warningMessage(
              [
                t("estimation_page.no_data_warning1"),
                t("estimation_page.no_data_warning2"),
                t("estimation_page.no_data_warning3")
              ]
          );
        }
      }
    }, [users]);

    const fillProjectTypes = () => {
      // @ts-ignore
      projectTypeService.getAll().then(({data: response}) => {
        if (response.success) {
          if (Array.isArray(response.data)) {
            setProjectTypes(response.data);
            let ptBase = response.data.find((pt:any) => pt.name == user.baseTemplateName);
            setIdProjectTypeBase(ptBase.idProjectType);
            setProjectTypeBase(ptBase);
          }
        } else if (response.internalError) {
          toast.error(response.message);
        }
      }).catch((ex:any) => {
        console.log(ex);
      });
    }

    //console.log(projectPlan);

    const fillElementTypes = (idProjectType: number) => {
        // @ts-ignore
        elementTypeService.getAllWithTasksByProjectType(idProjectType).then(({data : response}) => {
            if (Array.isArray(response.data)) {
                setElementTypes(response.data);
            }
        });
    }

    const fillUsers = () => {
        // @ts-ignore
        userService.getAll().then(({data: response}) => {
            if (Array.isArray(response)) {
                // SÓLO SE MUESTRAN LOS USUARIOS QUE YA ESTÁN HABILITADOS,
                // ASÍ COMO LOS QUE NO HAY PARTICIPADO EN ALGUNA ACTIVIDAD DEL TIPO
                const arrUsersEnabled = response.filter((data: any) => data.isEnabled);
                setUsers(arrUsersEnabled);
            }
        });
    }

    const fillLeaders = () => {
        // @ts-ignore
        userService.getLeaders().then(({data: response}) => {
            //console.log(response);
            if (Array.isArray(response)) {
                setLeaders(response);
            }
        });
    }

    const submitProjectPlan = () => {
      // @ts-ignore
      //console.log(projectPlan);
      //let item = JSON.parse(JSON.stringify(projectPlan));
      //console.log(item);
      //item.registredDate = dateToDateLocale(projectPlan.registredDate).toISOString().split('T')[0];
      //return;
      projectService.createPlan(projectPlan).then(({data : response} : any) => {
        if (response.internalError != null && response.internalError && response.message != null) {
          toast.error(response.message);
          setIsSaving(false);
        } else {
          toast.success(
            t("estimation_page.msg_success_save"), 
            {
              onClose: () => window.location.replace("/projectDetail?idProject=" + response.data)
            }
          );
        }
      });
    }

    const getEstimate = (formData: any) => {
        let tiposElementosArr = [] as any;
        formData.elementsForm.forEach((elem: any) => {
            tiposElementosArr.push({
               id: elem.idElemento,
               id_size: elem.tamanio,
               id_complexity: elem.complejidad
            });
        });
        const datos = {
            "project_types": [parseInt(formData.idSelectedProjectType)],
            "element_types": tiposElementosArr
        }

        // @ts-ignore
        estimationsService.getAccumulatedEstimate(datos).then(({data: response}) => {
            //console.log("EST", response);
            if (response.status === "warning") {
                toast.error(t(response.code));
            } else {
                console.log(formData);
                projectPlanDispatch({
                    type: 'create',
                    createData: {
                        estimation: response.projecttypes[0],
                        projectName: formData.projectName,
                        idsLeader: formData.leadersIds,
                        idSelectedMeasurement: formData.idSelectedMeasurement,
                        elementsForm: formData.elementsForm,
                        registredDate: formData.registredDate
                    }
                });
                setDetailedEstimate(response.projecttypes[0]);
                fillUsers();
            }
        }).catch(function (error: any) {
            //console.log(error);
            toast.error("Ocurrió un problema al ejecutar la estimación (" + error + ")");
        });
    }

    const searchForCeroValues = () => {
        if (projectPlan.estimatedDays === 0 || projectPlan.estimatedTime === 0) {
            return [-1, -1];
        }

        for (let i: number = 0; i < projectPlan.elementTypes.length; i++) {
            for (let j: number = 0; j < projectPlan.elementTypes[i].projectElements.length; j++) {
                if (!projectPlan.elementTypes[i].projectElements[j].estimatedTime
                    || !projectPlan.elementTypes[i].projectElements[j].estimatedDays) {
                    return [i, j];
                }

                for (let k: number = 0; k < projectPlan.elementTypes[i].projectElements[j].projectPhases.length; k++) {
                    if (!projectPlan.elementTypes[i].projectElements[j].projectPhases[k].estimatedTime
                        || !projectPlan.elementTypes[i].projectElements[j].projectPhases[k].estimatedDays) {
                        return [i, j];
                    }

                    if (!projectPlan.elementTypes[i].projectElements[j].projectPhases[k]
                        .projectTasks.every((task: any) => task.estimatedDays && task.estimatedTime)) {
                        return [i, j];
                    }
                }
            }
        }

        return [];
    }

    const warningMessage = (messages: string[]) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return(
                    <TrueffortConfirmAlert
                        title={
                            <Typography color='#6A6A6A' variant='h6' fontWeight={700} marginBottom='20px' >
                                <InfoIcon sx={{ color: '#6A6A6A' }} />&nbsp;&nbsp;{t("Warning")}
                            </Typography>
                        }
                        alertColor='#6A6A6A'
                        messages={messages}
                        buttons={[
                            {
                                label: t("accept"),
                                onClick: onClose
                            },
                        ]}
                    />
                );
            }
        });
    }

    const handleShowDetail = () => {
        setDetail(true);
        setShowForm(false);
        /*if (searchForCeroValues().length > 0) {
            warningMessage(
                [
                    t("estimation_page.no_data_warning1"),
                    t("estimation_page.no_data_warning2"),
                    t("estimation_page.no_data_warning3")
                ]
            );
        }*/
    }

    const handlePlanDurationChange = (timeValue: number, changeLevel: number, planIndexes: any) => {
        if (timeValue < projectTimeMin || timeValue < 0 || (!statisticalRange && timeValue > adjustedDurationMax)) {
            return;
        }

        projectPlanDispatch({
            type: 'changeDuration',
            newDurationValue: timeValue,
            level: changeLevel,
            planIndexes: planIndexes,
            adjustedDuration: !statisticalRange
        });
    }

    const handleAdjustedDuration = (fullRange: boolean) => {
        setStatisticalRange(fullRange);
        if (!fullRange) {
            projectPlanDispatch({
                type: "adjustDurationEstimates",
                adjustedDuration: true,
                estimation: undefined
            });
        } else {
            projectPlanDispatch({
                type: "adjustDurationEstimates",
                adjustedDuration: false,
                estimation: detailedEstimate
            });
        }
    }

    const handleDurationBlur = () => {
        if (duration === '') {
            setDuration(projectPlan.estimatedDays);
        } else {
            handlePlanDurationChange(Math.round(Number(duration)), 0, 0);
        }

        setDurationFocused(false);
    }

    const handleProjectEstimationCancel = () => {
        setDetailedEstimate("");
        projectPlanDispatch({ type: 'reset' });
        setDetail(false);
        setStatisticalRange(false);
        setShowForm(true);
    }

    const handleOpenDetailHelp = () => {
        setActiveDetailDialogIndex(0);
    }

    const handleDetailHelperClose = () => {
        setActiveDetailDialogIndex(-1);
    };

    const handleActiveDetailHelperChange = () => {
        if (activeDetailDialogIndex === helperDialogs['estimationDetail'].length) {
            setActiveDetailDialogIndex(-1)
        }

        else {
            setActiveDetailDialogIndex((prev: number) => prev + 1)
        }
    }

    return (
        <>
            <Container maxWidth='xl' sx={{ marginTop: '0' }} >
                <Paper elevation={0} sx={{ padding: '6px', bgcolor: '#F5F5F5' }} >
                    <Collapse
                        in={!detail || showForm || (formHelperDialogsConfig.activeHelperDialogIndex !== -1)}
                        timeout="auto"
                    >
                        <GenerateProjectForm
                            t={t}
                            fetchedData={{
                                projectTypes: projectTypes,
                                elementTypes: elementTypes,
                                leaders: leaders
                            }}
                            detail={detail}
                            getEstimate={getEstimate}
                            cancelProjectEstimation={handleProjectEstimationCancel}
                            fillElementTypes={fillElementTypes}
                            setElementTypes={setElementTypes}
                            helperDialogsConfig={formHelperDialogsConfig}
                            idProjectTypeBase={idProjectTypeBase}
                            projectTypeBase={projectTypeBase}
                        />
                    </Collapse>

                    {detail &&
                        <Box
                            sx={{ width: '100%', background: "#F5F5F5", margin: '0.2em' }}
                            className="box-show-more"
                            onClick={() => setShowForm(!showForm)}>
                            <Typography
                                color='#4A4A4A'
                                variant='subtitle2'
                                sx={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}
                            >
                                {detail && showForm &&
                                    <KeyboardArrowUpIcon fontSize="small" />
                                }

                                {detail && !showForm &&
                                    <ExpandMoreICon fontSize="small" />
                                }

                                {t("estimation_page.generate_project")}
                            </Typography>
                        </Box>
                    }
                </Paper>
            </Container>

            <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
                <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5' }} >

                    <Typography color='#4A4A4A' >
                        {t("estimation_page.automatic_estimation")} &nbsp;

                        {detail &&
                            <IconButton
                                size='small'
                                onClick={handleOpenDetailHelp}
                            >
                                <HelpIcon sx={{ color: '#6875C7' }} />
                            </IconButton>
                        }
                    </Typography>

                    <Grid container direction="row" alignItems="stretch" spacing={1} >
                        <Grid item xs >
                            <Card elevation={1} sx={{ height: '100%' }} >
                                <CardContent >
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" >
                                        <Grid item xs >
                                            <Typography color='#050536' textAlign='center' >
                                                {t("estimation_page.estimated_total_effort")}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs >
                                            <HelperDialog
                                                title={t(helperDialogs["estimation"][10].title)}
                                                body={t(helperDialogs["estimation"][10].body)}
                                                dialogIndex={10}
                                                totalDialogs={helperDialogs["estimation"].length}
                                                handleNext={formHelperDialogsConfig.handleActiveHelperChange}
                                                isOpen={formHelperDialogsConfig.activeHelperDialogIndex === 10}
                                                handleClose={formHelperDialogsConfig.handleClose}
                                            >
                                                <Stack direction='row' spacing={1} justifyContent='center' alignContent='center' >
                                                    <Typography variant="h4" color='#000000' >
                                                        {(projectPlan === null || projectPlan.estimatedTime === undefined) ? formatSeconds(0, false, false, false) :
                                                            formatSeconds(projectPlan.estimatedTime * 3600, false, false, false)
                                                        }
                                                    </Typography>
                                                    {user.timeFormat == 'dec' ?
                                                    <Typography variant="h6" color='#000000' >
                                                        {t("estimation_page.man_hours")}
                                                    </Typography> : null}
                                                </Stack>
                                            </HelperDialog>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs >
                            <Card elevation={1} sx={{ height: '100%' }} >
                                <CardContent >
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" >
                                        <Grid item xs >
                                            <Typography color='#050536' >
                                                {t("estimation_page.estimated_total_time")}
                                            </Typography>
                                        </Grid>

                                        {belowSM ?
                                            <>
                                                <Grid item xs >
                                                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' >
                                                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                                            <Typography variant='caption' >
                                                                Min
                                                            </Typography>

                                                            <TrueffortChip
                                                                icon={
                                                                    <SvgIcon >
                                                                        <svg
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                                                fill={
                                                                                    projectPlan.estimatedDays ===
                                                                                    /*detailedEstimate.duration_min*/
                                                                                    adjustedDurationMin ?
                                                                                        'white' :
                                                                                        '#6693CB'
                                                                                }
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                                size='small'
                                                                color='secondary'
                                                                variant={
                                                                    projectPlan.estimatedDays ===
                                                                    /*detailedEstimate.duration_min*/
                                                                    adjustedDurationMin ?
                                                                        'filled' :
                                                                        'outlined'
                                                                }
                                                                label={
                                                                    projectPlan.estimatedDays === undefined ? '--' :
                                                                        /*detailedEstimate.duration_min*/
                                                                        adjustedDurationMin
                                                                }
                                                                disabled={projectPlan.estimatedDays === undefined}
                                                                onClick={() =>
                                                                    handlePlanDurationChange(
                                                                        /*detailedEstimate.duration_min*/
                                                                        adjustedDurationMin,
                                                                        0,
                                                                        0
                                                                    )
                                                                }
                                                            />
                                                        </Stack>

                                                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                                            <Typography variant='caption' >
                                                                Max
                                                            </Typography>

                                                            <TrueffortChip
                                                                icon={
                                                                    <SvgIcon >
                                                                        <svg
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                                                fill={
                                                                                    projectPlan.estimatedDays ===
                                                                                    /*detailedEstimate.duration_max*/
                                                                                    adjustedDurationMax ?
                                                                                        'white' :
                                                                                        '#6693CB'
                                                                                }
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                                size='small'
                                                                color='secondary'
                                                                variant={
                                                                    projectPlan.estimatedDays ===
                                                                    /*projectPlan.duration_max*/
                                                                    adjustedDurationMax ?
                                                                        'filled' :
                                                                        'outlined'
                                                                }
                                                                label={
                                                                    projectPlan.estimatedDays === undefined ? '--' :
                                                                        /*detailedEstimate.duration_max*/
                                                                        adjustedDurationMax
                                                                }
                                                                disabled={projectPlan.estimatedDays === undefined}
                                                                onClick={() =>
                                                                    handlePlanDurationChange(
                                                                        /*detailedEstimate.duration_max,*/
                                                                        adjustedDurationMax,
                                                                        0,
                                                                        0
                                                                    )
                                                                }
                                                            />
                                                        </Stack>

                                                    </Stack>
                                                </Grid>

                                                <Grid item xs >
                                                    <Stack direction='row' spacing={1} justifyContent='center' alignContent='center' >
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            disabled={projectPlan === null || projectPlan.estimatedDays === undefined}
                                                            //sx={{ height: '2.5rem' }}
                                                            //InputProps={{ inputProps: { min: projectTimeMin } }}
                                                            inputProps={{
                                                                min: projectTimeMin,
                                                                style: {
                                                                    height: '2.15rem',
                                                                    width: '100px',
                                                                    fontFamily: 'ofelia-display, sans-serif',
                                                                    fontSize: '2.125rem'
                                                                }
                                                            }}
                                                            onFocus={() => setDurationFocused(true)}
                                                            onBlur={handleDurationBlur}
                                                            value={durationFocused ? duration :
                                                                (projectPlan.estimatedDays === undefined ? 0 :
                                                                    projectPlan.estimatedDays)
                                                            }
                                                            onChange={(e: any) =>
                                                                durationFocused ? setDuration(e.target.value) :
                                                                    handlePlanDurationChange(
                                                                        Number(e.target.value),
                                                                        0,
                                                                        0
                                                                    )
                                                            }
                                                            onKeyDown={(event: any) => {
                                                                if (event.key === "Enter")
                                                                    handlePlanDurationChange(Math.round(Number(event.target.value)), 0, 0);
                                                            }}
                                                        />

                                                        {/*<Typography variant="h6" color='#000000' >
                                                            {t("estimation_page.days")}
                                                        </Typography>*/}

                                                        <Stack direction='column' spacing={-1} justifyContent='center' alignContent='center' >
                                                            <Typography variant="h6" color='#000000' >
                                                                {t("estimation_page.days")}
                                                            </Typography>

                                                            <IconButton
                                                                //sx={{maxWidth: '10px', maxHeight: '10px', minWidth: '10px', minHeight: '10px'}}
                                                                size='small'
                                                                color='secondary'
                                                                onClick={() =>
                                                                    handlePlanDurationChange(
                                                                        !statisticalRange ?
                                                                            detailedEstimate.duration_avg :
                                                                            Math.round((adjustedDurationMin + adjustedDurationMax) / 2),
                                                                        0,
                                                                        0
                                                                    )
                                                                }
                                                                disabled={projectPlan.estimatedDays === undefined}
                                                            >
                                                                <ReplayIcon fontSize="small" />
                                                            </IconButton>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </>
                                            :
                                            <Grid item xs >
                                                <Stack direction='row' spacing={3} alignItems='center' justifyContent='center' >
                                                    <HelperDialog
                                                        title={t(helperDialogs["estimationDetail"][1].title)}
                                                        body={t(helperDialogs["estimationDetail"][1].body)}
                                                        dialogIndex={1}
                                                        totalDialogs={projectPlan.elementTypes !== undefined && !projectPlan.elementTypes.length ?
                                                            helperDialogs["estimationDetail"].length - 2 :
                                                            helperDialogs["estimationDetail"].length
                                                        }
                                                        handleNext={handleActiveDetailHelperChange}
                                                        isOpen={activeDetailDialogIndex === 1}
                                                        handleClose={handleDetailHelperClose}
                                                    >
                                                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                                            <Typography variant='caption' >
                                                                Min
                                                            </Typography>

                                                            <TrueffortChip
                                                                icon={
                                                                    <SvgIcon >
                                                                        <svg
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                                                fill={
                                                                                    projectPlan.estimatedDays ===
                                                                                    /*detailedEstimate.duration_min*/
                                                                                    adjustedDurationMin ?
                                                                                        'white' :
                                                                                        '#6693CB'
                                                                                }
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                                size='small'
                                                                color='secondary'
                                                                variant={
                                                                    projectPlan.estimatedDays ===
                                                                    /*detailedEstimate.duration_min*/
                                                                    adjustedDurationMin ?
                                                                        'filled' :
                                                                        'outlined'
                                                                }
                                                                label={
                                                                    projectPlan.estimatedDays === undefined ? '--' :
                                                                        /*detailedEstimate.duration_min*/
                                                                        adjustedDurationMin
                                                                }
                                                                disabled={projectPlan.estimatedDays === undefined}
                                                                onClick={() =>
                                                                    handlePlanDurationChange(
                                                                        /*detailedEstimate.duration_min,*/
                                                                        adjustedDurationMin,
                                                                        0,
                                                                        0
                                                                    )
                                                                }
                                                            />
                                                        </Stack>
                                                    </HelperDialog>

                                                    <HelperDialog
                                                        title={t(helperDialogs["estimation"][11].title)}
                                                        body={t(helperDialogs["estimation"][11].body)}
                                                        dialogIndex={11}
                                                        totalDialogs={helperDialogs["estimation"].length}
                                                        handleNext={formHelperDialogsConfig.handleActiveHelperChange}
                                                        isOpen={formHelperDialogsConfig.activeHelperDialogIndex === 11}
                                                        handleClose={formHelperDialogsConfig.handleClose}
                                                    >
                                                        <Stack direction='row' spacing={1} justifyContent='center' alignContent='center' >
                                                            <TextField
                                                                type="number"
                                                                size="small"
                                                                disabled={projectPlan === null || projectPlan.estimatedDays === undefined}
                                                                //sx={{ height: '2.5rem' }}
                                                                //InputProps={{ inputProps: { min: projectTimeMin } }}
                                                                inputProps={{
                                                                    min: projectTimeMin,
                                                                    style: {
                                                                        height: '2.15rem',
                                                                        width: '100px',
                                                                        fontFamily: 'ofelia-display, sans-serif',
                                                                        fontSize: '2.125rem'
                                                                    }
                                                                }}
                                                                onFocus={() => setDurationFocused(true)}
                                                                onBlur={handleDurationBlur}
                                                                value={durationFocused ? duration :
                                                                    (projectPlan.estimatedDays === undefined ? 0 :
                                                                        projectPlan.estimatedDays)
                                                                }
                                                                onChange={(e: any) =>
                                                                    durationFocused ? setDuration(e.target.value) :
                                                                        handlePlanDurationChange(
                                                                            Number(e.target.value),
                                                                            0,
                                                                            0
                                                                        )
                                                                }
                                                                onKeyDown={(event: any) => {
                                                                    if (event.key === "Enter")
                                                                        handlePlanDurationChange(Math.round(Number(event.target.value)), 0, 0);
                                                                }}
                                                            />

                                                            <Stack direction='column' spacing={-1} justifyContent='center' alignContent='center' >
                                                                <Typography variant="h6" color='#000000' >
                                                                    {t("estimation_page.days")}
                                                                </Typography>

                                                                <HelperDialog
                                                                    title={t(helperDialogs["estimationDetail"][3].title)}
                                                                    body={t(helperDialogs["estimationDetail"][3].body)}
                                                                    dialogIndex={3}
                                                                    totalDialogs={projectPlan.elementTypes !== undefined && !projectPlan.elementTypes.length ?
                                                                        helperDialogs["estimationDetail"].length - 2 :
                                                                        helperDialogs["estimationDetail"].length
                                                                    }
                                                                    handleNext={handleActiveDetailHelperChange}
                                                                    isOpen={activeDetailDialogIndex === 3}
                                                                    handleClose={handleDetailHelperClose}
                                                                >
                                                                    <IconButton
                                                                        //sx={{maxWidth: '10px', maxHeight: '10px', minWidth: '10px', minHeight: '10px'}}
                                                                        size='small'
                                                                        color='secondary'
                                                                        onClick={() =>
                                                                            handlePlanDurationChange(
                                                                                statisticalRange ?
                                                                                    detailedEstimate.duration_avg :
                                                                                    Math.round((adjustedDurationMin + adjustedDurationMax) / 2),
                                                                                0,
                                                                                0
                                                                            )
                                                                        }
                                                                        disabled={projectPlan.estimatedDays === undefined}
                                                                    >
                                                                        <ReplayIcon fontSize="small" />
                                                                    </IconButton>
                                                                </HelperDialog>
                                                            </Stack>
                                                        </Stack>
                                                    </HelperDialog>

                                                    <HelperDialog
                                                        title={t(helperDialogs["estimationDetail"][2].title)}
                                                        body={t(helperDialogs["estimationDetail"][2].body)}
                                                        dialogIndex={2}
                                                        totalDialogs={projectPlan.elementTypes !== undefined && !projectPlan.elementTypes.length ?
                                                            helperDialogs["estimationDetail"].length - 2 :
                                                            helperDialogs["estimationDetail"].length
                                                        }
                                                        handleNext={handleActiveDetailHelperChange}
                                                        isOpen={activeDetailDialogIndex === 2}
                                                        handleClose={handleDetailHelperClose}
                                                    >
                                                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                                            <Typography variant='caption' >
                                                                Max
                                                            </Typography>

                                                            <TrueffortChip
                                                                icon={
                                                                    <SvgIcon >
                                                                        <svg
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                                                fill={
                                                                                    projectPlan.estimatedDays ===
                                                                                    /*detailedEstimate.duration_max*/
                                                                                    adjustedDurationMax ?
                                                                                        'white' :
                                                                                        '#6693CB'
                                                                                }
                                                                            />
                                                                        </svg>
                                                                    </SvgIcon>
                                                                }
                                                                size='small'
                                                                color='secondary'
                                                                variant={
                                                                    projectPlan.estimatedDays ===
                                                                    adjustedDurationMax ?
                                                                        'filled' :
                                                                        'outlined'
                                                                }
                                                                label={
                                                                    projectPlan.estimatedDays === undefined ? '--' :
                                                                        /*detailedEstimate.duration_max*/
                                                                        adjustedDurationMax
                                                                }
                                                                disabled={projectPlan.estimatedDays === undefined}
                                                                onClick={() =>
                                                                    handlePlanDurationChange(
                                                                        /*detailedEstimate.duration_max,*/
                                                                        adjustedDurationMax,
                                                                        0,
                                                                        0
                                                                    )
                                                                }
                                                            />
                                                        </Stack>
                                                    </HelperDialog>
                                                </Stack>
                                            </Grid>
                                        }

                                        <Grid item xs >
                                            <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' >
                                                <div>
                                                    <TrueffortChip
                                                        icon={<ReplayIcon />}
                                                        color='secondary'
                                                        variant={!statisticalRange ? 'filled' : 'outlined'}
                                                        label={t("estimation_page.default_duration")}
                                                        disabled={projectPlan === null || projectPlan.estimatedDays === undefined}
                                                        onClick={() =>
                                                            /*handlePlanDurationChange(
                                                                detailedEstimate.duration_avg,
                                                                0,
                                                                0
                                                            )*/
                                                            handleAdjustedDuration(false)
                                                        }
                                                    />
                                                </div>

                                                <HelperDialog
                                                    title={t(helperDialogs["estimationDetail"][4].title)}
                                                    body={t(helperDialogs["estimationDetail"][4].body)}
                                                    dialogIndex={4}
                                                    totalDialogs={projectPlan.elementTypes !== undefined && !projectPlan.elementTypes.length ?
                                                        helperDialogs["estimationDetail"].length - 2 :
                                                        helperDialogs["estimationDetail"].length
                                                    }
                                                    handleNext={handleActiveDetailHelperChange}
                                                    isOpen={activeDetailDialogIndex === 4}
                                                    handleClose={handleDetailHelperClose}
                                                >
                                                    <div>
                                                        <TrueffortChip
                                                            icon={<EqualizerIcon />}
                                                            color='secondary'
                                                            variant={statisticalRange ? 'filled' : 'outlined'}
                                                            label={t("estimation_page.statistical_range")}
                                                            disabled={projectPlan === null || projectPlan.estimatedDays === undefined}
                                                            onClick={() => handleAdjustedDuration(true)}
                                                        />
                                                    </div>
                                                </HelperDialog>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

            {!detail &&
                <Container
                    maxWidth='xl'
                    sx={{
                        marginTop: '20px',
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: 'flex-end'
                    }}
                >
                    <TrueffortButton
                        id="estimate-detail-button"
                        color="indigo"
                        size="small"
                        variant="contained"
                        fullWidth={matches}
                        startIcon={<EditNote />}
                        disabled={projectPlan === null || projectPlan.idProjectType === undefined}
                        onClick={
                            /*() => setDetail(true)*/
                            handleShowDetail
                        }
                    >
                        {t("estimation_page.detail_estimate")}
                    </TrueffortButton>
                </Container>
            }

            {detail &&
                <Container maxWidth='xl' sx={{ marginTop: '20px', marginBottom: '20px' }} >
                    <UsersContext.Provider value={users} >
                        <DurationTypeContext.Provider value={statisticalRange} >
                            <EstimationDetailTable
                                idProjectType={projectPlan.idProjectType}
                                projectTypeName={projectTypes.find(
                                    (pt: any) =>
                                        pt.idProjectType === projectPlan.idProjectType
                                    ).name
                                }
                                savingState={{
                                    isSaving: isSaving,
                                    setIsSaving: setIsSaving
                                }}
                                estimateState={{
                                    detailedEstimate: detailedEstimate,
                                    setDetailedEstimate: setDetailedEstimate
                                }}
                                elementTypes={elementTypes}
                                warningMessage={warningMessage}
                                searchForCeroValues={searchForCeroValues}
                                submitProjectPlan={submitProjectPlan}
                                handleProjectEstimationCancel={handleProjectEstimationCancel}
                                detailHelperDialogsConfig={{
                                    activeDetailDialogIndex: activeDetailDialogIndex,
                                    handleActiveDetailHelperChange: handleActiveDetailHelperChange,
                                    handleDetailHelperClose: handleDetailHelperClose
                                }}
                            />
                        </DurationTypeContext.Provider>
                    </UsersContext.Provider>
                </Container>
            }
        </>
    );
}