// @flow
import React from "react";
import {
  Card, TableContainer, Paper, TableHead, TableBody, Table,
  Chip, CardContent, TablePagination, Box, Stack
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useFormik} from "formik";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import ApiRequest from "../../helpers/ApiRequest";
import {useTranslation} from "react-i18next";
import DepartamentService from "../../services/departament.service";
import '../../css/FormCrud.css';
import {confirmAlert} from "react-confirm-alert";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { StyledTableRow, StyledTableCell, TrueffortButton, TrueffortCardTable, TrueffortSearchInput, 
    TrueffortTableHeadCell, TrueffortTextField, TrueffortInputLabel } from "../../MUIstyle/MUICustom";
import { Add, Edit, Delete, DeleteOutline, Save, Clear } from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useMemo, useEffect } from "react";
import CardEmpty from "../custom/CardEmpty";

function  DepartmentsTab() {
    const {user} = useAuthContext();
    const {t} = useTranslation();

    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));

    const [departament, setDepartament] = useState([]);
    const [departamentFiltered, setDepartamentFiltered] = useState([]);
    const [showFormAdd, setShowFormAdd] = useState(false);
    
    const [rowsPerPagedepartament, setRowsPerPagedepartament] = useState(10);
    const [departamentPage, setDepartamentPage] = useState(0);
    const [departamentCount, setDepartamentCount] = useState(0);//Cantidad de categorias

    const [state, setState] = useState({ loading: false, isEditing: false, isUpdating: false, isCreating: false});
    const departamentService = useMemo(() => new DepartamentService(), []);

    const initialValues = useMemo(() => {
        return {
            idDepartament: null,
            name: '',
            isEditing: false,
            isCreating: false,
            isLoading: false
        }
    }, [departament]);

    useEffect(() => {
        fillDepartaments();
    }, []);

    const fillDepartaments = () => {
      // @ts-ignore
      departamentService.getAll(user.instance.idInstance).then(({data: response}) => {
        //console.log(response);
        if (Array.isArray(response.data)) {
          setDepartament(response.data);
          setDepartamentFiltered(response.data);
          setDepartamentCount(response.data.length);
        }
      });
    }

    const handleChangeSearchInput = (searchFilter) => {
        let items = departament.filter((data) => {
            if (searchFilter == "") {
                return departament;
            } else {
                if (data.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return data;
                }
            }
        });

        setDepartamentFiltered(items);
        setDepartamentCount(items.length);
    }

    const handleSubmit = async (values, setValues) => {
        //console.log("handleSubmit");
        //console.log(applicationsValues);

        const dataRequest = {
            name: values.name,
            instance: {idInstance: user.instance.idInstance}
        };
        //console.log("data sent new category" + JSON.stringify(dataRequest));
        departamentService.create(dataRequest).then(({data:response}) => {
          console.log(response);
          if (response.success) {
            toast.success(t("catalogs_page.departaments.msg_success_add"));
            formik.setSubmitting(false);
            handleCancel();
            fillDepartaments();
            formik.resetForm();
          } else {
            toast.error(t(response.message));
          }
        }).catch(err => toast.error(t(err.message)));
    }
    //console.log(state);

    const handleEdit = async (values, setValues) => {
        //console.log("submit");
        const dataRequest = {
            idDepartament: values.idDepartament,
            name: values.name,
            instance: {idInstance: user.instance.idInstance},
        };
        //console.log("data sent edited category" + JSON.stringify(dataRequest));
          departamentService.update(dataRequest).then(({data:response}) => {
            if (response.success) {
              toast.success(t("catalogs_page.departaments.msg_success_update"));
              setShowFormAdd(false);
              fillDepartaments();
              formik.resetForm();
            } else {
              toast.error(t(response.message));
            }
          }).catch(err => toast.error(t(err.message)));
    }

    function prepareEdit(depto) {
        setState({...state, isUpdating: true});
        formik.setValues({...initialValues, ...depto, isEditing: true});
        setDepartamentFiltered(departament.map((item_) => item_.idDepartament === depto.idDepartament ? {
            ...item_,
            isEditing: true
        } : item_));
    }

    const validate = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = 'Required';
        } else if (values.name.length > 25) {
            errors.name = 'forms.nameLengthValidation25';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: initialValues,
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues, resetForm}) => {
            //console.log(values);
            if (!state.isUpdating) {
                handleSubmit(values, setValues).then(() => {
                    setSubmitting(false);
                    setState({...state, isUpdating: false, isCreating: false});
                })
            } else {
                handleEdit(values, setValues).then(() => {

                    setSubmitting(false);
                    setState({...state, isUpdating: false, isCreating: false});
                })
            }
        },
    });

    const handleDelete = (dep) => {
        confirmAlert({
            title: t("catalogs_page.departaments.delete_title"),
            message: t("catalogs_page.departaments.delete_body").replace("{0}", dep.name),
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            departamentService.delete(dep.idDepartament).then(({data: response}) => {
                                if (response.internalError != null && response.internalError && response.message != null) {
                                    toast.error(t(response.message));
                                } else {
                                    toast.success(t("catalogs_page.departaments.msg_success_delete"));
                                    fillDepartaments();
                                }
                            });
                        } catch (error) {
                            toast.error(t(error.message));
                            toast.error("Ocurrió un error al eliminar el departamento.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const handleCancel = () => {
        formik.setValues({...initialValues, isLoading: false, isCreating: false, isEditing: false});
        setDepartament(departament.map((dep) => {
            return {
                ...dep,
                isEditing: false
            }
        }));
        formik.resetForm();
        setState({...state, isCreating: false});
        setShowFormAdd(false);
    };

    const handleChangedepartamentRowsPerPage = (event) => {
        setRowsPerPagedepartament(parseInt(event.target.value, 10));
        setDepartamentPage(0);
    }
    
    const handleChangedepartamentPage = (event, newPage) => {
        setDepartamentPage(newPage);
    }

    return (
        <Card sx={{padding:"8px", background:"#EFEFEF", overflow:"unset"}}>
            <Grid container spacing={1} rowSpacing={3} style={{marginBottom:"0.4em"}}>
                <Grid item xs={12} >
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={1} >
                        <TrueffortSearchInput
                            id="idSearchInput"
                            placeholder={t("catalogs_page.departaments.search")}
                            variant="outlined"
                            size="small"
                            fullWidth={matchesXS}
                            onChange={(e) => handleChangeSearchInput(e.target.value)}
                        />
                        <TrueffortButton
                            id="idBtnAddCategory"
                            variant="contained"
                            color="secondary"
                            fullWidth={matchesXS}
                            startIcon={<Add />}
                            onClick={() => {
                                setState({...state, isUpdating: false, isCreating: true});
                                setShowFormAdd(true);
                            }}
                            disabled={state.isUpdating}
                            maxHeight="25px"
                        >{t("add_new")}</TrueffortButton>
                    </Stack>
                </Grid>

            {showFormAdd &&
            <Paper item style={{ marginBottom: "0.4em", width:'100%'  }}>
                <CardContent>{t("catalogs_page.departaments.create")}
                    <Grid container spacing={1} >
                        <Grid item xl={10} lg={8} md={8} sm={8} xs={12}>
                            <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                            <TrueffortTextField
                                id="field-name-category-new"
                                name="name"
                                size="small"
                                fullWidth
                                required
                                disabled={formik.isSubmitting}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors?.name): null}
                            />
                        </Grid>
                        <Grid item xl={2} lg={4} md={4} sm={4} xs={12}>
                            <div style={{"float": "right", display:"flex", alignItems:"flex-end"}}>
                                <TrueffortButton 
                                    id="new-departament-button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={formik.handleSubmit}><Save size="small" /></TrueffortButton>
                                <div style={{paddingRight:'0.2em'}}></div>
                                <TrueffortButton
                                    id="cancel-new-departament-button" 
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    disabled={formik.isSubmitting} 
                                    onClick={handleCancel}
                                ><Clear size="small" /></TrueffortButton>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>}
            {departamentFiltered.length == 0 ? <CardEmpty>
                <div>{t("msg.info.empty_response")}</div>
              </CardEmpty> :
              <TrueffortCardTable>
                  <Grid container spacing={1} rowSpacing={3} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                      <TableContainer component={Paper}
                          onSubmit={formik.handleSubmit}
                          /*sx={{overflow:"unset"}}*/
                      >
                          <Table responsive={"true"}>
                              <TableHead>
                                  <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                      <TrueffortTableHeadCell>{t("Name")}</TrueffortTableHeadCell>
                                      <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                                  </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                  {departamentFiltered.slice(departamentPage * rowsPerPagedepartament,
                                      departamentPage * rowsPerPagedepartament + rowsPerPagedepartament).map((dep, k) => (
                                  !dep.isEditing ?
                                      <StyledTableRow key={k}>
                                          <StyledTableCell>
                                              {dep.name}</StyledTableCell>
                                          <StyledTableCell className="tbl-td-center">
                                              <div style={{ display: "flex" }}>
                                                  <Chip size="small"
                                                      icon={<Edit fontSize="small" />}
                                                      variant="outlined"
                                                      color='primary'
                                                      disabled={dep.isEditing}
                                                      onClick={() => prepareEdit(dep)}
                                                      label={t("elements.buttons.edit")}
                                                  />
                                                  <div style={{ marginLeft: "0.5em" }} />
                                                  <Chip size="small"
                                                      icon={<Delete fontSize="small" />}
                                                      onClick={() => handleDelete(dep)}
                                                      label={t("elements.buttons.delete")}
                                                      variant="outlined"
                                                      color="error"
                                                      disabled={dep.isEditing}
                                                  >
                                                      <DeleteOutline fontSize="small" />
                                                  </Chip>
                                              </div>
                                          </StyledTableCell>
                                      </StyledTableRow> :
                                      <StyledTableRow key={k}>
                                          <StyledTableCell colSpan={4}>
                                              <Grid container spacing={1}>
                                                  <Grid item lg={10} md={10}>
                                                      <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                                                      <TrueffortTextField
                                                          id={"field-name-category-name"}
                                                          name="name"
                                                          size="small"
                                                          required={true}
                                                          value={formik.values.name}
                                                          placeholder={t("Name")}
                                                          onBlur={formik.handleBlur}
                                                          onChange={formik.handleChange}
                                                          error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                          disabled={formik.isSubmitting}
                                                          fullWidth
                                                      />
                                                  </Grid>
                                                  <Grid item lg={2} md={2} style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center'}}>
                                                      <Chip size="small"
                                                          icon={<Save fontSize="small" />}
                                                          variant="outlined"
                                                          color='success'
                                                          disabled={state.isEditing}
                                                          onClick={formik.handleSubmit}
                                                          label={t("elements.buttons.save")}
                                                      />
                                                      <div style={{ marginLeft: "0.5em" }} />
                                                      <Chip size="small"
                                                          icon={<Clear fontSize="small" />}
                                                          onClick={() => {
                                                              dep["isEditing"] = false;
                                                              formik.resetForm();
                                                              setState({...state, isUpdating: false});
                                                          }}
                                                          label={t("elements.buttons.cancel")}
                                                          variant="outlined"
                                                          color="error"
                                                      />
                                                  </Grid>
                                              </Grid>
                                          </StyledTableCell>
                                      </StyledTableRow>
                              ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                      <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                          <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                              alignItems: "center", marginTop: '1em' }}>
                              <TablePagination 
                                  labelRowsPerPage={t("elements.pagination.label")}
                                  rowsPerPageOptions={[10,20,30]}
                                  component={"div"}
                                  onRowsPerPageChange={handleChangedepartamentRowsPerPage}
                                  onPageChange={handleChangedepartamentPage}
                                  count={departamentCount}
                                  rowsPerPage={rowsPerPagedepartament}
                                  page={departamentPage}
                              />
                          </Box>
                      </div>
                  </Grid>
              </TrueffortCardTable>}
            </Grid>
        </Card>
    );
};

export default DepartmentsTab;
