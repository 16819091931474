import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  defaultStrings, download_options, RECAPTCHA_SITE_KEY
} from "../../util/Constants";
import {useFormik} from 'formik';
import ConfirmationTokenFormTRUE from './ConfirmationTokenFormTRUE';
import '../../css/RegisterPage.css';
import '../../css/FormCrud.css';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Context} from "vm";
import UserCompanyPositionTypeService from "../../services/userCompanyPositionType.service";
import CompanyBusinessSubtypeService from "../../services/companyBusinessSubtype.service";
import {
    Avatar, Box, Checkbox, Divider, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment,
    MenuItem, Paper, Stack, Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    TrueffortButton,
    TrueffortSelect,
    TrueffortTextField
} from "../../MUIstyle/MUICustom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


export type FormAccountType = {
    active: boolean | string,
    paid: boolean | string,
    name: string,
    lastname: string,
    email: string,
    password: string,
    confirmPassword: string,
    idInstance: number | string,
    terms: boolean | string,
    policy: boolean | string,
    company: string,
    token: string,
    passed: boolean,
    showConfirmForm: boolean,
    phone: string,
    idCompanyBusinessSubtype: string,
    idUserCompanyPositionType: string
};

const RegisterCardFormTRUE = ({handleSubmit}: { handleSubmit: Function, strings: any }) => {

    const recaptchaRef = React.useRef();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [reCaptchaApproved, setReCaptchaApproved] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false)
    const [userCompanyRoleTypes, setUserCompanyRoleTypes] = React.useState([]);
    const [userCompanyPositionTypes, setUserCompanyPositionTypes] = React.useState([]);
    const [companyBusinessSubtypes, setCompanyBusinessSubtypes] = React.useState([]);
    const userCompanyPositionTypeService = React.useMemo(() => new UserCompanyPositionTypeService(), []);
    const companyBusinessSubtypeService = React.useMemo(() => new CompanyBusinessSubtypeService(), []);

    const {t} = useTranslation();

    React.useEffect(() => {
        fillPositionTypes();
        fillCompanyBusinessSubtypes();
    }, []);

    React.useEffect(() => {
        if (isInvitation()) {
            companyBusinessSubtypeService.getBusinessByCompany(getCompany()).then(({data: response}: any) => {
                console.log("RES", response.data);
                formik.setFieldValue("idCompanyBusinessSubtype", response.data.idCompanyBusinessSubtype);
            });
        }
    }, [companyBusinessSubtypes]);

    const fillPositionTypes = () => {
        userCompanyPositionTypeService.getAll().then(({data: response}: any) => {
            if (Array.isArray(response.data)) {
                setUserCompanyPositionTypes(response.data);
            }
        });
    }

    const fillCompanyBusinessSubtypes = () => {
        companyBusinessSubtypeService.getAll().then(({data: response}: any) => {
            if (Array.isArray(response.data)) {
                setCompanyBusinessSubtypes(response.data);
            }
        });
    }

    const redoCaptcha = (context: Context) => {
        recaptchaRef.current.reset();
        recaptchaRef.current.execute(context);
    }

    const validate = (values: FormAccountType) => {
        let errors: any = {};

        if (!values.name) {
            errors.name = t("Required");
        } else if (values.name.length > 35) {
            errors.name = t("forms.nameLengthValidation");
        }

        if (!values.lastname) {
            errors.lastname = t("Required");
        } else if (values.lastname.length > 40) {
            errors.lastname = t("forms.nameLengthValidation");
        }

        if (!values.company) {
            errors.company = t("Required");
        } else if (values.company.length > 50) {
            errors.company = t("forms.nameLengthValidation");
        }

        if (!values.email) {
            errors.email = t("Required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(values.email)) {
            errors.email = t("forms.invalidEmailValidation");
        }

        if (!values.terms) {
            errors.terms = t("forms.termsValidation");
        }
        if (!values.policy) {
            errors.policy = t("forms.policyValidation");
        }
        if (!values.password) {
            errors.password = t("Required");
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = t("Required");
        } else if (values.password !== values.confirmPassword) {
            errors.password = t("forms.passwordDontMatchValidation");
            errors.confirmPassword = t("forms.passwordDontMatchValidation");
        }
        if (!values.idInstance) {
            errors.idInstance = t("Required");
        }
        if (!values.phone) {
            errors.phone = t("Required");
        } else if (values.phone.length < 10) {
            errors.phone = t("forms.invalidFormNumber");
        }
        if (values.idUserCompanyPositionType == "") {
            errors.idUserCompanyPositionType = t("Required");
        }
        if (values.idCompanyBusinessSubtype == "" && !isInvitation()) {
            errors.idCompanyBusinessSubtype = t("Required");
        }
        // if (Object.keys(errors).length === 0 && values.active) {
        //     return recaptchaRef.current.executeAsync().then((token: any) => {
        //         if (!token && !reCaptchaApproved)
        //             errors.terms = t(DEFAULT_REQUEST_ERROR);
        //         return errors;
        //     });
        // } else {
        return errors;
        // }
    };

    const isInvitation = () => {
        let query = new URLSearchParams(window.location.search);
        let u = query.get('email');
        return u !== null;
    };

    const getEmail = () => {
        let query = new URLSearchParams(window.location.search);
        let u = query.get('email');
        return u;
    }

    const getCompany = () => {
        let query = new URLSearchParams(window.location.search);
        let u = query.get('company');
        return u;
    }

    const formik = useFormik({
        initialValues: {
            idInstance: `${!isInvitation() ? 2 : 3}`,
            active: true,
            showConfirmForm: false,
            confirmPassword: '',
            passed: false,
            name: '',
            email: `${!isInvitation() ? "" : getEmail()}`,
            company: `${!isInvitation() ? "" : getCompany()}`,
            lastname: '',
            password: '',
            token: '',
            terms: false,
            policy: false,
            //reCaptcha: false,
            paid: false,
            phone: "",
            idUserCompanyRoleType: "",
            idUserCompanyPositionType: "",
            idCompanyBusinessSubtype: ""
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            //console.log("submiting")
            handleSubmit(values, setValues).then(() => {
                setSubmitting(false);
                //console.log("finish submiting")
            })
        },
    });


    const history = useHistory();

    return(
        <Paper component="form" onSubmit={formik.handleSubmit} sx={{ width: '805px', padding: '19px' }} >
            <div style={{ height: 0 }} >
                <hr style={{ visibility: "hidden", marginTop: 0, marginBottom: '30px' }} />
                <Divider color='#EFEFEF' sx={{ borderBottomWidth: 2, marginTop: '30px', marginBottom: '28px' }} />
            </div>

            <Box sx={{ height: '60px', display: 'flex', alignItems: "flex-start", justifyContent: 'space-between' }}>
                <Avatar sx={{ bgcolor: '#EFEFEF', width: '60px', height: '60px' }} >
                    <FontAwesomeIcon icon={"users"} size="lg" color='#07074E' />
                </Avatar>
                {/*<Typography color='#6A6A6A' sx={{ fontSize: 14 }} >
                    Texto
                </Typography>*/}
            </Box>

            <Box sx={{ weight: '294px', height:'75px' }} >
                <Typography
                    variant="h4"
                    color='#4A4A4A'
                    marginTop='0.5em'
                    marginBottom='0.25em'
                >
                    {t("register.title")}
                </Typography>

                <Typography color='#6A6A6A' >
                    {t("register.go_login")} <a onClick={() => history.push("login")} href="#">{t("register.login_here")}</a>
                </Typography>
            </Box>

            <Box sx={{ marginTop: '20px', marginBottom: '20px' }} >
                <Grid container columnSpacing={2} >
                    {/* Row 1 */}
                    <Grid item xs={12} md={4} >
                        <TrueffortTextField
                            id="register-name"
                            name="name"
                            size="small"
                            required
                            fullWidth
                            disabled={!formik.values.active || formik.isSubmitting}
                            placeholder={ t(defaultStrings.namePlaceholder) }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors?.name && formik.touched?.name)}
                            helperText={!formik.errors?.name || !formik.touched?.name ? " ": t(formik.errors.name)}
                            requiredlabel
                            labelstring={t(defaultStrings.nameLabel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <TrueffortTextField
                            id="register-lastname"
                            name="lastname"
                            size="small"
                            required
                            fullWidth
                            disabled={!formik.values.active || formik.isSubmitting}
                            placeholder={ t(defaultStrings.lastnamePlaceholder) }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors?.lastname && formik.touched?.lastname)}
                            helperText={!formik.errors?.lastname || !formik.touched?.lastname ? " ": t(formik.errors.lastname)}
                            requiredlabel
                            labelstring={t(defaultStrings.lastname)}
                        />
                    </Grid>

                    {/* Row 2 */}
                    <Grid item xs={12} md={4} >
                        <TrueffortTextField
                            id="register-phone"
                            name="phone"
                            size="small"
                            required
                            inputProps={{ maxLength: 10, style: { background:'#F5F5F5' } }}
                            fullWidth
                            disabled={!formik.values.active || formik.isSubmitting}
                            onBlur={formik.handleBlur}
                            onChange={(e: any) => {
                                if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                                    formik.handleChange(e);
                                }
                            }}
                            value={formik.values.phone}
                            error={Boolean(formik.errors?.phone && formik.touched?.phone)}
                            helperText={!formik.errors?.phone || !formik.touched?.phone ? " ": t(formik.errors.phone)}
                            placeholder="(00)00-00-00-00"
                            requiredlabel
                            labelstring={t("Phone")}
                        />
                    </Grid>
                    {/* Row 2 */}
                    <Grid item xs={12} md={6} >
                        <TrueffortSelect
                            id="register-position"
                            name="idUserCompanyPositionType"
                            size="small"
                            required
                            fullWidth
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={formik.handleChange}
                            value={formik.values.idUserCompanyPositionType}
                            error={Boolean(
                                formik.errors?.idUserCompanyPositionType &&
                                formik.touched?.idUserCompanyPositionType &&
                                !formik.isSubmitting
                            )}
                            requiredlabel
                            labelstring={t("position")}
                        >
                            <MenuItem value="" disabled sx={{ fontFamily: 'Inter, sans-serif' }} >{t("Select")}</MenuItem>
                            {userCompanyPositionTypes.map((o: any) =>
                                <MenuItem
                                    key={o.idUserCompanyPositionType}
                                    sx={{ fontFamily: 'Inter, sans-serif' }}
                                    value={o.idUserCompanyPositionType}
                                >
                                    {o.name}
                                </MenuItem>
                            )}
                        </TrueffortSelect>
                        <FormHelperText>
                            {(formik.errors?.idUserCompanyPositionType && formik.touched?.idUserCompanyPositionType && !formik.isSubmitting) ?
                                t(formik.errors.idUserCompanyPositionType) : " "
                            }
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <TrueffortTextField
                            id="register-company"
                            name="company"
                            size="small"
                            required
                            inputProps={{ maxLength: 50, style: { background:'#F5F5F5' } }}
                            placeholder={t(defaultStrings.companyPlaceholder)}
                            fullWidth
                            disabled={!formik.values.active || formik.isSubmitting || isInvitation()}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={!isInvitation() ? formik.values.company : getCompany()}
                            error={Boolean(formik.errors?.company && formik.touched?.company)}
                            helperText={!formik.errors?.company || !formik.touched?.company ? " ": t(formik.errors.company)}
                            requiredlabel
                            labelstring={t(defaultStrings.company)}
                        />
                    </Grid>

                    {/* Row 3 */}
                    <Grid item xs={12} md={6} >
                        <TrueffortSelect
                            id="register-business-line"
                            name="idCompanyBusinessSubtype"
                            size="small"
                            required
                            fullWidth
                            displayEmpty
                            disabled={isInvitation()}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={formik.handleChange}
                            value={formik.values.idCompanyBusinessSubtype}
                            error={Boolean(
                                formik.errors?.idCompanyBusinessSubtype &&
                                formik.touched?.idCompanyBusinessSubtype &&
                                !formik.isSubmitting
                            )}
                            requiredlabel
                            labelstring={t("company_line_business")}
                        >
                            <MenuItem value="" disabled sx={{ fontFamily: 'Inter, sans-serif' }} >{t("Select")}</MenuItem>
                            {companyBusinessSubtypes.map((o: any) =>
                                <MenuItem
                                    key={o.idCompanyBusinessSubtype}
                                    sx={{ fontFamily: 'Inter, sans-serif' }}
                                    value={o.idCompanyBusinessSubtype}
                                >
                                    {o.name}
                                </MenuItem>
                            )}
                        </TrueffortSelect>
                        <FormHelperText>
                            {(formik.errors?.idCompanyBusinessSubtype && formik.touched?.idCompanyBusinessSubtype && !formik.isSubmitting) ?
                                t(formik.errors.idCompanyBusinessSubtype) : " "
                            }
                        </FormHelperText>

                    </Grid>
                    <Grid item xs={12} md={6} >
                        <TrueffortTextField
                            id="register-email"
                            name="email"
                            size="small"
                            required
                            placeholder={t(defaultStrings.emailPlaceholder)}
                            fullWidth
                            disabled={!formik.values.active || formik.isSubmitting || isInvitation()}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={!isInvitation() ? formik.values.email : getEmail()}
                            error={Boolean(formik.errors?.email && formik.touched?.email)}
                            helperText={!formik.errors?.email || !formik.touched?.email ? " ": t(formik.errors.email)}
                            requiredlabel
                            labelstring={t(defaultStrings.emailLabel)}
                        />
                    </Grid>

                    {/* Row 4 */}
                    <Grid item xs={12} md={6} >
                        <TrueffortTextField
                            id="register-password"
                            name="password"
                            size="small"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            backgroundColor: '#F5F5F5'
                                        }}
                                    >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size='small'
                                        >
                                            {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            fullWidth
                            //label={t("Password")}
                            placeholder={t(defaultStrings.passwordPlaceholder)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            error={ Boolean(formik.errors?.password && formik.touched?.password) }
                            disabled={!formik.values.active || formik.isSubmitting}
                            helperText={!formik.touched?.password || !formik.errors?.password ? " " : t(formik.errors.password)}
                            requiredlabel
                            labelstring={t(defaultStrings.passwordLabel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <TrueffortTextField
                            id="register-comfirm-password"
                            name="confirmPassword"
                            size="small"
                            type={showPasswordConfirm ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                            edge="end"
                                            size='small'
                                        >
                                            {showPasswordConfirm ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            fullWidth
                            placeholder={t(defaultStrings.confirmPasswordPlaceholder)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            error={ Boolean(formik.errors?.confirmPassword && formik.touched?.confirmPassword) }
                            disabled={!formik.values.active || formik.isSubmitting}
                            helperText={!formik.touched?.confirmPassword || !formik.errors?.confirmPassword ? " " : t(formik.errors.confirmPassword)}
                            requiredlabel
                            labelstring={t(defaultStrings.confirmPassword)}
                        />
                    </Grid>

                    {/* Row 5 */}
                    <Grid item xs={12} md={6} >
                        <FormControlLabel
                            sx={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 700,
                                color: '#050536'
                            }}
                            control={
                                <Checkbox
                                    id="register-terms"
                                    name="terms"
                                    color="primary"
                                    size="small"
                                    sx={{ '&.Mui-checked': { color: "#EF3E2D" } }}
                                    //checked={(!formik.values.active || formik.isSubmitting) ? "checked" : formik.values.terms}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                            }
                            label={
                                <div>
                                    {t("forms.i_agree")}
                                    <a href="https://trueffort.com/terminos-y-condiciones" >
                                        {t("forms.terms_cond")}
                                    </a>
                                </div>
                            }
                        />
                        {formik.errors?.terms && formik.touched?.terms && !formik.isSubmitting &&
                            <FormHelperText error>{t(formik.errors.terms)} </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <FormControlLabel
                            sx={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 700,
                                color: '#050536'
                            }}
                            control={
                                <Checkbox
                                    id="register-policy"
                                    name="policy"
                                    color="primary"
                                    size="small"
                                    sx={{ '&.Mui-checked': { color: "#EF3E2D" } }}
                                    //checked={(!formik.values.active || formik.isSubmitting) ? "checked" : formik.values.terms}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                            }
                            label={
                                <div>
                                    {t("forms.i_agree_with")}
                                    <a href="https://trueffort.com/politicas-de-privacidad" >
                                        {t("forms.privacy_policy")}
                                    </a>
                                </div>
                            }
                        />
                        {formik.errors?.policy && formik.touched?.policy && !formik.isSubmitting &&
                            <FormHelperText error >{t(formik.errors.policy)} </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </Box>

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                size="invisible"
                onErrored={() => setReCaptchaApproved(false)}
                onExpired={redoCaptcha}
                onChange={() => setReCaptchaApproved(true)}
            />

            {formik.values.showConfirmForm &&
                <ConfirmationTokenFormTRUE
                    setValues={formik.setValues}
                    values={formik.values}
                    token={formik.values.token}
                    email={formik.values.email}
                    error={formik.errors && formik.errors.token && formik.touched.token ? t(formik.errors.token) : null}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    touched={formik.touched.token}
                />
            }

            {formik.values.passed &&
                <Stack direction="row" spacing={1}>
                    {download_options(t, "success").map((action_) =>
                        <TrueffortButton
                            id={"register-" + action_.value}
                            onClick={action_.onClick}
                            variant="contained"
                            size="medium"
                            color="primary"
                        >
                            {action_.value}
                        </TrueffortButton>
                    )}
                </Stack>
            }


            {formik.values.active &&
                <Box >
                    <Grid container justifyContent='flex-end'>
                        <TrueffortButton
                            id="token-confirm"
                            color="primary"
                            size="medium"
                            type="submit"
                            variant="contained"
                            disabled={formik.isSubmitting}
                            fullWidth={matches}
                        >
                            {formik.isSubmitting ? t(defaultStrings.buttonTextDuring) : t(defaultStrings.createAccountButtonTextAfter)}
                        </TrueffortButton>
                    </Grid>
                </Box>
            }
        </Paper>
    );
}

export default RegisterCardFormTRUE;