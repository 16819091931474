import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {
    TextField,
    MenuItem,
    Button,
    Stack,
    Collapse,
    Typography,
    Menu,
    Select,
    Paper,
    ListItemIcon,
    ListItemText, SvgIcon, Chip, FormControlLabel, Switch
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { toast } from 'react-toastify';
import EstimationService from "../../services/estimations.service";
import ProjectTaskService from "../../services/projectTask.service";
import ProjectDetailUserCard from "./ProjectDetailUserCard";
import {TrueffortButton, TrueffortChip, TrueffortConfirmAlert, TrueffortTextField} from "../../MUIstyle/MUICustom";
import {PendingRounded as PendingRoundedIcon, Delete as DeleteIcon, Edit as EditIcon, Close as CloseIcon, Save as SaveIcon} from '@mui/icons-material';
import AssignUsersDrawer from "../AssignUsersDrawer";
import {confirmAlert} from "react-confirm-alert";
import {useFormik} from "formik";
import { dateToDateLocale, formatSeconds, statusAllowedSinceStatus } from "../../util/GlobalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faL, faUsers} from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import { ID_STATUS_CANCELED, ID_STATUS_COMPLETED, MIN_LEVEL_LEADER } from "../../util/Constants";
import { User } from "../../models/user";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import TrueffortChipStatus from "./TrueffortChipStatus";
import DatePicker from 'react-datepicker';
import { useState } from "react";

/*export type TaskType = {
  idProjectTask:number,
  name: string,
  duration: number,
  effort: number,
  registredDate: any
};*/

export enum UserStatus {
  Unassigned = 0,
  Assigned = 1,
  Saved = 3
}

// @ts-ignore
function ProjectDetailTaskRow({element, phase, task, taskType, users, states, _fillProjectInfo, hidePhaseAndTask, project}) {
  //console.log(project);
  //console.log(task);
  //console.log(states);
  //console.log(taskType);
    //console.log(task);
    //console.log(taskType);
    //console.log(users);
    const {t} = useTranslation();
    const {user} = useAuthContext() as { user: User };
    //console.log(taskType?.users);
    const [open, setOpen] = React.useState(hidePhaseAndTask);
    const [showModal, setShowModal] = React.useState(false);
    const [changeValuesOnAssignedUsers, setChangeValuesOnAssignedUsers] = React.useState(false);
    const [rankedUsers, setRankedUsers] = React.useState(() => {
      let ids = users.map((us:any) => us.idUser);
      if (taskType && Array.isArray(taskType?.users)) {
        return taskType?.users.filter((us:any) => ids.includes(us.id)).map((user: any) => (
        {
            ...user,
            status: task.users.findIndex((usr: any) => usr.id === user.id) !== -1 ?
                UserStatus.Saved :
                UserStatus.Unassigned
        }));
      } else{
          return  [];
        }
    });
    const [notRankedUsers, setNotRankedUsers] = React.useState(() => {
      const ttUsers = taskType && Array.isArray(taskType?.users) ? taskType?.users.map((user: any) => user.id) : [];
      return users.filter((user: any) => !ttUsers.includes(user.idUser)).map(
        (user: any) => ({
          id: user.idUser,
          name: user.name,
          lastname: user.lastName,
          email: user.email,
          effort_interp_avg: taskType ? taskType.effort_avg : 0,
          image_id: user.imageDto.idImage,
          image_url: user.imageDto.url,
          status: task.users.findIndex((usr: any) => usr.id === user.idUser) !== -1 ?
            UserStatus.Saved :
            UserStatus.Unassigned
        })
      );
    });
    const projectTaskService = React.useMemo(() => new ProjectTaskService(), []);
    const estimationService = React.useMemo(() => new EstimationService(), []);
    const [idSelectedStatus, setIdSelectedStatus] = React.useState(task.status_id);
    const [isEditing, setIsEditing] = React.useState(false);
    const [taskStatesAllowed, setTaskStatesAllowed] = React.useState([]);
    const [valuesUsersSelect, setValuesUsersSelect] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);

    const [usersTask, setUsersTask] = React.useState(task.users.map((user: any) => ({
        ...user,
        effort_interp_avg: !user.effort_interp_avg ? (taskType ? taskType.effort_avg : 0) : user.effort_interp_avg
    })));
    /*if (task.users.length !== usersTask.length) {
        //console.log("------- UsersTask Update ------");
        setUsersTask(task.users.map((user: any) => ({
            ...user,
            effort_interp_avg: !user.effort_interp_avg ? (taskType ? taskType.effort_avg : 0) : user.effort_interp_avg
        })));
        updateUsers();
    }
    if (taskType && Array.isArray(taskType.users) && taskType.users.length !== rankedUsers.length) {
        //console.log("------- Users Update ------");
        updateUsers();
    }*/

    /*React.useEffect(() => {
        fillTaskUsers();
    }, []);*/

    /*function updateUsers(): void {
        const ttUsers = Array.isArray(taskType?.users) ? taskType.users.map((user: any) => user.id) : [];
        const updatedNotRankedUsers = users
            .filter((user: any) => !ttUsers.includes(user.idUser))
            .map(
                (user: any) => ({
                    id: user.idUser,
                    name: user.name,
                    lastname: user.lastName,
                    email: user.email,
                    effort_interp_avg: taskType.effort_avg,
                    image_id: user.imageDto.idImage,
                    image_url: user.imageDto.url,
                    status: task.users.findIndex((usr: any) => usr.id === user.idUser) !== -1 ?
                        UserStatus.Saved :
                        UserStatus.Unassigned
                })
            );
        //let ids = users.map((us:any) => us.idUser);
        let ids:any = [];
        let items:any = [];
        
        //.filter((us:any) => ids.includes(us.id))
        if (Array.isArray(taskType?.users)) {
          let is:any = taskType.users.filter((us:any) => ids.includes(us.id));
          items = is.map((user: any) => ({
              ...user,
              status: task.users.findIndex((usr: any) => usr.id === user.id) !== -1 ?
                  UserStatus.Saved :
                  UserStatus.Unassigned
          }));
        }
        setRankedUsers(items);

        setNotRankedUsers(updatedNotRankedUsers);
    }*/

    const handleClickOptions = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

    const saveAssignedUsers = (assignedUsers: any) => {
        const usersProjectTask = {
            idProjectTask: task.id,
            updatedTaskEffort: 0,
            updatedTaskDuration: 0,
            users: assignedUsers.map((usr: any) => usr.id)
        };

        if (changeValuesOnAssignedUsers) {
            let usersWithData: number = (usersTask.length + assignedUsers.length) -
                usersTask.reduce(
                    (acc: number, usr: any) => acc + (!usr.effort_interp_avg ? 1 : 0),
                    assignedUsers.reduce(
                        (acc2: number, assignedUsr: any) => acc2 + (!assignedUsr.effort_interp_avg ? 1 : 0),
                        0
                    )
                );

            usersProjectTask.updatedTaskEffort = !usersWithData ? task.effort : // might delete
                Math.ceil((usersTask.reduce((acc: number, usr: any) => acc + usr.effort_interp_avg, 0) +
                    assignedUsers.reduce((acc: number, usr: any) => acc + usr.effort_interp_avg, 0)) / usersWithData);
            usersProjectTask.updatedTaskDuration = !usersWithData ? task.duration : // might delete
                Math.ceil((taskType.duration_avg * taskType.team_sz_avg) / (usersTask.length + assignedUsers.length));
        } else {
            usersProjectTask.updatedTaskEffort = task.effort;
            usersProjectTask.updatedTaskDuration = task.duration;
        }

        // @ts-ignore
        projectTaskService.assignUsers(task.id, usersProjectTask).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                toast.success(t("project_tasks_page.user_assigned"));
                _fillProjectInfo();
            }
        });
    }

    const deleteUser = (usr: any) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='react-confirm-alert-body custom-ui'>
                        <h3 style={{marginBottom: "1em"}}>
                            {t("user_page.msg_delete_confirm2")} <span
                            className="dark">{usr.name + " " + usr.lastname + "?"}</span>
                        </h3>
                        <div className="react-confirm-alert-button-group">
                            <Button
                                onClick={() => {
                                    const userProjectTask = {
                                        idUser: usr.id,
                                        idProjectTask: task.id,
                                        updatedTaskEffort: 0,
                                        updatedTaskDuration: 0,
                                    };

                                    if (changeValuesOnAssignedUsers) {
                                        const taskUsrs = usersTask.filter((u: any) => u.id !== usr.id);
                                        let divisor: number = taskUsrs.length - /* might delete */
                                            taskUsrs.reduce((acc: number, usr: any) => acc + (!usr.effort_interp_avg ? 1 : 0), 0);

                                        userProjectTask.updatedTaskEffort = !divisor ? taskType.effort_avg :
                                            Math.ceil(taskUsrs.reduce((acc: number, u: any) => acc + u.effort_interp_avg, 0) / divisor);
                                        userProjectTask.updatedTaskDuration = !divisor ? taskType.duration_avg :
                                            Math.ceil((taskType.duration_avg * taskType.team_sz_avg) / taskUsrs.length);
                                    } else {
                                        userProjectTask.updatedTaskEffort = task.effort;
                                        userProjectTask.updatedTaskDuration = task.duration;
                                    }

                                    try {
                                        // @ts-ignore
                                        projectTaskService.removeUserFromTask(task.id, userProjectTask).then(({data: response}) => {
                                            toast.success(t("project_tasks_page.msg_success_delete_user"));
                                            _fillProjectInfo();
                                        }).catch(function (error: any) {
                                            toast.error("Ocurrió un error al eliminar al usuario.");
                                        });
                                    } catch (error) {
                                        console.log("Error #Users handleDelete" + JSON.stringify(error));
                                        toast.error("Ocurrió un error al eliminar al usuario.");
                                    }
                                    onClose();
                                }}>
                                {t("project_page.msg_delete_task_confirm_yes")}
                            </Button>
                            <Button onClick={onClose}>{t("project_page.msg_delete_task_confirm_no")}</Button>
                        </div>
                    </div>
                );
            }
        });
    }

     const validate = (values: any) => {
        let errors: any = {};
        // No aplica para tareas terminadas
        if (task.status_id != 4) {
            if (values.duration < 0) {
                errors.duration = t("project_tasks_page.msg.errors.duration_negative");
            }
            if (values.effort < 0) {
                errors.effort = t("project_tasks_page.msg.errors.effort_negative");
            }
        }

        if (new Date(values.registredDate) < new Date(project.registred_date)) {
          errors.registredDate = t("project_tasks_page.msg.errors.registred_date");
        }
        
        return errors;
    }

    const handleSubmit = (values: any) => {
      let errors = validate(values);
      if (JSON.stringify(errors) != '{}') {
        setFormTask({values:formTask.values, errors: errors});
        return;
      }
        setAnchorEl(null);
        let projectTaskAux = {
            name: '',
            status: {
              idStatus: idSelectedStatus
            },
            users: getArrUsersFromValues(),
            estimatedDays: values.duration,
            estimatedTime: values.effort,
            registredDate: values.registredDate,
            finishDate: [ID_STATUS_COMPLETED, ID_STATUS_CANCELED].includes(idSelectedStatus) ? dateToDateLocale(new Date()).toISOString() : null
        }

        // @ts-ignore
        projectTaskService.update(task.id, projectTaskAux).then(({data : response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);

                setIsEditing(false);
                _fillProjectInfo();
            } else {
                toast.success(t("project_page.msg_success_task_update"));
                setIsEditing(false);
                // SE LLAMA AL SERVICIO PARA LLENAR LAS ESTIMACIONES CADA QUE SE CAMBIA EL STATUS A UNA TAREA
                // @ts-ignore
                estimationService.create().then(() => {
                    _fillProjectInfo();
                });
            }
            //fillProjectTasks();
        });
    }

    const prepareEdit = (t: any) => {
      setAnchorEl(null);
      setIsEditing(true);
      // Determinar los estados permitidos a partir del estado actual
      setTaskStatesAllowed(statusAllowedSinceStatus({status_id:task.status_id, status_name:task.status_name}));
    }

    function getArrUsersFromValues() {
        let arr: { idProject: number; idUser: number; }[] = [];
        valuesUsersSelect.map((usr: any, k: number) => {
            arr.push({idProject: 0, idUser: usr.value});
        });
        return arr;
    }

    const [formTask, setFormTask] = useState({values:{idProjectTask:0, name:'', duration: task.duration, 
      effort: task.effort, registredDate: dateToDateLocale(new Date())}, errors: { duration:null, effort:null }
    });

    const deleteProjectTask = (task: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body custom-ui'>
                        <h3 style={{marginBottom: "1em"}}>{t("project_page.msg_delete_task_confirm2")} <span className="dark">{task.type_name + "?"}</span></h3>
                        <p className="red"><strong>{t("project_page.msg_delete_task_confirm3")}</strong></p>
                        <div className="react-confirm-alert-button-group">
                            <Button
                                onClick={() => {
                                    setShowLoading(true);
                                    try {
                                        // @ts-ignore
                                        projectTaskService.delete(task.id).then(({data : response}) => {
                                            toast.success(t("project_page.msg_success_task_delete"));
                                            _fillProjectInfo();
                                            setShowLoading(false);
                                        }).catch(function (error: any) {
                                            toast.error("Ocurrió un error al eliminar la tarea.");
                                            setShowLoading(false);
                                        });
                                    } catch (error) {
                                        console.log("Error #Users handleDelete" + JSON.stringify(error));
                                        toast.error("Ocurrió un error al eliminar la tarea.");
                                        setShowLoading(false);
                                    }
                                    onClose();
                                }}>
                                {t("project_page.msg_delete_task_confirm_yes")}
                            </Button>
                            <Button onClick={onClose}>{t("project_page.msg_delete_task_confirm_no")}</Button>
                        </div>
                    </div>
                );
            }
        });
    }

    const handleEstimatesChangeOnAssignedUsers = () => {
        if (!changeValuesOnAssignedUsers) { // Si se activa el recalculo de estimaciones
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <TrueffortConfirmAlert
                            title={
                                <Typography color='error' variant='h6' fontWeight={700} marginBottom='20px' >
                                    <ReportProblemIcon color='error' />&nbsp;&nbsp;{t("Warning")}
                                </Typography>
                            }
                            alertColor='error'
                            messages={[
                                t("project_page.msg_recalculate_estimates_1"),
                                t("estimation_page.msg_recalculate_estimates_2")
                            ]}
                            buttons={[
                                {
                                    label: t("project_page.msg_delete_task_confirm_no"),
                                    onClick: onClose
                                },
                                {
                                    label: t("export_excel.yes"),
                                    onClick: () => {
                                        setChangeValuesOnAssignedUsers(!changeValuesOnAssignedUsers);
                                        onClose();
                                    }
                                }
                            ]}
                        />
                    );
                }
            });
        } else { // Si se desactiva el recalculo de estimaciones
            setChangeValuesOnAssignedUsers(!changeValuesOnAssignedUsers);
        }
    }

    return (
        <>
          {!hidePhaseAndTask ? !isEditing ?
                <Paper
                    variant="outlined"
                    square
                    sx={{ padding: '10px', backgroundColor: 'rgba(242, 233, 255, 0.30)' }}
                >
                    <Grid container spacing={1} alignItems="center" sx={{ padding: 0 }} >
                        <Grid item xs={0.3} />

                        <Grid item xs={11.7} >
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: '700' }} onClick={() => setOpen(!open)} className='project-detail-task-row' component='span' >
                                {task.type_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={0.5} />
                        <Grid item xs={1.5} sx={{ display: "flex", justifyContent: 'center' }} >
                          <TrueffortChipStatus 
                            status_id={task.status_id} 
                            status_name={task.status_name} 
                          />
                        </Grid>
                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task?.registred_date === 0 ? '----------' : task?.registred_date}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.start_date === 0 ? '----------' : task.start_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.end_date === 0 ? '----------' : task.end_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.estimated_finish_date === 0 ? '----------' : task.estimated_finish_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" color={task.days_deviation < 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                                {Math.abs(task.days_deviation) /*+ " " + t("project_tasks_page.days")*/}
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={0.9} >
                          <Typography align="center" color={(task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) == 0 ? '' : (task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) - (task.effort * 3600) > 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                            {(task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) == 0 ? 0 : formatSeconds(Math.abs((task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) - (task.effort * 3600)), false, false, false)}
                          </Typography>
                        </Grid>

                        <Grid item xs={0.9} /*sx={{ backgroundColor: '#DCDCDC', boxShadow: '0 0 0 0.5rem #DCDCDC' }}*/ >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.duration.toLocaleString() /*+ " " + t("project_tasks_page.days")*/}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.9} /*sx={{ backgroundColor: '#DCDCDC' }}*/ >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(task.effort * 3600, false, false, false)}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.9} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.registered_duration.toLocaleString()}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.9} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60, false, false, false)}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.5} sx={{ display:'flex', justifyContent: 'center' }} >
                            {user.role.accessLevel >= MIN_LEVEL_LEADER && 
                            <TrueffortButton style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                             size="small"
                                             color="secondary"
                                             id="btnOptionsElement"
                                             aria-controls={openOptions ? 'basic-menu' : undefined}
                                             aria-haspopup="true"
                                             aria-expanded={openOptions ? 'true' : undefined}
                                             onClick={handleClickOptions}
                            >
                                <PendingRoundedIcon />
                            </TrueffortButton>}

                            <Menu
                                id="basic-menu"
                                sx={{
                                    '& .MuiPaper-root': {
                                        backgroundColor: '#050536'
                                    }
                                }}
                                anchorEl={anchorEl}
                                open={openOptions}
                                onClose={handleCloseOptions}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => prepareEdit(task)}>
                                    <ListItemIcon>
                                        <EditIcon size="small" sx={{color: '#F5F5F5'}} />
                                    </ListItemIcon>
                                    <ListItemText sx={{ color: '#F5F5F5'}}>
                                        {t("elements.buttons.edit")}
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleCloseOptions();
                                        deleteProjectTask(task);
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteIcon size="small" sx={{ color: '#989898' }} />
                                    </ListItemIcon>
                                    <ListItemText sx={{ color: '#989898' }} >
                                        {t("delete")}
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Paper> :
                <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#DCDCDC' }} >
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={1.5} >
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: '700' }} onClick={() => setOpen(!open)} component='span' >
                                {task.type_name}
                            </Typography>
                        </Grid>

                        <Grid item xs={1.5} >
                            <Select
                                fullWidth
                                id="idSlTaskStatus"
                                size="small"
                                name="idStatus"
                                onChange={(e: any) => {
                                  setIdSelectedStatus(e.target.value);
                                  setFormTask({values: {...formTask.values, idSelectedStatus: e.target.value}, 
                                  errors: formTask.errors});
                                }}
                                value={idSelectedStatus}
                            >
                                {states?.map((ts: any, k: number) =>
                                    <MenuItem
                                        key={ts.idStatus}
                                        disabled={typeof taskStatesAllowed.find((s: any) => s.idStatus == ts.idStatus) == "undefined"}
                                        value={ts.idStatus}>
                                        {ts.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={1.5}>
                          <DatePicker
                            style={{width:'100%'}}
                            dateFormat="yyyy/MM/dd"
                            selected={formTask?.values?.registredDate}
                            onChange={(date:any) => setFormTask({values: {...formTask.values, registredDate: dateToDateLocale(date)}, errors: formTask.errors})}
                            customInput={<TrueffortTextField 
                              size="small" 
                              name="registredDate"
                              inputProps={{background:'red'}}
                              error={formTask.errors.registredDate || ''}
                            />}
                          />
                        </Grid>
                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.start_date === 0 ? '----------' : task.start_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.end_date === 0 ? '----------' : task.end_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.estimated_finish_date === 0 ? '----------' : task.estimated_finish_date}
                            </Typography>
                        </Grid>
                        <Grid item xs={0.5} >
                            <Typography align="center" color={task.days_deviation < 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                                {Math.abs(task.days_deviation) /*+ " " + t("project_tasks_page.days")*/}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <TrueffortTextField
                                name="duration"
                                type="number"
                                min="0"
                                size="small"
                                variant="filled"
                                label={t("estimation_page.days_duration")}
                                maxLength={6} disabled={task.status_id == 4}
                                onChange={(ev:any) => setFormTask({values: {...formTask.values, duration: ev.target.value}, errors: formTask.errors})}
                                value={formTask.values.duration}
                                error={formTask.errors?.duration || ''}
                            />
                        </Grid>

                        <Grid item xs={1} >
                            <TrueffortTextField
                                name="effort"
                                type="number"
                                min="0"
                                size="small"
                                variant="filled"
                                label={t("estimation_page.effort_man_hours")}
                                maxLength={6} disabled={task.status_id == 4}
                                onChange={(ev:any) => setFormTask({values: {...formTask.values, effort: ev.target.value}, errors: formTask.errors})}
                                value={formTask.values.effort}
                                error={formTask.errors?.effort || ''}
                            />
                        </Grid>

                        <Grid item xs={0.5} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {task.registered_duration.toLocaleString()}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60, false, false, false)}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.5} >
                            <Stack direction='column' alignItems='center' >
                                <TrueffortButton
                                    style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                    size="small"
                                    color="secondary"
                                    id="btnOptionsElement"
                                    aria-controls={openOptions ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openOptions ? 'true' : undefined}
                                    onClick={() => {
                                      setFormTask({values:{idProjectTask:0, duration:task.duration, effort: task.effort, registredDate: dateToDateLocale(new Date())},
                                        errors:{duration:null, effort:null}});
                                      setIsEditing(false);}}
                                >
                                    <CloseIcon />
                                </TrueffortButton>

                                <TrueffortButton
                                    style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                    size="small"
                                    color="primary"
                                    id="btnOptionsElement"
                                    aria-controls={openOptions ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openOptions ? 'true' : undefined}
                                    onClick={() => handleSubmit(formTask.values)}
                                >
                                    <SaveIcon />
                                </TrueffortButton>
                            </Stack>
                        </Grid>
                        {formTask.errors.duration && <Grid item xs={12} style={{color:'red', fontSize:'0.75rem'}}>{formTask.errors.duration}</Grid>}
                        {formTask.errors.effort && <Grid item xs={12} style={{color:'red', fontSize:'0.75rem'}}>{formTask.errors.effort}</Grid>}
                        {formTask.errors.registredDate && <Grid item xs={12} style={{color:'red', fontSize:'0.75rem'}}>{formTask.errors.registredDate + ' ' + project.registred_date}</Grid>}
                    </Grid>
                </Paper> : null}
            
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Paper
                    variant="outlined"
                    square
                    sx={{ padding: '10px', backgroundColor: 'rgba(242, 233, 255, 0.30)' }}
                >
                    <Grid container spacing={1} >
                        <Grid item xs={0.5} />

                        <Grid item xs={2} sx={{paddingLeft: "1rem"}}>
                            <Stack spacing={1} direction="column">
                                <Typography variant="subtitle2" >
                                    <SvgIcon fontSize="small" >
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.75 2.75V5.5C2.75 5.86467 2.89487 6.21441 3.15273 6.47227C3.41059 6.73013 3.76033 6.875 4.125 6.875H8.70833M8.70833 6.875L6.875 5.04167M8.70833 6.875L6.875 8.70833" stroke="#553D74" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </SvgIcon>

                                    {t("estimation_page.participants")}
                                </Typography>
                                <Chip
                                    sx={{ borderRadius: '3px' }}
                                    icon={<FontAwesomeIcon icon={faUsers} color={'#9A64E2'} />}
                                    label={"Total: " + usersTask.length}
                                    variant='outlined'
                                />
                                {user.role.accessLevel >= MIN_LEVEL_LEADER &&
                                    <TrueffortButton
                                        variant="outlined"
                                        size="small"
                                        color='secondary'
                                        //disabled={!notRankedUsers.length && !rankedUsers.length}
                                        startIcon={<AddIcon />}
                                        onClick={() => setShowModal(true)}
                                    >
                                        {t("project_tasks_page.add_user")}
                                    </TrueffortButton>
                                }
                                {/*<Box sx={{borderRadius: "0.1875rem", border: "solid 1px #DCDCDC", width: "fit-content", padding: "0.1rem"}} >
                                    <GroupsIcon fontSize="small" sx={{color: "#9A64E2", marginBottom: "-6px"}} /> {t("total")}: {usersTask.length}
                                </Box>*/}
                            </Stack>
                        </Grid>
                        <Grid xs={7}>
                            <Grid container spacing={1} padding={1}>
                                {rankedUsers
                                    .filter((usr: any) => usr.status === UserStatus.Saved)
                                    .map((usr: any, i: number) =>
                                        <Grid item >
                                            <ProjectDetailUserCard
                                                key={usr.name + "-" + usr.lastname}
                                                userInfo={usr}
                                                _deleteUser={deleteUser}
                                                index={i}
                                                detail={true}
                                                pendingSave={false}
                                            />
                                        </Grid>
                                    )
                                }

                                {notRankedUsers
                                    .filter((usr: any) => usr.status === UserStatus.Saved)
                                    .map((usr: any, i: number) =>
                                        <Grid item >
                                            <ProjectDetailUserCard
                                                key={usr.name + "-" + usr.lastname}
                                                userInfo={usr}
                                                _deleteUser={deleteUser}
                                                index={i}
                                                detail={true}
                                                pendingSave={false}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={changeValuesOnAssignedUsers}
                                        onChange={handleEstimatesChangeOnAssignedUsers}
                                    />
                                }
                                label={t("users_modal.recalculate_estimates")}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Collapse>

            <AssignUsersDrawer
                open={showModal}
                anchor='right'
                onClose={() => setShowModal(false)}
                users={{
                    notRankedUsers: notRankedUsers,
                    rankedUsers: rankedUsers.sort((usr1: any, usr2: any) => usr2.ranking - usr1.ranking)
                }}
                setUsers={{
                    setNotRankedUsers: setNotRankedUsers,
                    setRankedUsers: setRankedUsers
                }}
                taskName={taskType ? taskType.name : task.type_name}
                assignUsers={saveAssignedUsers}
                /*automaticEstimatesState={{
                    changeValuesOnAssignedUsers: changeValuesOnAssignedUsers,
                    handleEstimatesChangeOnAssignedUser: handleEstimatesChangeOnAssignedUser
                }}*/
                page='detail'
                //handleCheckboxChange={selectUser}
            />
        </>
    );
}

export default ProjectDetailTaskRow;