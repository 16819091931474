import SiteWrapper from "../SiteWrapper.react";
import React from "react";
import { useTranslation } from "react-i18next";
import '../css/ProjectDetail.css';
import EstimationService from "../services/estimations.service";
import TaskStateService from "../services/taskState.service";
import UserService from "../services/user.service";
import TaskTypeService from "../services/taskType.service";
import { toast } from "react-toastify";
import SelectMulti from 'react-select';
import ProjectTaskService from "../services/projectTask.service";
import { useAuthContext } from "../contexts/AuthenticationContext";
import { User } from "../util/Interfaces";
import ProjectPhaseService from "../services/projectPhase.service";
import { confirmAlert } from "react-confirm-alert";
import ProjectSerivice from "../services/project.service";
import ElementTypeService from "../services/elementType.service";
import {
  Container, Card, Box, TextField, MenuItem, Button, Stack, CircularProgress, Paper, Typography, IconButton
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {
  TrueffortSearchInput, TrueffortButton, TrueffortSelect, HelperDialog, TrueffortTextField
} from "../MUIstyle/MUICustom";
import ProjectDetailElementRow from "../components/projectDetail/ProjectDetailElementRow";
import SummaryCard from "../components/projectDetail/SummaryCard";
import AddElementForm from "../components/projectDetail/AddElementForm";
import { 
  Delete as DeleteIcon, Save as SaveIcon, Close as CloseIcon
} from '@mui/icons-material';
import { MIN_LEVEL_ADMIN, MIN_LEVEL_LEADER } from "../util/Constants";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { dateToDateLocale, formatSeconds } from "../util/GlobalFunctions";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AddIcon from "@mui/icons-material/Add";
import { TaskTypesContext } from "../components/projectDetail/ProjectDetailContext";
import HelpIcon from "@mui/icons-material/Help";
import { helperDialogs } from "../util/HelperDialogs";
import { ExcelFile, ExcelColumn, ExcelSheet } from '../util/ExportExcel';
import DatePicker from "react-datepicker";
import { useState } from "react";

const dataSummaryEmpty = {
  name: '',
  finished: 0,
  process: 0,
  created: 0,
  total: 0,
  datasets: {
    datasets: [{
      data: [1, 1],
      backgroundColor: ["#78B737", "#F2F2F2"],
      display: true,
      borderWidth: 0
    }]
  },
  percent: 0
}

function ProjectDetailPage() {
  const { t } = useTranslation();
  const { user } = useAuthContext() as { user: User };
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const theme = useTheme();
  const belowSmall = useMediaQuery(theme.breakpoints.down('sm'));
  //const matches = useMediaQuery(theme.breakpoints.down('md'));

  const query = new URLSearchParams(window.location.search);
  const idProject = query.get('idProject');
  const [loading, setLoading] = React.useState(true);
  const [formState, setFormState] = React.useState(
    { isCreating: false, isEditing: false, showFormAddTask: false, showFormAddElement: false }
  );
  const [valuesUsersSelect, setValuesUsersSelect] = React.useState([]);
  const [valuesUsersSelectFilter, setValuesUsersSelectFilter] = React.useState([]);
  const [valuesStatusSelectFilter, setValuesStatusSelectFilter] = React.useState([]);
  const [searchFilterValue, setSearchFilterValue] = React.useState("");
  const [notSavedTasks, setNotSavedTasks] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [usersAll, setUsersAll] = React.useState([]);
  const [idSelectedProjectElement, setIdSelectedProjectElement] = React.useState("");
  const [selectedNewTask, setSelectedNewTask] = React.useState({ id: 0, users: [] });
  const [projectInfo, setProjectInfo] = React.useState({ elements: [] });
  const [taskTypes, setTaskTypes] = React.useState([]);
  const [taskStates, setTaskStates] = React.useState([]);
  const [elementTypes, setElementTypes] = React.useState([]);
  const [datasetElements, setDatasetElements] = React.useState({});
  const elementTypeService = React.useMemo(() => new ElementTypeService(), []);
  const projectPhaseService = React.useMemo(() => new ProjectPhaseService(), []);
  const taskTypeService = React.useMemo(() => new TaskTypeService(), []);
  const projectTaskService = React.useMemo(() => new ProjectTaskService(), []);
  const taskStateService = React.useMemo(() => new TaskStateService(), []);
  const estimationService = React.useMemo(() => new EstimationService(), []);
  const userService = React.useMemo(() => new UserService(), []);
  const projectService = React.useMemo(() => new ProjectSerivice(), []);
  const [elementSummaryData, setElementSummaryData] = React.useState(dataSummaryEmpty);
  const [phasesSummaryData, setPhasesSummaryData] = React.useState(dataSummaryEmpty);
  const [tasksSummaryData, setTasksSummaryData] = React.useState(dataSummaryEmpty);
  const [elementsFiltered, setElementsFiltered] = React.useState([]);
  const [canEdit, setCanEdit] = React.useState(true);
  const [idsLeader, setIdsLeader] = React.useState([]);
  const [idsLeaderAsigned, setIdsLeaderAsigned] = React.useState([]);
  const [leaders, setLeaders] = React.useState([]);
  const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);

  React.useEffect(() => {
    fillProjectInfo();
    fillUsersAll();
    fillTaskStates();
    if (user.role.accessLevel >= MIN_LEVEL_LEADER) {
      fillLeader();
    }

    const id = setInterval(() => {
      fillProjectInfo();
    }, 60000);
    return () => clearInterval(id);
  }, []);

  React.useEffect(() => {
    if (valuesUsersSelectFilter.length > 0 || valuesStatusSelectFilter.length) {
      handleFilterElements();
    }
  }, [projectInfo]);

  React.useEffect(() => {
    handleFilterElements();
  }, [valuesStatusSelectFilter, valuesUsersSelectFilter]);

  const fillProjectInfo = () => {
    let req = {
      projects: [Number(idProject)]
    }
    estimationService.projectStructure(req).then(({ data: response }: any) => {
      //console.log("PROJECT ESTIMATE", response);
      if (response.status == "ok") {
        let pro = response.projects[0];
        //console.log(pro);

        // SETEAR EL FILTRO DE USUARIOS CON SÓLO USUARIOS QUE TENGAN ACTIVIDAD
        let arrUsersTmp: any[] = [];
        let arrUsersTmpIds: number[] = [];
        for (let m = 0; m < pro.elements.length; m++) {
          let el = pro.elements[m];
          for (let i = 0; i < el.phases.length; i++) {
            let ph = el.phases[i];
            for (let k = 0; k < ph.tasks.length; k++) {
              let tk = ph.tasks[k];
              for (let j = 0; j < tk.users.length; j++) {
                let usr = tk.users[j];
                //console.log("usr", usr);
                let usrObj = { idUser: usr.id, name: usr.name, lastName: usr.lastname, fullname: usr.name + ' ' + usr.lastname, imageDto: { idImage: usr.image_id, url: usr.image_url } };
                if (arrUsersTmpIds.indexOf(usrObj.idUser) == -1) {
                  arrUsersTmpIds.push(usrObj.idUser);
                  // Buscar id del user dentro de los users del servicio
                  arrUsersTmp.push(usrObj);
                }
              }
            }
          }
        }
        //console.log("arrUsersTmp", arrUsersTmp);
        setUsers(arrUsersTmp.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

        setProjectInfo(pro);
        if (valuesStatusSelectFilter.length == 0 && valuesUsersSelectFilter.length == 0) {
          setElementsFiltered(pro.elements);
        }

        // Crear los datasets para las gráficas de elementos, fases y tareas terminadas
        let elementsFinished = response.projects[0].elements.filter((el: any) => el.status_id == 4).length;
        setDatasetElements({
          datasets: [{
            data: [elementsFinished, response.projects[0].elements.length - elementsFinished],
            backgroundColor: ["#78B737", "#F2F2F2"],
            display: true,
            borderWidth: 0
          }]
        });

        let elProcess = response.projects[0].elements.filter((el: any) => el.status_id == 2).length;
        let elCreated = response.projects[0].elements.filter((el: any) => el.status_id == 1).length;
        setElementSummaryData({
          name: t("home.elements"),
          finished: elementsFinished,
          process: elProcess,
          created: elCreated,
          total: elementsFinished + elProcess + elCreated,
          datasets: [{
            data: [elementsFinished, response.projects[0].elements.length - elementsFinished],
            backgroundColor: ["#78B737", "#F2F2F2"],
            display: true,
            borderWidth: 0
          }],
          percent: (elementsFinished / (elementsFinished + elProcess + elCreated) * 100).toFixed(0)
        });

        let phasesFinished = 0;
        let phasesProcess = 0;
        let phasesCreated = 0;
        let tasksFinished = 0;
        let tasksProcess = 0;
        let tasksCreated = 0;
        response.projects[0].elements.map((el: any) => {
          phasesProcess += el.phases.filter((ph: any) => ph.status_id == 2).length;
          phasesFinished += el.phases.filter((ph: any) => ph.status_id == 4).length;
          phasesCreated += el.phases.filter((ph: any) => ph.status_id == 1).length;
          el.phases.map((ph: any) => {
            tasksFinished += ph.tasks.filter((tk: any) => tk.status_id == 4).length;
            tasksProcess += ph.tasks.filter((tk: any) => tk.status_id == 2).length;
            tasksCreated += ph.tasks.filter((tk: any) => tk.status_id == 1).length;
          });
        });

        setPhasesSummaryData({
          name: t("home.phases"),
          finished: phasesFinished,
          process: phasesProcess,
          created: phasesCreated,
          total: phasesCreated + phasesProcess + phasesFinished,
          datasets: [{
            data: [phasesFinished, (phasesCreated + phasesProcess + phasesFinished) - phasesFinished],
            backgroundColor: ["#78B737", "#F2F2F2"],
            display: true,
            borderWidth: 0
          }],
          percent: (phasesFinished / (phasesCreated + phasesProcess + phasesFinished) * 100).toFixed(0)
        });

        setTasksSummaryData({
          name: t("home.tasks"),
          finished: tasksFinished,
          process: tasksProcess,
          created: tasksCreated,
          total: tasksFinished + tasksProcess + tasksCreated,
          datasets: [{
            data: [tasksFinished, tasksProcess + tasksCreated],
            backgroundColor: ["#78B737", "#F2F2F2"],
            display: true,
            borderWidth: 0
          }],
          percent: (tasksFinished / (tasksFinished + tasksProcess + tasksCreated) * 100).toFixed(0)
        });
        if (user.role.accessLevel >= MIN_LEVEL_LEADER) {
          fillTaskTypes(response.projects[0].type_id);
        } else { setTimeout(function () { setLoading(false); }, 500); }
      } else {
        toast.warning(t(response.code));
        setLoading(false);
      }
    });
  }

  const fillUsersAll = () => {
    // @ts-ignore
    userService.getAll().then(({ data: response }) => {
      //console.log("fillUsersAll");
      //console.log(response);
      if (Array.isArray(response)) {
        setUsersAll(response.filter((u: any) => u.isEnabled));
      }
    });
  }

  const fillTaskStates = () => {
    // @ts-ignore
    taskStateService.getAll().then(({ data: response }) => {
      if (Array.isArray(response.data)) {
        setTaskStates(response.data);
      }
    });
  }

  const fillTaskTypes = (idProjectType: number) => {
    const entrada = {
      project_types: [idProjectType],
      root_level: "tasktypes"
    }

    // @ts-ignore
    estimationService.getAccumulatedEstimate(entrada).then(({ data: response }) => {
      if (response.status === "warning") {
        toast.error(t(response.code));
      } else {
        setTaskTypes(response.tasktypes);
      }
      setLoading(false);
    });
  }

  const fillElementTypes = () => {
    // @ts-ignore
    elementTypeService.getAllWithTasksByProjectType(projectInfo.type_id).then(({ data: response }) => {
      if (Array.isArray(response.data)) {
        setElementTypes(response.data);
      }
    });
  }

  const fillNotSavedTasks = (idProjectElement: number) => {
    // @ts-ignore
    taskTypeService.getNotSavedTasks(idProjectElement).then(({ data: response }) => {
      if (Array.isArray(response.data) && response.data.length) {
        getTasksEstimate(response.data.map((task: any) => ({
          "id": task.idTaskType,
          "id_size": 2,
          "id_complexity": 2
        })));
      }
    });
  }

  const getTasksEstimate = (taskTypesIDs: any) => {
    // @ts-ignore
    estimationService.getAccumulatedEstimate({ "task_types": taskTypesIDs }).then(({ data: response }) => {
      if (response.status === "error") {
        toast.error(response.message);
      } else {
        const tasks = response.projecttypes[0].elementtypes[0].dificulties[0].phasetypes
          .map((phase: any) => {
            phase.tasktypes.forEach((task: any) => task.idPhaseType = phase.id);
            return phase.tasktypes;
          }).flat();
        setNotSavedTasks(tasks);
      }
    }).catch(function (error: any) {
      console.log(error);
      toast.error("Ocurrió un problema al ejecutar la estimación (" + error + ")");
    });
  }

  const createProjectTask = (newProjectTask: any) => {
    // @ts-ignore
    projectTaskService.create(newProjectTask).then(({ data: response }) => {
      if (response.internalError != null && response.internalError && response.message != null) {
        toast.error(response.message);
      } else {
        setFormState({ ...formState, showFormAddTask: false, isCreating: false });
        toast.success(t("project_page.msg_success_task_add"));
      }

      fillProjectInfo();
    }).catch(function (error: any) {
      console.log(error);
      toast.error(t("project_tasks_page.msg_task_add_error"));
    });
  }

  const createProjectPhaseAndTask = (newProjectPhase: any) => {
    // @ts-ignore
    projectPhaseService.create(newProjectPhase).then(({ data: response }) => {
      if (response.internalError != null && response.internalError && response.message != null) {
        toast.error(response.message);
      } else {
        setFormState({ ...formState, showFormAddTask: false, isCreating: false });
        toast.success(t("project_page.msg_success_task_add"));
      }

      fillProjectInfo();
    }).catch(function (error: any) {
      console.log(error);
      toast.error(t("project_tasks_page.msg_task_add_error"));
    });
  }

  const deleteProject = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body custom-ui'>
            <h3 style={{ marginBottom: "1em" }}>{t("project_page.msg_delete_confirm2")} <span className="dark">{projectInfo.name + "?"}</span></h3>
            <p className="red"><strong>{t("project_page.msg_delete_confirm3")}</strong></p>
            <div className="react-confirm-alert-button-group">
              <Button
                onClick={() => {
                  setLoading(true);
                  try {
                    // @ts-ignore
                    projectService.delete(idProject).then(({ data: response }) => {
                      // ENVIAR A LA PANTALLA PRINCIPAL AL CERRA LA NOTIFICACIÓN
                      toast.success(
                        t("project_page.msg_success_delete"),
                        {
                          onClose: () => window.location.replace("/")
                        }
                      );
                      //setLoading(false);
                    }).catch(function (error: any) {
                      toast.error("Ocurrió un error al eliminar el proyecto.");
                      setLoading(false);
                    });
                  } catch (error) {
                    console.log("Error #Users handleDelete" + JSON.stringify(error));
                    toast.error("Ocurrió un error al eliminar el proyecto.");
                    setLoading(false);
                  }
                  onClose();
                }}>
                {t("project_page.msg_delete_task_confirm_yes")}
              </Button>
              <Button onClick={onClose}>{t("project_page.msg_delete_task_confirm_no")}</Button>
            </div>
          </div>
        );
      }
    });
  }

  const handleTaskSelectChange = (idTaskType: number) => {
    const selectedTask = notSavedTasks.find((task: any) => task.id == idTaskType);
    setSelectedNewTask(selectedTask);
    setFormikTask({
      values: {
        ...formikTask.values, idTaskType: selectedTask.id, estimatedDays: Math.round(selectedTask.duration_avg),
        estimatedTime: Math.round(selectedTask.effort_avg)
      }, errors: formikTask.errors
    });
  }

  function getArrUsersFromValues() {
    let arr: { idProjectTask: number; idUser: number; }[] = [];
    valuesUsersSelect.map((usr: any) => {
      arr.push({ idProjectTask: 0, idUser: usr.value });
    });

    return arr;
  }

  function getArrUsersFilterIdsFromValues() {
    let arr: number[] = [];
    valuesUsersSelectFilter.map((usr: any) => {
      arr.push(usr.value);
    });
    return arr;
  }

  function getArrStatusFilterIdsFromValues() {
    let arr: number[] = [];
    valuesStatusSelectFilter.map((st: any) => {
      arr.push(st.value);
    });
    return arr;
  }

  function getProjectPhaseValue(idTaskType: number) {
    let idPhaseType = notSavedTasks.find((task: any) => task.id == idTaskType).idPhaseType;
    const projectElement = projectInfo.elements.find((elem: any) => elem.id == idSelectedProjectElement);

    return projectElement.phases.find((phase: any) => phase.type_id == idPhaseType);
  }

  const [formikTask, setFormikTask] = useState({
    values: {
      idTaskType: 0, estimatedDays: 0, estimatedTime: 0,
      registredDate: dateToDateLocale(new Date())
    }, errors: { idTaskType: null }
  });

  //console.log(formikTask);
  const handleSubmit = (values: any) => {
    let errors = validate(values);
    if (JSON.stringify(errors) != '{}') {
      console.log(errors);
      setFormikTask({ values: formikTask.values, errors: errors });
      return;
    }
    const projectPhase = getProjectPhaseValue(values.idTaskType);
    const taskUsers = getArrUsersFromValues();

    if (projectPhase !== undefined) {
      const newProjectTask = {
        taskType: {
          idTaskType: values.idTaskType
        },
        projectPhase: {
          idProjectPhase: projectPhase.id
        },
        estimatedDays: values.estimatedDays,
        estimatedTime: values.estimatedTime,
        registredDate: values.registredDate,
        users: taskUsers
      };

      createProjectTask(newProjectTask);
    } else {
      const newProjectPhase = {
        projectElement: {
          idProjectElement: Number(idSelectedProjectElement)
        },
        idPhaseType: selectedNewTask.idPhaseType,
        estimatedDays: values.estimatedDays,
        estimatedTime: values.estimatedTime,
        projectTasks: [{
          taskType: {
            idTaskType: values.idTaskType
          },
          projectPhase: {
            idProjectPhase: 0
          },
          estimatedDays: values.estimatedDays,
          estimatedTime: values.estimatedTime,
          users: taskUsers
        }]
      }

      createProjectPhaseAndTask(newProjectPhase);
    }

    setIdSelectedProjectElement(0);

  }

  const validate = (values: any) => {
    let errors: any = {};
    if (!values.idTaskType) {
      errors.idTaskType = "Required";
    }

    if (new Date(values.registredDate) < new Date(projectInfo.registred_date)) {
      errors.registredDate = t("project_tasks_page.msg.errors.registred_date");
    }
    return errors;
  }



  const handleCancel = () => {
    setIdSelectedProjectElement(0);
    setFormikTask({ values: { idTaskType: 0, estimatedDays: 0, estimatedTime: 0 }, errors: { idTaskType: null } });
    setFormState({ ...formState, isCreating: false, isEditing: false, showFormAddTask: false });
  }

  const handleChangeSearchInput = (searchFilter: string) => {
    setSearchFilterValue(searchFilter);
    let items = projectInfo.elements.filter((data: any) => {
      if (searchFilter == "") {
        return projectInfo;
      } else {
        if ((data.name.toLowerCase().includes(searchFilter.toLowerCase()))) {
          return data;
        }
      }
    });
    // todo: filtrar por nombre de fases y tareas

    // SET ELEMENTS FILTERED
    setElementsFiltered(items);
  }

  /**
   * Manejar el filtrado por STATUS(ES) y/o USUARIO(S)
   **/
  const handleFilterElements = () => {
    let arrStatus = getArrStatusFilterIdsFromValues();
    let arrUsersSelectedIds = getArrUsersFilterIdsFromValues();
    if (arrStatus.length > 0 || arrUsersSelectedIds.length > 0) {
      let items = [];

      let elementsWithFilter = [];
      for (let m = 0; m < projectInfo.elements.length; m++) {
        let el = projectInfo.elements[m];

        let phasesWithFilter = [];   // Arreglo de fases que coinciden con el filtro/filtros
        for (let i = 0; i < el.phases.length; i++) {
          let ph = el.phases[i];

          let tasksWithFilter = [];
          for (let k = 0; k < ph.tasks.length; k++) {
            let tk = ph.tasks[k];

            if (arrStatus.length > 0 && arrUsersSelectedIds.length > 0) {   // Aplican los 2 filtros
              if (tk.users.map((u: any) => u.id).some((r: any) => arrUsersSelectedIds.includes(r)) && 
                arrStatus.includes(tk.status_id)) {
                tasksWithFilter.push(tk);
              }
            } else if (arrUsersSelectedIds.length > 0) {  // Aplica filtro de usuarios
              if (tk.users.map((u: any) => u.id).some((r: any) => arrUsersSelectedIds.includes(r))) {
                tasksWithFilter.push(tk);
              }
            } else {    // Aplica filtro de status
              if (arrStatus.includes(tk.status_id)) {
                tasksWithFilter.push(tk);
              }
            }
          }

          // Determinar si la fase tiene tareas con el estatus seleccionado
          if (tasksWithFilter.length > 0) {
            let phCopy = JSON.parse(JSON.stringify(ph)); // Copiar sin referencia
            phCopy["tasks"] = tasksWithFilter;
            phasesWithFilter.push(phCopy);
          }
        }

        // Determinar si el elemento tiene fases con tareas con el estatus/usuario seleccionado
        if (phasesWithFilter.length > 0) {
          let elCopy = JSON.parse(JSON.stringify(el)); // Copiar sin referencia
          elCopy["phases"] = phasesWithFilter;
          // Aplicar el filtro de búsqueda, si existe
          if (searchFilterValue != "") {
            if (elCopy.name.toLowerCase().includes(searchFilterValue.toLowerCase())) {
              elementsWithFilter.push(elCopy);
            }
          } else {
            elementsWithFilter.push(elCopy);
          }
        }
      }

      setElementsFiltered(elementsWithFilter);
    } else {
      if (searchFilterValue != "") {
        handleChangeSearchInput(searchFilterValue);
      } else {
        setElementsFiltered(projectInfo.elements);
      }
    }
  }

  const fillLeader = async () => {
    userService.getLeaders().then(({ data: response }: any) => {
      //console.log(response);
      if (Array.isArray(response)) {
        setLeaders(response);
      }
    });
    userService.getLeadersByProject(idProject).then(({ data: response }: any) => {
      //console.log(response_);
      if (Array.isArray(response)) {
        //console.log(response);
        let ids = response.map((x: any) => { return { value: x.idUser, label: (x?.user.name ? x.user.name : '') + ' ' + 
        (x?.user.lastName ? x.user.lastName : '') + ' ' +
        (x?.user.email ? '('+ x.user.email + ')' : '') }; });
        //console.log(ids);
        setIdsLeaderAsigned(ids);
        if (!canEdit) setCanEdit(((user.role.accessLevel >= MIN_LEVEL_LEADER) && 
          ids.find((x: any) => x.value == user.idUser)) || user.role.accessLevel >= MIN_LEVEL_ADMIN);
      }
    });
  }

  const handleOpenHelp = () => {
    setActiveHelperDialogIndex(0);
  }

  const handleClose = () => {
    setActiveHelperDialogIndex(-1);
  };

  const handleActiveHelperChange = () => {
    if (activeHelperDialogIndex === helperDialogs['projectDetail'].length) {
      setActiveHelperDialogIndex(-1)
    } else {
      setActiveHelperDialogIndex((prev: number) => prev + 1)
    }
  }

  const exportData = () => {
    //console.log(elementsFiltered);
    let data: any = [];

    elementsFiltered.forEach((elem: any) => {
      let elem_ = {
        elementName: elem.name, elementStatus: elem.status_name,
        elementStartDate: elem.start_date == 0 ? "" : elem.start_date,
        elementEndDate: elem.end_date == 0 ? "" : elem.end_date,
        elementEstimatedFinishDate: elem.estimated_finish_date == 0 ? "" : elem.estimated_finish_date,
        elementEffortDeviation: (elem.registered_effort_hours * 3600 + elem.registered_effort_minutes * 60) == 0 ? 0
          : formatSeconds((elem.registered_effort_hours * 3600 + elem.registered_effort_minutes * 60) - (elem.effort * 3600), false, false, false),
        elementDaysDeviation: elem.days_deviation, elementEstimateDuration: elem.duration,
        elementEstimateEffort: elem.effort, elementRealDuration: elem.registered_duration,
        elementRealEffort: elem.registered_effort
      };
      if (elem.phases.length > 0) {
        elem.phases.forEach((phase: any) => {
          let phase_ = {
            phaseName: phase.type_name, phaseStatus: phase.status_name,
            phaseStartDate: phase.start_date == 0 ? "" : phase.start_date,
            phaseEndDate: phase.end_date == 0 ? "" : phase.end_date,
            phaseEstimatedFinishDate: phase.estimated_finish_date == 0 ? "" : phase.estimated_finish_date,
            phaseEffortDeviation: (phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60) == 0 ? 0
              : formatSeconds((phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60) - (phase.effort * 3600), false, false, false),
            phaseDaysDeviation: phase.days_deviation, phaseEstimateDuration: phase.duration,
            phaseEstimateEffort: phase.effort, phaseRealDuration: phase.registered_duration,
            phaseRealEffort: phase.registered_effort
          }
          if (phase.tasks.length > 0) {
            phase.tasks.forEach((task: any) => {
              let task_ = {
                taskName: task.type_name, taskStatus: task.status_name,
                taskStartDate: task.start_date == 0 ? "" : task.start_date,
                taskEndDate: task.end_date == 0 ? "" : task.end_date,
                taskEstimatedFinishDate: task.estimated_finish_date == 0 ? "" : task.estimated_finish_date,
                taskEffortDeviation: (task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) == 0 ? 0
                  : formatSeconds((task.registered_effort_hours * 3600 + task.registered_effort_minutes * 60) - (task.effort * 3600), false, false, false),
                taskDaysDeviation: task.days_deviation, taskEstimateDuration: task.duration,
                taskEstimateEffort: task.effort, taskRealDuration: task.registered_duration,
                taskRealEffort: task.registered_effort
              }
              if (task.users.length > 0) {
                task.users.forEach((user: any) => {
                  let user_ = { userName: user.name + ' ' + user.lastname };
                  data.push({ ...elem_, ...phase_, ...task_, ...user_ });
                });
              } else {
                data.push({ ...elem_, ...phase_, ...task_ });
              }
            });
          } else {
            data.push({ ...elem_, ...phase_ });
          }
        });
      } else {
        data.push({ ...elem_ });
      }
    });
    return data;
  }

  return (
    <SiteWrapper>
      <Container>
        <TaskTypesContext.Provider value={taskTypes} >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flexGrow: 1 }}>
              {t("project_tasks_page.title")}
              <h3 style={{ marginBottom: 0 }}>
                {projectInfo.name}&nbsp;
                {projectInfo.name !== undefined &&
                  <IconButton
                    size='small'
                    onClick={handleOpenHelp}
                  >
                    <HelpIcon sx={{ color: '#6875C7' }} />
                  </IconButton>
                }
              </h3>
              <Typography style={{ fontSize: '0.85rem', fontWeight: '700', marginBottom: '0.5em' }}>
                {t("project_page.registred_date") + ': ' + (projectInfo.registred_date ? projectInfo.registred_date : "")}
              </Typography >
            </Box>
            {user.role.accessLevel >= MIN_LEVEL_LEADER &&
              <div style={{ display: 'flex', alignItems: 'end', marginBottom: '0.3em' }}>
                {features.exportProjectToExcel && <ExcelFile
                  element={
                    <TrueffortButton
                      id="idBtnExportProject"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {"Exportar"}
                    </TrueffortButton>}
                  filename={"Proyecto"}
                >
                  <ExcelSheet data={() => exportData()} name="Datos">
                    <ExcelColumn label={t("project_tasks_page.element_name")} value={"elementName"} />
                    <ExcelColumn label={t("home.status")} value={"elementStatus"} />
                    <ExcelColumn label={t("start_date")} value={"elementStartDate"} />
                    <ExcelColumn label={t("end_date")} value={"elementEndDate"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_finish_date")} value={"elementEstimatedFinishDate"} />
                    <ExcelColumn label={t("project_tasks_page.effort_deviation")} value={"taskEffortDeviation"} />
                    <ExcelColumn label={t("project_tasks_page.days_deviation")} value={"elementDaysDeviation"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_duration")} value={"elementEstimateDuration"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_effort")} value={"elementEstimateEffort"} />
                    <ExcelColumn label={t("project_tasks_page.registered_duration")} value={"elementRealDuration"} />
                    <ExcelColumn label={t("project_tasks_page.registered_effort")} value={"elementRealEffort"} />
                    <ExcelColumn label={t("project_tasks_page.phase_name")} value={"phaseName"} />
                    <ExcelColumn label={t("home.status")} value={"phaseStatus"} />
                    <ExcelColumn label={t("start_date")} value={"phaseStartDate"} />
                    <ExcelColumn label={t("end_date")} value={"phaseEndDate"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_finish_date")} value={"phaseEstimatedFinishDate"} />
                    <ExcelColumn label={t("project_tasks_page.days_deviation")} value={"phaseDaysDeviation"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_duration")} value={"phaseEstimateDuration"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_effort")} value={"phaseEstimateEffort"} />
                    <ExcelColumn label={t("project_tasks_page.registered_duration")} value={"phaseRealDuration"} />
                    <ExcelColumn label={t("project_tasks_page.registered_effort")} value={"phaseRealEffort"} />
                    <ExcelColumn label={t("project_tasks_page.task_name")} value={"taskName"} />
                    <ExcelColumn label={t("home.status")} value={"taskStatus"} />
                    <ExcelColumn label={t("start_date")} value={"taskStartDate"} />
                    <ExcelColumn label={t("end_date")} value={"taskEndDate"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_finish_date")} value={"taskEstimatedFinishDate"} />
                    <ExcelColumn label={t("project_tasks_page.days_deviation")} value={"taskDaysDeviation"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_duration")} value={"taskEstimateDuration"} />
                    <ExcelColumn label={t("project_tasks_page.estimated_effort")} value={"taskEstimateEffort"} />
                    <ExcelColumn label={t("project_tasks_page.registered_duration")} value={"taskRealDuration"} />
                    <ExcelColumn label={t("project_tasks_page.registered_effort")} value={"taskRealEffort"} />
                    <ExcelColumn label={t("User")} value={"userName"} />
                  </ExcelSheet>
                </ExcelFile>}
                <Box style={{ paddingLeft: '0.5em' }}>
                  <TrueffortButton
                    id="idBtnDeleteProject"
                    color="gray"
                    variant="outlined"
                    size="small"
                    onClick={deleteProject}>
                    <DeleteIcon sx={{ fontSize: "1.1rem" }} />
                    {t("erase")}
                  </TrueffortButton>
                </Box>
              </div>
            }
          </Box>

          <Grid container spacing={2}>
            <Grid xs={12} md={6} >
              <HelperDialog
                title={t(helperDialogs['projectDetail'][0].title)}
                body={t(helperDialogs['projectDetail'][0].body)}
                dialogIndex={0}
                totalDialogs={helperDialogs['projectDetail'].length}
                isOpen={activeHelperDialogIndex === 0}
                handleNext={handleActiveHelperChange}
                handleClose={handleClose}
                position='right'
              >
                <Card sx={{ background: "#F5F5F5", padding: "6px" }}>
                  {t("project_tasks_page.automatic_estimation")}
                  <Grid container spacing={1}>
                    <Grid xs={6}>
                      <Card sx={{ padding: "12px", textAlign: "center" }}>
                        <h5>{t("project_tasks_page.estimated_duration")}</h5>
                        <h2 style={{ marginBottom: 0 }}>{projectInfo?.duration?.toLocaleString()} 
                          {belowSmall && <br />} {t("estimation_page.days")}
                        </h2>
                      </Card>
                    </Grid>
                    <Grid xs={6}>
                      <Card sx={{ padding: "12px", textAlign: "center" }}>
                        <h5>{t("project_tasks_page.estimated_effort")}</h5>
                        <h2 style={{ marginBottom: 0 }}>{formatSeconds(projectInfo?.effort * 3600, false, false, false)} 
                          {belowSmall && <br />} {user.timeFormat == 'dec' ? t("estimation_page.man_hours") : ''}
                        </h2>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </HelperDialog>
            </Grid>
            <Grid xs={12} md={6}>
              <HelperDialog
                title={t(helperDialogs['projectDetail'][1].title)}
                body={t(helperDialogs['projectDetail'][1].body)}
                dialogIndex={1}
                totalDialogs={helperDialogs['projectDetail'].length}
                isOpen={activeHelperDialogIndex === 1}
                handleNext={handleActiveHelperChange}
                handleClose={handleClose}
                position='left'
              >
                <Card sx={{ background: "#050536", color: "white", padding: "6px" }}>
                  {t("project_tasks_page.registered_in_trueffort")}
                  <Grid container spacing={1}>
                    <Grid xs={6}>
                      <Card sx={{ padding: "12px", textAlign: "center" }}>
                        <h5>{t("project_tasks_page.registered_duration")}</h5>
                        <h2 style={{ marginBottom: 0 }}>{projectInfo?.registered_duration?.toLocaleString()} 
                          {belowSmall && <br />} {t("estimation_page.days")}
                        </h2>
                      </Card>
                    </Grid>
                    <Grid xs={6}>
                      <Card sx={{ padding: "12px", textAlign: "center" }}>
                        <h5>{t("project_tasks_page.registered_effort")}</h5>
                        <h2 style={{ marginBottom: 0 }}>
                          {(typeof projectInfo?.registered_effort_hours == "undefined" ? "--" :
                            formatSeconds((projectInfo?.registered_effort_hours * 3600) + (projectInfo?.registered_effort_minutes * 60), false, false, false)
                          )}
                          {belowSmall && <br />}
                          {user.timeFormat == 'dec' ? " " + t("project_tasks_page.hp") : ""}
                        </h2>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </HelperDialog>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <HelperDialog
              title={t(helperDialogs['projectDetail'][2].title)}
              body={t(helperDialogs['projectDetail'][2].body)}
              dialogIndex={2}
              totalDialogs={helperDialogs['projectDetail'].length}
              isOpen={activeHelperDialogIndex === 2}
              handleNext={handleActiveHelperChange}
              handleClose={handleClose}
              position='right'
            >
              <Grid xs={12} md={4}>
                <SummaryCard key={11} data={elementSummaryData} />
              </Grid>
            </HelperDialog>
            <HelperDialog
              title={t(helperDialogs['projectDetail'][3].title)}
              body={t(helperDialogs['projectDetail'][3].body)}
              dialogIndex={3}
              totalDialogs={helperDialogs['projectDetail'].length}
              isOpen={activeHelperDialogIndex === 3}
              handleNext={handleActiveHelperChange}
              handleClose={handleClose}
              position='right'
            >
              <Grid xs={12} md={4}>
                <SummaryCard key={12} data={phasesSummaryData} />
              </Grid>
            </HelperDialog>
            <HelperDialog
              title={t(helperDialogs['projectDetail'][4].title)}
              body={t(helperDialogs['projectDetail'][4].body)}
              dialogIndex={4}
              totalDialogs={helperDialogs['projectDetail'].length}
              isOpen={activeHelperDialogIndex === 4}
              handleNext={handleActiveHelperChange}
              handleClose={handleClose}
              position='left'
            >
              <Grid xs={12} md={4}>
                <SummaryCard key={13} data={tasksSummaryData} />
              </Grid>
            </HelperDialog>
          </Grid>

          <Grid container spacing={1} sx={{ marginTop: ".4rem", marginBottom: ".2rem" }}>
            <Grid xs={12} md={3}>
              <TrueffortSearchInput
                id="idSearchInput"
                placeholder={t("search")}
                variant="outlined"
                size="small"
                onChange={(e: any) => handleChangeSearchInput(e.target.value)}
              />
            </Grid>
            <Grid xs={12} md={valuesStatusSelectFilter.length > 1 ? 4 : 2}>
              <SelectMulti
                isMulti
                label={t("home.status")}
                placeholder={t("project_page.status_activity")}
                value={valuesStatusSelectFilter}
                options={
                  taskStates.map((s: any, k: number) => {
                    return ({
                      value: s.idStatus,
                      label: s.name
                    })
                  })
                }
                onChange={(option: any) => {
                  setValuesStatusSelectFilter(option);
                }}
              />
            </Grid>
            <Grid xs={12} md={valuesUsersSelectFilter.length > 1 ? 5 : 2}>
              {user.role.accessLevel >= MIN_LEVEL_LEADER &&
                <SelectMulti
                  isMulti
                  label={t("User")}
                  placeholder={t("User")}
                  value={valuesUsersSelectFilter}
                  options={
                    users.map((u: any, k: number) => {
                      return ({
                        value: u.idUser,
                        label: u.fullname
                      })
                    })
                  }
                  onChange={(option: any) => {
                    setValuesUsersSelectFilter(option);
                  }}
                />}
            </Grid>
            {(valuesStatusSelectFilter.length > 1 || valuesUsersSelectFilter.length > 1) &&
              <Grid xs={0} md={7}>
              </Grid>
            }
            <Grid xs={12} md={5}>
              {user.role.accessLevel >= MIN_LEVEL_LEADER &&
                <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                  {user.role.accessLevel >= MIN_LEVEL_ADMIN && <TrueffortButton
                    id="idBtnNewLeader"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setFormState({ ...formState, showFormAddLeader: true, showFormAddTask: false, 
                        showFormAddElement: false });
                      setIdsLeader(idsLeaderAsigned);
                    }}
                  >
                    + {t("role.rLeader")}
                  </TrueffortButton>}
                  <TrueffortButton
                    id="idBtnNewElement"
                    onClick={() => {
                      fillElementTypes();
                      setFormState(
                        { ...formState, isCreating: true, showFormAddElement: true, showFormAddTask: false, 
                          showFormAddLeader: false }
                      );
                    }}
                    fullWidth color="primary" variant="contained">
                    + {t("estimation_page.element")}
                  </TrueffortButton>
                  <TrueffortButton
                    id="idBtnNewTask"
                    onClick={() => {
                      fillElementTypes();
                      setFormState({ ...formState, isCreating: true, showFormAddElement: false, 
                        showFormAddTask: true });
                    }}
                    fullWidth color="primary" variant="contained">
                    + {t("project_page.new_task")}
                  </TrueffortButton>
                </Stack>
              }
            </Grid>
          </Grid>

          {(!loading && formState.showFormAddTask) &&
            <Card sx={{ padding: "1rem", overflow: "unset" }}>
              <h5 className="color-primary">{t("project_tasks_page.add_task_title")}</h5>
              <Grid container spacing={2}>
                <Grid xs={12} sm={4}>
                  <TrueffortSelect
                    id="idSlNewTaskElementType"
                    name="idElementType"
                    size="small"
                    fullWidth
                    label={t("project_tasks_page.select_element")}
                    value={idSelectedProjectElement}
                    onChange={(e: any) => {
                      setIdSelectedProjectElement(e.target.value)
                      fillNotSavedTasks(parseInt(e.target.value));
                    }}
                  >
                    <MenuItem key="-1" value="" disabled>{t("Select")}</MenuItem>
                    {projectInfo.elements.map((projectElement: any) =>
                      <MenuItem key={projectElement.id} value={projectElement.id}>{projectElement.name}</MenuItem>
                    )}
                  </TrueffortSelect>
                </Grid>
                <Grid xs={12} sm={4}>
                  <TrueffortSelect
                    id="idSlTaskType"
                    name="idTaskType"
                    label={t("project_tasks_page.select_task")}
                    size="small"
                    fullWidth
                    onChange={(e: any) => {
                      setFormikTask({ values: {} })
                      handleTaskSelectChange(e.target.value)
                    }}
                    disabled={!notSavedTasks.length}
                    value={formikTask.values.idTaskType}
                    error={formikTask.errors?.idTaskType ? true : false}
                  >
                    <MenuItem value={0} disabled >{notSavedTasks.length ? t("Select") : 
                      t("estimation_page.no_tasks")}</MenuItem>
                    {notSavedTasks.map((task: any) =>
                      <MenuItem key={task.id} value={task.id} >
                        {task.name}
                      </MenuItem>)}
                  </TrueffortSelect>
                  {formikTask.errors.idTaskType && <span style={{ color: 'red', fontSize: '0.75rem' }}>
                    {t(formikTask.errors.idTaskType)}</span>}
                </Grid>
                <Grid xs={12} sm={4}>
                  <SelectMulti
                    name="users"
                    isMulti
                    label={t("project_tasks_page.assign_users")}
                    placeholder={t("project_page.users")}
                    value={valuesUsersSelect}
                    isDisabled={!formikTask.values.idTaskType}
                    onChange={(option: any) => setValuesUsersSelect(option)}
                    options={
                      usersAll.map((usr: any) => (
                        {
                          value: usr.idUser,
                          label: usr.fullNameEmail
                        }
                      ))
                    }
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <TextField
                    name="estimatedDays"
                    label={t("project_tasks_page.estimated_duration")}
                    type="number"
                    min="1"
                    size="small"
                    fullWidth
                    disabled={!formikTask.values.idTaskType}
                    onChange={(ev: any) => setFormikTask({
                      values: { ...formikTask.values, estimatedDays: ev.target.value },
                      errors: formikTask.errors
                    })}
                    value={formikTask.values.estimatedDays}
                    error={formikTask.errors?.estimatedDays && formikTask.touched?.estimatedDays ? 
                      t(formikTask.errors.estimatedDays) : null} >
                  </TextField>
                </Grid>
                <Grid xs={12} sm={4} >
                  <TextField
                    name="estimatedTime"
                    label={t("project_tasks_page.estimated_effort")}
                    type="number"
                    min="1"
                    size="small"
                    fullWidth
                    disabled={!formikTask.values.idTaskType}
                    onChange={(ev: any) => setFormikTask({
                      values: { ...formikTask.values, estimatedTime: ev.target.value },
                      errors: formikTask.errors
                    })}
                    value={formikTask.values.estimatedTime}
                    error={formikTask.errors?.estimatedTime && formikTask.touched?.estimatedTime ? 
                      t(formikTask.errors.estimatedTime) : null} >
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <DatePicker
                    style={{ width: '100%' }}
                    dateFormat="yyyy/MM/dd"
                    selected={formikTask?.values?.registredDate}
                    onChange={(date: any) => setFormikTask({
                      values: { ...formikTask.values, registredDate: dateToDateLocale(date) },
                      errors: formikTask.errors
                    })}
                    customInput={<TrueffortTextField
                      size="small"
                      name="registredDate"
                      inputProps={{ background: 'red' }}
                      error={formikTask.errors.registredDate || ''}
                    />}
                  />
                </Grid>
                <Grid xs={12} sm={2}>
                  <TrueffortButton color="primary" onClick={() => handleSubmit(formikTask.values)}>
                    <SaveIcon />
                  </TrueffortButton>
                  <TrueffortButton color="gray" onClick={handleCancel} >
                    <CloseIcon />
                  </TrueffortButton>
                </Grid>
                {formikTask.errors.registredDate && <Grid item xs={12} style={{ color: 'red', fontSize: '0.75rem' }}>
                  {formikTask.errors.registredDate + ' ' + projectInfo.registred_date}</Grid>}
              </Grid>
            </Card>
          }

          {(!loading && formState.showFormAddElement) &&
            <AddElementForm
              t={t}
              formState={formState}
              setFormState={setFormState}
              elementTypes={elementTypes}
              project={{
                idProject: projectInfo.id,
                idProjectType: projectInfo.type_id
              }}
              _fillProjectInfo={fillProjectInfo} />
          }

          {(!loading && formState.showFormAddLeader) &&
            <Card sx={{ padding: "1rem", overflow: "unset" }}>
              <h6>{t("leader.add")}</h6>
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={4}>
                  <SelectMulti
                    id="idSlNewLeader"
                    sx={{ background: "white" }}
                    size="small"
                    isMulti
                    label={t("leader.assign")}
                    name="idLeader"
                    value={idsLeader}
                    onChange={(option: any) => {
                      setIdsLeader(option)
                    }
                    }
                    options={
                      leaders.map((leader: any) => { return { value: leader.idUser, label: (leader?.name ? leader.name : '') + ' ' + 
                      (leader?.lastName ? leader.lastName : '') + ' ' +
                      (leader?.email ? '('+ leader.email + ')' : '') }; })
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                  <TrueffortButton onClick={() => {
                    projectService.assignLeaderes(idsLeader.map((l: any) => {
                      return { idUser: l.value, idProject: idProject };
                    })).then(({ data: response_ }: any) => {
                      if (response_.success) {
                        toast.success(t("project_page.msg_success_assign_leader"));
                        setIdsLeaderAsigned(response_.data.map((x: any) => { return { value: x.idUser, label: (x?.user?.name ? x.user.name : '') + ' ' + 
                        (x?.user?.lastName ? x.user.lastName : '') + ' ' +
                        (x?.user?.email ? '('+ x.user.email + ')' : '') }; }));
                      } else {
                        toast.error(response_.message);
                      }
                    }).finally(() => setFormState({ ...formState, showFormAddLeader: false }));
                  }}
                  >
                    <SaveIcon />
                  </TrueffortButton>
                  <TrueffortButton
                    color="gray"
                    onClick={() => {
                      setFormState({ ...formState, showFormAddLeader: false }); setIdsLeader([]);
                    }}
                  >
                    <CloseIcon />
                  </TrueffortButton>
                </Grid>
              </Grid>
            </Card>
          }

          {loading &&
            <div style={{ margin: "0 auto", width: "100px" }}><CircularProgress /></div>
          }

          {!loading &&
            <Card sx={{ padding: "12px", marginTop: ".4rem", overflowX: 'scroll' }} className="background-gray">

              {user.role.accessLevel >= MIN_LEVEL_ADMIN && idsLeaderAsigned.length > 0 &&
                <div>
                  <span className="color-sand600" style={{ fontSize: ".8rem" }}>
                    <strong>{t('project_page.leaders')}</strong>
                  </span>
                  <ul>
                    {idsLeaderAsigned.map((l: any) => (
                      <li style={{ fontSize: ".8rem" }}>{l.label}</li>
                    ))}
                  </ul>
                </div>
              }


              <HelperDialog
                title={t(helperDialogs['projectDetail'][5].title)}
                body={t(helperDialogs['projectDetail'][5].body)}
                dialogIndex={5}
                totalDialogs={helperDialogs['projectDetail'].length}
                isOpen={activeHelperDialogIndex === 5}
                handleNext={handleActiveHelperChange}
                handleClose={handleClose}
              >
                <Box sx={{ minWidth: "970px" }} >
                  <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#F5F5F5' }} >
                    <Grid container spacing={1} alignItems='center' >
                      <Grid item xs={6} >
                        <Stack direction="row" spacing={2} >
                          <Typography sx={{ fontSize: '1em', display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                            <AppRegistrationIcon fontSize="small" sx={{ marginRight: '6px' }} />
                            {t("project_page.template") + ": " + projectInfo.type_name}
                          </Typography>

                          <Typography sx={{ fontSize: '1em', display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                            <AssignmentIcon fontSize="small" sx={{ marginRight: '6px' }} />
                            {t("project_page.defined_tasks") + ": " + projectInfo.total_tasks}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("project_tasks_page.deviation")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1.8} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][11].title)}
                          body={t(helperDialogs['projectDetail'][11].body)}
                          dialogIndex={11}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 11}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("project_tasks_page.estimate")}
                          </Typography>
                        </HelperDialog>
                      </Grid>

                      <Grid item xs={1.8} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][12].title)}
                          body={t(helperDialogs['projectDetail'][12].body)}
                          dialogIndex={12}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 12}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("project_tasks_page.reality")}
                          </Typography>
                        </HelperDialog>
                      </Grid>
                      <Grid item xs={0.5} />

                      <Grid item xs={0.5} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][6].title)}
                          body={t(helperDialogs['projectDetail'][6].body)}
                          dialogIndex={6}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 6}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("Name")}
                          </Typography>
                        </HelperDialog>
                      </Grid>

                      <Grid item xs={1.5} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][7].title)}
                          body={t(helperDialogs['projectDetail'][7].body)}
                          dialogIndex={7}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 7}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("home.status")}
                          </Typography>
                        </HelperDialog>
                      </Grid>
                      <Grid item xs={1} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("project_tasks_page.registred_date")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][8].title)}
                          body={t(helperDialogs['projectDetail'][8].body)}
                          dialogIndex={8}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 8}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("start_date")}
                          </Typography>
                        </HelperDialog>
                      </Grid>

                      <Grid item xs={1} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][9].title)}
                          body={t(helperDialogs['projectDetail'][9].body)}
                          dialogIndex={9}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 9}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("end_date")}
                          </Typography>
                        </HelperDialog>
                      </Grid>
                      <Grid item xs={1} >
                        <HelperDialog
                          title={t(helperDialogs['projectDetail'][10].title)}
                          body={t(helperDialogs['projectDetail'][10].body)}
                          dialogIndex={10}
                          totalDialogs={helperDialogs['projectDetail'].length}
                          isOpen={activeHelperDialogIndex === 10}
                          handleNext={handleActiveHelperChange}
                          handleClose={handleClose}
                        >
                          <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                            {t("project_tasks_page.estimated_finish_date")}
                          </Typography>
                        </HelperDialog>
                      </Grid>
                      <Grid item xs={1} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("project_tasks_page.duration")} <br />
                          ({t("project_tasks_page.days")})
                        </Typography>
                      </Grid>
                      <Grid item xs={0.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("estimation_page.effort")} <br />
                          ({t("project_tasks_page.hp")})
                        </Typography>
                      </Grid>

                      <Grid item xs={0.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("project_tasks_page.duration")} <br />
                          ({t("project_tasks_page.days")})
                        </Typography>
                      </Grid>

                      <Grid item xs={0.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("estimation_page.effort")} <br />
                          ({t("project_tasks_page.hp")})
                        </Typography>
                      </Grid>

                      <Grid item xs={0.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("project_tasks_page.duration")} <br />
                          ({t("project_tasks_page.days")})
                        </Typography>
                      </Grid>

                      <Grid item xs={0.9} >
                        <Typography sx={{ fontSize: '0.75rem' }} color='#4A4A4A' align="center" >
                          {t("estimation_page.effort")} <br />
                          ({t("project_tasks_page.hp")})
                        </Typography>
                      </Grid>

                      <Grid item xs={0.5} >
                        <Typography variant='subtitle2' color='#4A4A4A' align="center" >
                          <AddIcon fontSize='small' />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>

                  {elementsFiltered.map((el: any, i: number) => (
                    <ProjectDetailElementRow
                      key={el.id}
                      el={el}
                      users={usersAll}
                      idProject={projectInfo.id}
                      states={taskStates}
                      _fillProjectInfo={fillProjectInfo}
                      project={projectInfo}
                    />
                  ))}
                </Box>
              </HelperDialog>
            </Card>
          }
        </TaskTypesContext.Provider>
      </Container>
    </SiteWrapper>
  );
}

export default ProjectDetailPage;