import React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../../util/Interfaces";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {Container, Card, Box, Button, Chip, AvatarGroup, Avatar, Stack, Collapse, Paper, Typography, CardContent,
        Table, TableContainer, TableHead, TableBody, TableRow, TableCell, CircularProgress} from "@mui/material";
import {TrueffortButton, StyledTableCell, StyledTableRow, TrueffortTableHead, TrueffortTableHeadCell, TrueffortCardTable} from "../../MUIstyle/MUICustom";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Add, DeleteOutline, ExpandMore, Edit, Delete, Clear, Save, FileUpload, SaveAlt } from "@mui/icons-material";
import ProjectService from "../../services/project.service";
import { useState } from "react";

const FileDownload = require('js-file-download');

// @ts-ignore
export default function CreateFromXlsForm({_setShowXlsForm}) {
    const {t} = useTranslation();

    const uploadFile = React.useRef();
    const [uploadFileInfo, setUploadFileInfo] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = useState(null);
    //const [bulkUploadErrors, setBulkUploadErrors] = React.useState([]);
    const projectService = React.useMemo(() => new ProjectService(), []);
    //console.log(errors);
    const handleDownloadXlsx = () => {
        // @ts-ignore
        projectService.downloadEstimationXlsLayout().then((response) => {
            FileDownload(response.data, 'Layout-estimation.xls');
        }).catch((response: any) => {
            //setShowLoaderExport(false);
        });
    }

    function onChangeUploadFile() {
        setUploadFileInfo({
            name: uploadFile.current?.files[0].name,
            size: uploadFile.current?.files[0].size,
            type: uploadFile.current?.files[0].type
        });
    }

    const uploadFileElements = (confirm:boolean) => {
      setErrors(null);
        //setBulkUploadErrors([]);

        if (uploadFileInfo?.type !== "application/vnd.ms-excel" && uploadFileInfo?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            toast.error(t("user_page.msg_error_file_type"));
            return;
        }

        setLoading(true);
        let formData = new FormData();
        formData.append("file", uploadFile.current.files[0], uploadFile.current.files[0].name);
        formData.append("confirm", new Blob([JSON.stringify(confirm)], {type: "application/json"}));
        try {
            // @ts-ignore
            projectService.bulkUploadProject(formData).then(({ data: response }) => {
                console.log("RESP", response);
                if (response?.data?.isOk) {
                  toast.success(t("estimation_page.msg_success_save"), 
                    { onClose: () => window.location.replace("/projectDetail?idProject=" + response.data.idProject)}
                  );
                  setLoading(false);
                } else {
                  if (!response.success) {
                    setLoading(false);
                    toast.error(t(response.errorMessage));
                    return;
                  }
                  let err:any = {};
                  if (!response.data.effortOk) {
                    err.errorEffort = response.data.errorMessageEffort;
                  }
                  if (!response.data.durationOk) {
                    err.errorDuration = response.data.errorMessageDuration;
                  }
                  if (Array.isArray(response.data.elementDtoList)) {
                    err.elements = response.data.elementDtoList;
                      //setBulkUploadErrors(response.data.elementDtoList);
                      /*if (response.data.elementDtoList.length > 0) {
                          setLoading(false);
                          toast.error(t("estimation_page.msg_error_bulk_upload_errors"));
                      }*/
                  }
                  err.countZero = response.data.countZero;
                  err.errorMessage = response.data.errorMessage;
                  setErrors(err);
                  setLoading(false);
                  throw new Error(t("user_page.msg_error_bulk_upload"));
                }

                // reset file input
                let elTmp = document.getElementById("id-input-file")! as HTMLInputElement | null;
                if (elTmp != null) {
                    elTmp.value = "";
                }
            });
        } catch (err:any) {
            toast.error(err.message);
            setLoading(false);
        }
    }

    return (
        <Container maxWidth='xl'>
            <Card elevation={2}>
                <CardContent>
                    <Typography variant="h5" color='#050536' sx={{marginBottom: "1rem"}}>
                        {t("estimation_page.generate_project")}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid xs={12} lg={4}>
                            <TrueffortButton
                                id="btnDownloadLayout"
                                size="small"
                                color="secondary"
                                variant="contained"
                                fullWidth
                                disabled={loading}
                                startIcon={<SaveAlt />}
                                onClick={() => handleDownloadXlsx()}
                            >
                                {t("estimation_page.download_layout_xls")}
                            </TrueffortButton>
                        </Grid>
                        <Grid lg={4}>
                            <Button
                                id="upload-file-button"
                                variant="outlined"
                                component="label"
                                fullWidth
                                size="small"
                            >
                                <input
                                    type="file"
                                    id="id-input-file"
                                    ref={uploadFile}
                                    name="image"
                                    onChange={onChangeUploadFile}
                                    accept="application/vnd.ms-excel"
                                    disabled={loading}
                                    readOnly={loading}
                                />
                            </Button>
                        </Grid>
                        <Grid sm={4} xs={12}>
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                            <Box>
                                <TrueffortButton
                                    id="send-file-elements-button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={() => uploadFileElements(false)}
                                >
                                    <FileUpload />
                                </TrueffortButton>
                                {loading && (
                                          <CircularProgress
                                            size={38}
                                            sx={{
                                              position: "absolute",
                                              marginLeft: '-52px',
                                            }}
                                          />
                                        )}
                            </Box>


                                <TrueffortButton
                                    color="error"
                                    size="small"
                                    variant={"outlined"}
                                    disabled={loading}
                                    onClick={() => {
                                        _setShowXlsForm(false);
                                        setUploadFileInfo(null);
                                        setErrors(null);
                                    }}
                                    style={{ fontSize: "11px" }}
                                >
                                    <Clear />
                                </TrueffortButton>
                            </Stack>
                        </Grid>
                    </Grid>


                    {errors?.errorMessage && <Grid item xs={12} className={"text-danger"} >{errors.errorMessage}</Grid>}
                    {errors?.errorEffort && <Grid item xs={12} className={"text-danger"} >{errors.errorEffort}</Grid>}
                    {errors?.errorDuration && <Grid item xs={12} className={"text-danger"}>{errors.errorDuration}</Grid>}
                    {errors?.elements.length > 0 && <TrueffortCardTable sx={{marginTop: "1rem"}}>
                        <TableContainer component={Paper}>
                            < Table
                                responsive="true"
                                className="card-table table-vcenter text-nowrap"
                            >
                                <TrueffortTableHead>
                                    <TableRow>
                                        <TrueffortTableHeadCell>{""}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("project_page.elem_name_placeholder")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.effort_man_hours")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.days_duration")}</TrueffortTableHeadCell>
                                    </TableRow>
                                </TrueffortTableHead>
                                <TableBody>
                                    {errors?.elements.map((err: any) => (
                                        <TableRow key={err.row}>
                                            <TableCell>{err.row}</TableCell>
                                            <TableCell style={{maxWidth: '600px'}}>
                                              <div className={"mt-2"} style={{width: '100%', textWrap: 'wrap'}}>{err.name}</div>
                                              {!err.nameOk && <div className={"text-danger mt-2"}>{t(err.errName)}</div>}
                                            </TableCell>
                                            <TableCell>
                                              <div className={"mt-2"}>{err.effort ? err.effort : ""}</div>
                                              {!err.effortOk && <div className={"text-danger mt-2"}>{t(err.errEffort)}</div>}
                                            </TableCell>
                                            <TableCell>
                                              <div className={"mt-2"}>{err.duration ? err.duration : ""}</div>
                                              {!err.durationOk && <div className={"text-danger mt-2"}>{t(err.errDuration)}</div>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                      </TrueffortCardTable>
                    }
                    {errors?.countZero > 0 && <Grid item xs={12} style={{textAlign:'center'}}>
                      <div className="mt-2">{t("estimation_page.cero_estimates")}</div>
                      <div className="mt-2">{t("estimation_page.save_history")}</div>
                      <TrueffortButton
                        id="send-file-elements-button"
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => uploadFileElements(true)}
                    >
                        {"Aceptar"}
                    </TrueffortButton>
                  </Grid>}
                </CardContent>
            </Card>
        </Container>
    );
}