import * as React from "react";
import { Grid, Menu, MenuItem, Stack, Box, Slider, CircularProgress, Divider, Typography} from "@mui/material";
import {KeyboardArrowDown as KeyboardArrowDownIcon, CalendarMonth as CalendarMonthIcon, Person as PersonIcon, 
  Dashboard as DashboardIcon, Groups as GroupsIcon, Info as InfoIcon} from "@mui/icons-material";
import '../css/Dashboard.css'
import DatePicker from "react-datepicker";
import {useAuthContext} from "../contexts/AuthenticationContext";
import ActivityService from "../services/activity.service";
import ApplicationService from "../services/application.service";
import ProfileService from "../services/profile.service";
import DashboardGeneral from "./dashboards/DashboardGeneral";
import {useSSR, useTranslation} from "react-i18next";
import DashboardTimeLine from "./dashboards/DashboardTimeLine";
import DashboardProductivity from "./dashboards/DashboardProductivity";
import SiteService from "../services/site.service";
import {exportDataExcel, processArrayTimeline} from "../util/ActivitiesProcessorUtil";
import {cutValueFormated, dateToDateLocale} from "../util/GlobalFunctions";
import SummaryActivityService from "../services/summaryActivity.service";
import CustomDatePickerInput from "./custom/CustomDatePickerInput";
import UserService from "../services/user.service";
import { TrueffortButton, TrueffortNavButton, TrueffortSearchInput } from "../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "./custom/CustomIconTrueffortScreen";
import { confirmAlert } from "react-confirm-alert";
import InstanceService from "../services/instance.service";
import { MIN_LEVEL_ADMIN } from "../util/Constants";
import { ExcelColumn, ExcelSheet, ExcelFile } from "../util/ExportExcel";
import { useEffect, useState, useMemo } from "react";

const TeamDashboardTRUE = ({setLastUserActivity, allProjects, allUsers, 
    allProfiles, listCategories, setRefreshCategory, report, fillAllProfiles, listCategoriesSites, setRefreshCategorySite}) => {
  const {user} = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const [listProfiles, setListProfiles] = useState([]);
  let date_ = new Date();
  const [startDate, setStartDate] = useState(date_);
  const [endDate, setEndDate] = useState(date_);
  const [minDate, setMinDate] = useState(new Date().setFullYear(date_.getFullYear() - 1));
  const [inputSearch, setInputSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [listAllActivities, setListAllActivities] = useState([]);
  const [listActivitiesOnlyBrowsers, setListActivitiesOnlyBrowsers] = useState([]);
  const [activitiesTimeline, setActivitiesTimeline] = useState([]);
  const [schedulesByUser, setSchedulesByUser] = useState([]);
  const [nameSelectedDays, setNameSelectedDays] = useState(t("dashboard.today"));
  const [idSelectedProject, setIdSelectedProject] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [idSelectedProfile, setIdSelectedProfile] = useState("");
  const [selectedProfile, setSelectedProfile] = useState({});
  const [idSelectedCategory, setIdSelectedCategory] = useState("");
  const [nameSelectedCategory, setNameSelectedCategory] = useState("");
  const [idSelectedCategoryNonRelevant, setIdSelectedCategoryNonRelevant] = useState("");
  const [nameSelectedCategoryNonRelevant, setNameSelectedCategoryNonRelevant] = useState("");
  const [idSelectedUser, setIdSelectedUser] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userFromTrueDesktop, setUserFromTrueDesktop] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const summaryActivityService = useMemo(() => new SummaryActivityService(), []);
  const instanceService = useMemo(() => new InstanceService(), []);
  const applicationService = useMemo(() => new ApplicationService(), []);
  const profileService = useMemo(() => new ProfileService(), []);
  const siteService = useMemo(() => new SiteService(), []);
  const userService = useMemo(() => new UserService(), []);
  const [listSites, setListSites] = useState([]);
  const [users, setUsers] = useState(allUsers);
  const [objectUsers, setObjectUsers] = useState({});
  const [anchorElemFilter, setAnchorElemFilter] = useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = useState(0);
  const [showProductivityRange, setShowProductivityRange] = useState(false);
  const [range, setRange] = useState([user.instance?.lowerProductivity, user.instance?.upperProductivity]);
  const [refreshPage, setRefreshPage] = useState(0);

  useEffect(() => {
    //console.log("change allUsers");
    let obj = {};
    allUsers.forEach(u => { obj[u.idUser] = { name: u.name, lastName: u.lastName, profiles: u.profiles, 
      relevantSites: u.relevantSites.filter((s) => s.isRelevant).map((s) => s.idSite), notRelevantSites: u.relevantSites.filter((s) => !s.isRelevant).map((s) => s.idSite) } });
    setObjectUsers(obj);
    setUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    setShowLoader(true);
    const query = new URLSearchParams(window.location.search);
    const u = query.get('u');
    setUserFromTrueDesktop(u);
    fillSites();
    instanceService.get(user.instance?.idInstance).then(({ data: response }) => {
      if (response.success) {
        setRange([response.data.lowerProductivity, response.data.upperProductivity]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoader(true);
    setShowProductivityRange(false);
    setIdSelectedUser("");
    setSelectedUsers([]);
    instanceService.get(user.instance?.idInstance).then(({ data: response }) => {
      if (response.success) {
        setRange([response.data.lowerProductivity, response.data.upperProductivity]);
      }
    });
  }, [report]);

  // Llenar el arreglo de datos completos, llamando al servicio
  useEffect(() => {
    const fillSchedules = async () => {
      let schedules = [];
      allUsers.forEach(us => {
        if (us.schedule != null) {
          let days = [];
          us.schedule.days.forEach(dat => {
            let hInit = new Date();
            hInit.setSeconds(0);
            hInit.setHours(dat.start.split(":")[0]);
            hInit.setMinutes(dat.start.split(":")[1]);
            let hFin = new Date();
            hFin.setSeconds(0);
            hFin.setHours(dat.end.split(":")[0]);
            hFin.setMinutes(dat.end.split(":")[1]);
            let seconds = (hFin - hInit) / 1000;
            dat['hInit'] = hInit;
            dat['hFin'] = hFin;
            dat['seconds'] = seconds;
            days.push(dat);
          });
          us.schedule.days = days;
          let ind = schedules.findIndex(s => s.idSchedule == us.schedule.idSchedule);
          let usNew = JSON.parse(JSON.stringify(us));
          usNew.schedule = null;
          if (ind != -1) { 
            schedules[ind].users.push(usNew);
          } else { 
            us.schedule.users.push(usNew);
            schedules.push(us.schedule); 
          }
        }
      });
      setSchedulesByUser(schedules);
    }

    setShowLoader(true);
    //setShowLoaderBrowser(true);
    if (report === "timeline" || report === "productivity") {
      fillSchedules().catch(error => console.log(error));
    }/* else {
      fillActivitiesBrowsersFromService();
    }*/
    fillActivitiesFromService();

    let today = new Date();
    let end = new Date(endDate);
    today.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    if (today.getTime() == end.getTime()) {
      /*let idBrowser;
      if (report == 'general') {
        idBrowser = setInterval(() => fillActivitiesBrowsersFromService(), 60000);
      }*/
      const id = setInterval(() => fillActivitiesFromService(), 30000);
      return () => {
        //if (report == 'general') {clearInterval(idBrowser);}
        clearInterval(id);
      }
    }
  }, [startDate, endDate, idSelectedProject, report]);

  useEffect(() => {    
    let count = endDate.getDate() - startDate.getDate();
    switch(count) {
      case 0:
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 5:
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 15:
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 30:
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
      default:
        setNameSelectedDays(t("dashboard.customized"));
        break;
    }
  }, [startDate, endDate]);

  const fillProfiles = () => {
    console.log("fillProfiles")
    profileService.find().then(({data: response}) => {
      console.log(response);
      //if (userTeam.idTeam == 0) {
        setListProfiles(response);
      //}
    });
  }

  const fillSites = () => {
    siteService.findAll().then(({data: response}) => {
      if (response.success) {
        let arr = response.data;
        arr.sort((a, b) => a.name > b.name ? 1 : -1);
        setListSites(arr);
      }
    });
  }

  const fillUsers = () => {
    userService.getAllComplete().then(({data:response}) => {
      if (response.length == 1) {
        setIdSelectedUser(response[0].idUser);
        setSelectedUser(response[0]);
      } else {
        setUsers(response);
      }
    });
  }

  useEffect(() => {
    fillUsers();
  }, [refreshPage])

  const fillActivitiesFromService = async () => {
    //console.log("fillActivitiesFromService");
    //setShowLoader(activities.length == 0);
    let start = dateToDateLocale(startDate).toISOString();
    let end = dateToDateLocale(endDate).toISOString();
    let idUser = ""; // Para que obtenga la info de todos los usuarios, depués se filtra en la función que procesa
    let idProfile = ""; // Para que obtenga la info de todos los profiles, depués se filtra en la función que procesa

    // Obtener fecha actual
    let currentDate = dateToDateLocale(new Date()).toISOString();
    summaryActivityService.find(start.slice(0, 10), end.slice(0, 10), currentDate.slice(0, 10), idUser, idProfile, idSelectedProject, report).then(({data: response}) => {
      if (response.success) {
        if (report === "general") {
          setListAllActivities(response.data.applications);
          setListActivitiesOnlyBrowsers(response.data.sites);
        }
        if (report === "timeline" || report === "productivity") {
          setListAllActivities(response.data.applications);
          setActivitiesTimeline(response.data.applications);
          setListActivitiesOnlyBrowsers(response.data.sites);
        }
      }
      setShowLoader(false);
    }).catch(({data: response}) => {
        console.error(response);
    }).finally(() => setShowLoader(false));

    // CONSULTAR LA ÚLTIMA ACTIVIDAD DEL USUARIO
    userService.getLastActivity().then(({data: response}) => {
      setLastUserActivity(response);
    }).catch(({data: response}) => {
      console.error("Error getting last activity");
    });
  };

  const setNoRelevant = (id, act) => {
    //console.log("setNoRelevant: " + id);
    //console.log(act);
    if (act.relevantByProfile) {
      confirmAlert({
        title: <Typography color='#6A6A6A' variant='h6' fontWeight={700} marginBottom='20px' >
            <InfoIcon sx={{ color: '#6A6A6A' }} />&nbsp;&nbsp;{t("Warning")}
          </Typography>,
        message: t("dashboard.message_relevant_by_profile"),
        buttons: [
          {
            label: t("accept"),
            onClick: () => {}
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    } else {
      let idUser = idSelectedUser;
      if (idSelectedProfile != "") {  // DESASIGNAR DEL PERFIL
        applicationService.setNotRelevantProfile(id, idSelectedProfile).then(({data: response}) => {
        }).catch(({data: response}) => {
          console.error(response);
        });
      } else {    // DESASIGNAR DEL USUARIO
        applicationService.setNotRelevant(id, idUser).then(({data: response}) => {
        }).catch(({data: response}) => {
          console.error(response);
        });
      }
      fillUsers();
    }
  }

  const setRelevant = (id) => {
    //console.log("setRelevant: " + id);
    let idUser = idSelectedUser;
    applicationService.setRelevant(id, idUser).then(({data: response}) => {
      fillUsers();
    }).catch(({data: response}) => {
        console.log(response);
    });
  }

  const setProfileRelevant = () => {
    //console.log("setProfileRelevant");
    fillAllProfiles();
  }

  const handleChangeUser = (id) => {
    setAnchorElemFilter(null);
    setIdSelectedUser(id);
    setSelectedUser(users.find(us => us.idUser == id));
  }
    
  const handleChangeUsers = (id) => {
    //setIdSelectedUser(id);
    setAnchorElemFilter(null);
    let us = users.find(us => us.idUser == id);
    let aux = selectedUsers.map(u => u);
    aux.push(us);
    setSelectedUsers(aux);
  }

  const handleChangeProject = (id) => {
    //console.log("handleChangeProject");
    setAnchorElemFilter(null);
    setIdSelectedProject(id);
    setSelectedProject(id == "" || id == "-1" ? {} : allProjects.find(p => p.idProject == id));
    //setIdSelectedUser("");
  }

  const handleChangeProfile = (id) => {
    //console.log("handleChangeProfile");
    //console.log(id);
    setAnchorElemFilter(null);
    setIdSelectedProfile(id);
    setSelectedProfile(allProfiles.find(p => p.idProfile == id));
    setIdSelectedUser("");
    if (id == "") {
      setUsers(allUsers);
    } else {
      setUsers(allUsers.filter(u => u.profiles.find(p => (p.idProfile == id)) != undefined));
    }
  }

  const handleChangeCategory = (id, name) => {
    setIdSelectedCategory(id);
    setNameSelectedCategory(name);
  }

  const handleChangeCategoryNonRelevant = (id, name) => {
    setIdSelectedCategoryNonRelevant(id);
    setNameSelectedCategoryNonRelevant(name);
  }

  const handleChangeDays = (id) => {
    setAnchorElemFilter(null);
    let start = new Date();
    switch (id) {
      case 1:
        setStartDate(new Date());
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 2:
        start.setDate(start.getDate() - 5)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 3:
        start.setDate(start.getDate() - 15)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 4:
        start.setDate(start.getDate() - 30)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
    }
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const getButtonDownloadExcel = () => {
    return (
      <ExcelFile
        element={
          <TrueffortButton
            id="idBtnReportDownload"
            color='primary'
            variant='contained'
            fullWidth
          >
            {t("download_excel")}
          </TrueffortButton>
        }
        filename={"TruEffort"}
      >
        <ExcelSheet data={() => exportDataExcel(listAllActivities, report == "general" ? idSelectedUser != "" ? [idSelectedUser] : []: selectedUsers.map(u => u.idUser), 
          idSelectedProfile, listProfiles, users, inputSearch, report, idSelectedCategory, idSelectedCategoryNonRelevant,
          listCategories, selectedProject, [])} name="Datos">
          <ExcelColumn label={t("export_excel.start_date")} value="startDate"/>
          <ExcelColumn label={t("export_excel.project")}
            value={(col) => col.project == "0" ? t("export_excel.without_project") : col.project == "-1" ? t("export_excel.all_project") : col.project}/>
          <ExcelColumn label={t("export_excel.username")} value="username"/>
          <ExcelColumn label={t("export_excel.profile")} value="profile"/>
          <ExcelColumn label={t("export_excel.relevant_non_relevant")} value={(col) => col.isRelevant ? t("export_excel.is_relevant") : t("export_excel.is_non_relevant")}/>
          <ExcelColumn label={t("export_excel.category")} value={(col) => col.category == "0" ? t("export_excel.without_category") : col.category}/>
          <ExcelColumn label={t("export_excel.application")} value="application"/>
          <ExcelColumn label={t("export_excel.time_1")} value="time1"/>
        </ExcelSheet>
      </ExcelFile>
    );
  }

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const marksRange = [
    { value: 50, label: '50%'},
    { value: 120, label: '120%'}
  ];

  return (
    <Grid>
      <Grid container spacing={0.5} sx={{paddingTop: '0.5em'}} justifyContent='space-evenly' >
        <Grid item xl={3} md={(report == "productivity" || report == "timeline") ? 2 : 3} xs={12} >
          <TrueffortSearchInput
            id="idInputSearchDashboards"
            placeholder={t("search")}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Grid>
        <Grid item >
          <TrueffortNavButton
            id="idSelectedDays"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 1)}
            className={nameSelectedDays == t("dashboard.customized") ? "" : "active"}
            fullWidth
            //noneHeight={(report == "productivity" || report == "timeline") && nameSelectedDays.length > 5}
          >
            <CustomIconTrueffortScreen icon={<CalendarMonthIcon />} />
            {cutValueFormated(nameSelectedDays, 11)}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>
        <Grid item>
          <div id="idStartDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              minDate={minDate}
              maxDate={endDate}
              onChange={date => setStartDate(date)}
              customInput={<CustomDatePickerInput/>}
            />
          </div>
        </Grid>
        <Grid item>
          <div id="idEndDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              minDate={startDate}
              onChange={date => {
                let a = new Date(date);
                a.setFullYear(a.getFullYear() - 1);
                setMinDate(a);
                if (startDate.getTime() < a.getTime()) {
                  setStartDate(a);
                }
                setEndDate(date);
              }}
              maxDate={new Date()}
              customInput={<CustomDatePickerInput/>}
            />
          </div>
        </Grid>
        <Grid item >
          <TrueffortNavButton
            id="idSelectedProfile"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 2)}
            className={idSelectedProfile != "" ?"active" : ""}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<PersonIcon />} />
            {idSelectedProfile == "" ? t("Profile") : cutValueFormated(selectedProfile.name, 13)}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>
        <Grid item >
          {(report != "productivity" && report != "timeline") &&
            <TrueffortNavButton
              id="idSelectedUser"
              size="small"
              variant="contained"
              onClick={(event) => handleClickMenu(event, 3)}
              className={idSelectedUser != "" ? "active" : ""}
              noneHeight={false}
              fullWidth
            >
              <CustomIconTrueffortScreen icon={<GroupsIcon />} />
              {idSelectedUser != "" ? cutValueFormated(selectedUser.name + " " + selectedUser.lastName, 16) : t("dashboard.all_users")}
              <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
            </TrueffortNavButton>
          }
          {(report == "productivity" || report == "timeline") &&
            <TrueffortNavButton
              id="idSelectedUsers"
              size="small"
              variant="contained"
              onClick={(event) => handleClickMenu(event, 5)}
              className={selectedUsers.length != 0 ? "active" : ""}
              noneHeight={selectedUsers.length > 1 ? true: false}
            >
              <CustomIconTrueffortScreen icon={<GroupsIcon />} />
              {selectedUsers.length == 0 ? t("dashboard.all_users") : selectedUsers.map((u, i) => u.name + (i < (selectedUsers.length-1) ? ", " : ""))}
              <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
            </TrueffortNavButton>
          }
        </Grid>
        <Grid item >
          <TrueffortNavButton
            id="idSelectedProject"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 4)}
            className={idSelectedProject != "" ?"active" : ""}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<DashboardIcon />} />
            {idSelectedProject != "" ? idSelectedProject != "-1" ? cutValueFormated(selectedProject.name, 18): t("project_page.without_project") : t("dashboard.all_projects")}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>
      </Grid>
      <Menu id="idElementsFilterDashboards"
        anchorEl={anchorElemFilter}
        open={openOptions}
        onClose={() => setAnchorElemFilter(null)}
      >
        {menuNumber == 1 && <div>
          <MenuItem id="idDays_0" key={"_1"} onClick={() => handleChangeDays(1)}>
            {t("dashboard.today")}
          </MenuItem>
          <MenuItem id="idDays_1" key={"_5"} onClick={() => handleChangeDays(2)}>
            {t("dashboard.last_5_days")}
          </MenuItem>
          <MenuItem id="idDays_2" key={"_15"} onClick={() => handleChangeDays(3)}>
            {t("dashboard.last_15_days")}
          </MenuItem>
          <MenuItem id="idDays_3" key={"_30"} onClick={() => handleChangeDays(4)}>
            {t("dashboard.last_30_days")}
          </MenuItem>
        </div>}
        {menuNumber == 2 && <div>
          <MenuItem id="idProfile_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProfile("")}>
            {t("all")}
          </MenuItem>
          <Divider />
          {allProfiles.map((p, i) => (
            <MenuItem id={"idProfile_" + (i+1)} key={i} sx={{fontSize: ".8rem!important"}} 
              onClick={() => handleChangeProfile(p.idProfile)}>
              {p.name}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 3 && <div>
          <MenuItem id="idUser_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUser("")}>
            {t("dashboard.all_users")}
          </MenuItem>
          <Divider />
          {users.map((u, i) => (
            <MenuItem id={"idUser_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} 
              onClick={() => handleChangeUser(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 4 && <div>
          <MenuItem id="idProject_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject("")}>
            {t("dashboard.all_projects")}
          </MenuItem>
          <MenuItem id="idProject_N" key={-2} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject("-1")}>
            {t("project_page.without_project")}
          </MenuItem>
          <Divider />
          {allProjects.map((p, i) => (
            <MenuItem id={"idProject_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject(p.idProject)}>
              {p.name}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 5 && <div>
            <MenuItem id="idUsers_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => {
              setAnchorElemFilter(null);
              setSelectedUsers([]);
              }}>
                {t("dashboard.all_users")}
            </MenuItem>
            <Divider />
            {users.map((u, i) => (
                <MenuItem id={"idUsers_"+(i+1)} key={i} 
                disabled={selectedUsers.findIndex(us => us.idUser == u.idUser) != -1} 
                sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUsers(u.idUser)}>
                    {u.name + " " + u.lastName}
                </MenuItem>
            ))}
        </div>}
      </Menu>
      {(report == "productivity" || report == "timeline") && 
      <div style={{display: 'flex', flexDirection:'row', alignItems:'center', paddingLeft:'2em', paddingTop:'1em', height:'60px'}}>
        <span title={t("reports.benchmark.describe_IP")} style={{paddingRight:'3em'}}>{t("reports.benchmark.config_IP")}</span>
        <Box sx={{ width: 300 }}>
          <Slider
            value={range}
            onChange={handleChange}
            onChangeCommitted={(event) => {                            
              if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
                instanceService.updateProductivityRange(user.instance.idInstance, 
                  {lowerProductivity: range[0], upperProductivity: range[1]}
                ).then(({data: response}) => {
                }).catch(error => {
                  console.error("error", error);
                });
              }
            }}
            max={120}
            min={50}
            valueLabelFormat={(e) => e + "%"}
            sx={{color:'#9A64E2', marginBottom: 0}}
            marks={marksRange}
            disabled={user.role.accessLevel < MIN_LEVEL_ADMIN}
          />
        </Box>
        <span style={{ fontSize: "13px", paddingLeft: '1.5em' }}>
          {'[' + range[0] + '% - ' + range[1] + '%]'}
        </span>
      </div>}
      <div sx={{ padding: '8px', marginTop:'0.5em' }}>
        {showLoader && 
          <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
            <CircularProgress />
          </Stack>
        }
        {features.reportProductivityToolsUsed && !showLoader && report == "general" &&
          <DashboardGeneral
            startDate={startDate}
            endDate={endDate}
            idUser={idSelectedUser}
            idProfile={idSelectedProfile}
            idSelectedProject={idSelectedProject}
            handleDecideRelevant={setNoRelevant}
            listSites={listSites}
            listProfiles={allProfiles}
            users={users}
            listCategories={listCategories}
            setRefreshCategory={setRefreshCategory}
            listAllActivities={listAllActivities}
            listActivitiesOnlyBrowsers={listActivitiesOnlyBrowsers}
            setRelevant={setRelevant}
            inputSearch={inputSearch}
            showLoader={showLoader}
            handleChangeCategory={(id, name) => handleChangeCategory(id, name)}
            handleChangeCategoryNonRelevant={(id, name) => handleChangeCategoryNonRelevant(id, name)}
            componentDownloadExcel={features.exportData ? getButtonDownloadExcel() : null}
            handleProfileRelevant={setProfileRelevant}
            listCategoriesSites={listCategoriesSites}
            setRefreshCategorySite={setRefreshCategorySite}
            setRefreshPage={setRefreshPage}
          />
        }
        {features.productivityUsers && !showLoader && report == "productivity" &&
          <DashboardProductivity
            idSelectedProject={idSelectedProject}
            listAllActivities={listAllActivities}
            listActivitiesOnlyBrowsers={listActivitiesOnlyBrowsers}
            listSites={listSites}
            idUser={idSelectedUser}
            inputSearch={inputSearch}
            showLoader={showLoader}
            startDate={startDate}
            endDate={endDate}
            selectedUsers={selectedUsers}
            componentDownloadExcel={features.exportData ? getButtonDownloadExcel() : null}
            listCategories={listCategories}
            objectUsers={objectUsers}
            listProfiles={allProfiles}
            idProfile={idSelectedProfile}
            range={range}
            users={users}
            schedulesByUser={schedulesByUser}
            listCategoriesSites={listCategoriesSites}
          />
        }
        {features.reportTimeline && !showLoader && report == "timeline" && 
          <DashboardTimeLine
            listAllActivities={listAllActivities}
            listActivitiesOnlyBrowsers={listActivitiesOnlyBrowsers}
            listCategories={listCategories}
            listProfiles={allProfiles}
            objectUsers={objectUsers}
            schedulesByUser={schedulesByUser}
            inputSearch={inputSearch}
            idSelectedProject={idSelectedProject}
            showLoader={showLoader}
            startDate={startDate}
            endDate={endDate}
            users={users}
            selectedUsers={selectedUsers}
            componentDownloadExcel={features.exportData ? getButtonDownloadExcel() : null}
            showProductivityRange={showProductivityRange}
            range={range}
            idProfile={idSelectedProfile}
          />
        }
      </div>
    </Grid>
  );
}

export default TeamDashboardTRUE;