import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatSeconds } from "../util/GlobalFunctions";
import DashboardAppWindowsTRUE from "./DashboardAppWindowsTRUE";
import CustomAvatar from "./custom/CustomAvatar";

const DashboardCategoryAppTrue = ({id, category, startDate, endDate, idUser, idProfile, 
  idSelectedProject, totalSeconds, handleDecideRelevant, users, listProfiles, listCategories, setRefreshCategory,
  className, isLarge, handleProfileRelevant, makeRelevant, setRefreshPage, asRelevant}) => {
  const [showAppsList, setShowAppsList] = React.useState(false);
  let sizeAvatar = isLarge ? '36px' : '20px';
  let sizeName = isLarge ? '16px' : '11px';

  return (
    <div className={className} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%'}}>
      <div id={id} style={{width: '100%', display:'flex', flexDirection:'row', alignItems: 'center', cursor: 'pointer', height: isLarge ? '3em' : '1.2em'}} onClick={() => setShowAppsList(!showAppsList)}>
          <CustomAvatar size={sizeAvatar} src={category.image?.url} />
          <span className="name-app" style={{fontSize : sizeName}}>{category.categoryName}</span>
          <span className="time-app-wrapper" style={{background: '#F3F3F3'}}>
              <span className="time-app-category" style={{fontSize : sizeName, width: (category.totalSeconds * 100 /totalSeconds) + "%"}}><span> { formatSeconds(category.totalSeconds, false, false, true)} </span></span>
          </span>
          <FontAwesomeIcon className="caret-icon" icon={['fas', showAppsList ? 'caret-down' : 'caret-right']}/>
      </div>
      {showAppsList && category.applications.filter(app => app.totalSeconds > 0).map((app, ind) =>
        <div id={id+"_"+"idAppWindows_" +ind} style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'row', cursor:'pointer', background: 'var(--sand-50, #F5F5F5)', marginBottom: '0.2em'}}>
          <DashboardAppWindowsTRUE 
            id={id+"_"+"idApp_"+ind}
            key={app.application.idApplication} 
            activity={app}
            startDate={startDate} endDate={endDate}
            idUser={idUser}
            idProfile={idProfile}
            idSelectedProject={idSelectedProject}
            totalSeconds={category.totalSeconds}
            handleDecideRelevant={handleDecideRelevant}
            users={users}
            listProfiles={listProfiles}
            listCategories={listCategories}
            setRefreshCategory={setRefreshCategory}
            className={"div-app-windows-clean"}
            isLarge={isLarge}
            handleProfileRelevant={handleProfileRelevant}
            makeRelevant={makeRelevant}
            setRefreshPage={setRefreshPage}
            asRelevant={asRelevant}
          />
        </div>
      )}
    </div>
  )
};

export default DashboardCategoryAppTrue;