import * as React from "react";
import {formatSeconds} from "../../util/GlobalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DashboardAppSitesTRUE from "./DashboardAppSitesTRUE";
import CustomAvatar from "../custom/CustomAvatar";

const DashboardCategorySitesTRUE = ({id, catSite, idUser, idProfile, totalSeconds, listUsers, makeRelevant, 
  setRefreshPage, listProfiles, listCategories, setRefreshCategorySite, listAllActivities, className}) => {
  const [showWindowsList, setShowWindowsList] = React.useState(false);

  return(
    <div className={className} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%'}}>
      <div id={id} style={{width: '100%', display:'flex', flexDirection:'row', alignItems:'center', cursor:'pointer', height:'3em'}} onClick={() => setShowWindowsList(!showWindowsList)}>
        <CustomAvatar url={catSite.iconUrl} />
        <span className="name-app">{catSite.name}</span>
        <span className="time-app-wrapper" style={{background: '#F3F3F3'}}>
            <span className="time-app-category" style={{width: (catSite.totalSeconds * 100 /totalSeconds) + "%"}}><span> {formatSeconds(catSite.totalSeconds, false, false, true)} </span></span>
        </span>
        <FontAwesomeIcon className="caret-icon" icon={['fas', showWindowsList ? 'caret-down' : 'caret-right']}/>
      </div>
      {showWindowsList && catSite.sites.map((act,i) =>
        <div id={id + "_idSiteWindow_" + i} style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'row', cursor:'pointer', background: 'var(--sand-50, #F5F5F5)', marginBottom: '0.2em'}}>
          <DashboardAppSitesTRUE 
            id={id + "_idSite_"+i}
            key={act.idSite} 
            activity={act}
            idUser={idUser}
            idProfile={idProfile}
            totalSeconds={act.totalSeconds}
            listUsers={listUsers}
            makeRelevant={makeRelevant}
            setRefreshPage={setRefreshPage}
            listProfiles={listProfiles}
            listAllActivities={listAllActivities}
            className={"div-app-windows-clean"}
            listCategories={listCategories}
            setRefreshCategorySite={setRefreshCategorySite}
          />
        </div>
      )}
    </div>
  );
}

export default DashboardCategorySitesTRUE;
