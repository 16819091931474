import {CircularProgress, Container, Stack} from "@mui/material";
import SiteWrapper from "../SiteWrapper.react";
import '../css/Dashboard.css'
import {useAuthContext} from "../contexts/AuthenticationContext";
import "react-datepicker/dist/react-datepicker.css";
import TeamDashboardTRUE from "../components/TeamDashboardTRUE";
import {Team} from "../models/team";
import {useTranslation} from "react-i18next";
import UserService from "../services/user.service";
import ProfileService from "../services/profile.service";
import ProjectService from "../services/project.service";
import CategorysiteService from "../services/categorysite.service";
import BuyComplete from "../components/BuyComplete";
import { useHistory} from "react-router-dom";
import CategoryService from "../services/category.service";
import { MIN_LEVEL_LEADER } from "../util/Constants";
import { TrueffortButtonSmall } from "../MUIstyle/MUICustom";
import {PieChart as PieChartIcon, AccountBox as AccountBoxIcon, WatchLater as WatchLaterIcon} from "@mui/icons-material";
import { useEffect, useState, useMemo } from "react";

function Dashboard() {
  const {user} = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const history = useHistory();

  const userService = useMemo(() => new UserService(), []);
  const [users, setUsers] = useState([]);
  const profileService = useMemo(() => new ProfileService(), []);
  const [profiles, setProfiles] = useState([]);
  const projectService = useMemo(() => new ProjectService(), []);
  const [projects, setProjects] = useState([]);
  const [lastUserActivity, setLastUserActivity] = useState(null);
  const categoryService = useMemo(() => new CategoryService(), []);
  const categorySitesService = useMemo(() => new CategorysiteService(), []);
  const [categories, setCategories] = useState([]);
  const [listCategoriesSites, setListCategoriesSites] = useState([]);
  const [refreshCategorySite, setRefreshCategorySite] = useState(0);
  const [refreshCategory, setRefreshCategory] = useState(0);
  const [tabName, setTabName] = useState(features?.reportProductivityToolsUsed ? "general" : 
    features?.productivityUsers ? "productivity" : features?.reportTimeline ? "timeline" : "");

  useEffect(() => {
    if ((!user.instance.activeByPayment && user.instance.trial === null)
      || (!user.instance.activeByPayment && user.instance.trial !== null
      && user.instance.trial.isExpired && !user.instance.trial.hasBought)) {
      history.push("/payment");
    }
    fillProjects();
    fillUsers();
    fillProfiles();
    fillCategories();
    fillCategoriesSites();
    //console.log(ip());
  }, []);

  useEffect(() => {
    fillCategories();
  }, [refreshCategory]);

  useEffect(() => {
    fillCategoriesSites();
  }, [refreshCategorySite]);



  const fillUsers = () => {
    userService.getAllComplete().then(({data: response}) => {
      if (response != null && Array.isArray(response)) {
        setUsers(response);
      }
    });
  }

  useEffect(() => {
    fillUsers();
  }, [tabName])

  const fillProfiles = () => {
    //console.log("fillProfiles 2");
    profileService.find().then(({data: response}) => {
      //console.log(response);
      if (response.success) {
        setProfiles(response.data);
      }
    }).catch((ex) => {
      console.error(ex);
    });
  }

  const fillProjects = () => {
    projectService.getAll().then(({data: response}) => {
      if (response.data != null && Array.isArray(response.data)) {
        setProjects(response.data);
      }
    });
  }

  const fillCategories = () => {
    categoryService.find(user.instance.idInstance).then(({data: response}) => {
      if (response.data != null && Array.isArray(response.data)) {
        setCategories(response.data);
      }
    });
  }

  const fillCategoriesSites = () => {
    categorySitesService.findAll().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        setListCategoriesSites(response.data);
      }
    });
  }

  const remainingTrialDays = () => {
    let aux = new Date(user.instance.trial.endTime);
    let now = new Date();
    let diffTime = Math.abs(aux - now);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays
  };

  return (
    <SiteWrapper>
      <Container maxWidth='xl' sx={{ marginTop: '20px' }}>
        {((user.instance.trial !== null && user.instance.trial !== undefined) ? user.instance.trial.isExpired && !user.instance.trial.hasBought && !user.instance.activeByPayment : false) ?
          <div style={{marginTop: "1em"}}><BuyComplete trialPeriod={false}/></div>
          : ((user.instance.trial !== null && user.instance.trial) ? !user.instance.trial.isExpired && !user.instance.trial.hasBought : false) ?
          <BuyComplete trialPeriod={true} remainingDays={remainingTrialDays()}/> : null
        }
        <div className="title">
          <div className="title-small">{t("Productivity")}</div>
          <h2 id="idTitle">{tabName == "general" ? t("reports_page.general.title") : 
          tabName == "productivity" ? t("BoardTypes.Productivity") : t("BoardTypes.Timeline")}</h2>
        </div>
        <Stack direction={{ xs: 'column', sm:'row', md: 'row'}} spacing={1} >
          {features?.reportProductivityToolsUsed && <TrueffortButtonSmall 
            id="idTabGeneralReport"
            color="secondary"
            variant={tabName == "general" ? "contained" : "outlined"}
            onClick={() => setTabName("general")}
            startIcon={<PieChartIcon sx={{width: '0.9em'}}/>}
            style={{marginRight: "0.2em"}}>
            {t("BoardTypes.General")}
          </TrueffortButtonSmall>}
          {features?.productivityUsers && <TrueffortButtonSmall
            id="idTabProductivityReport" 
            color="secondary"
            variant={tabName == "productivity" ? "contained" : "outlined"}
            onClick={() => setTabName("productivity")}
            startIcon={<AccountBoxIcon sx={{width: '0.9em'}}/>}
            style={{marginRight: "0.2em"}}>
            {t("BoardTypes.Productivity")}
          </TrueffortButtonSmall>}
          {features?.reportTimeline && <TrueffortButtonSmall 
            id="idTabTimelineReport" 
            color="secondary"
            variant={tabName == "timeline" ? "contained" : "outlined"}
            onClick={() => setTabName("timeline")}
            startIcon={<WatchLaterIcon sx={{width: '0.9em'}}/>}
            >
            {t("BoardTypes.Timeline")}
          </TrueffortButtonSmall>}
        </Stack>
        {categories.length == 0 && user.role.accessLevel != MIN_LEVEL_LEADER  && users.length == 0 && 
          <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
            <CircularProgress />
          </Stack>
        }
        {((user.role.accessLevel == MIN_LEVEL_LEADER && users.length == 0) || users.length > 0) && categories.length > 0 &&
          <TeamDashboardTRUE
            allProjects={projects}
            allUsers={users}
            allProfiles={profiles}
            listCategories={categories}
            setRefreshCategory={setRefreshCategory}
            setLastUserActivity={setLastUserActivity}
            report={tabName}
            fillAllProfiles={fillProfiles}
            listCategoriesSites={listCategoriesSites}
            setRefreshCategorySite={setRefreshCategorySite}
          />
        }
      </Container>
    </SiteWrapper>
  );
}

export default Dashboard;
