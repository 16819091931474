import * as React from "react";
import {useState} from "react";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { useHistory, useLocation } from "react-router-dom";
import { Box, AppBar, Toolbar, Link, IconButton, Avatar, Menu, MenuItem, Divider,
    List, ListItem, ListItemButton, ListItemIcon, Tooltip, ListItemText, Drawer
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import {useAuthContext} from "./contexts/AuthenticationContext";
import {useTranslation} from "react-i18next";
import {
    download_options,
    ID_INSTANCE_TYPE_CORPORATE,
    PROJECT_ID_KEY,
    PROJECT_KEY,
    PROJECT_OBJ_KEY,
    ID_INSTANCE_SPINGERE,
    MIN_LEVEL_ADMIN,
    MIN_LEVEL_LEADER,
    USER_INFO_KEY,
    TIME_FORMAT
} from "./util/Constants";
import ApiRequest from "./helpers/ApiRequest";
import UserService from "./services/user.service";
import {User} from "./util/Interfaces";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationService from "./services/notification.service";
import InstanceService from "./services/instance.service";
import NotificationTRUE from "./components/NotificationTRUE";
//import ProjectService from "./services/project.service";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from "react-confirm-alert";
import {Settings as SettingsIcon, CloudDownload as CloudDownloadIcon, Language as LanguageIcon,
    Notifications as NotificationsIcon, ExpandMore as ExpandMoreICon, CheckBox as CheckBoxIcon,
    ChevronLeft as ChevronLeftIcon, Home as HomeIcon, Dashboard as DashboardIcon, PieChart as PieChartIcon,
    PieChartOutline as PieChartOutlineIcon, Person as PersonIcon, Menu as MenuIcon,
    Timer as TimerIcon, Assignment, FactCheck as FactCheckIcon, Api as ApiIcon} from '@mui/icons-material';
import { useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import HelpIcon from "@mui/icons-material/Help";
import YouTubeIcon from '@mui/icons-material/YouTube';
import {HelperDialog, TrueffortChip} from "./MUIstyle/MUICustom";
import {helperDialogs} from "./util/HelperDialogs";

type subNavItem = {
    value: string,
    to?: string,
    icon?: string,
    LinkComponent?: any,
    useExact?: boolean,
};

type navItem = {
    value: string,
    to?: string,
    icon?: string,
    active?: boolean,
    LinkComponent?: any,
    subItems?: Array<subNavItem>,
    useExact?: boolean,
};

const drawerWidth = 250;
const colors = ["blue", "azure", "indigo", "purple", "pink", "red", "orange", "yellow", "lime", "green", "teal", "cyan", "gray", "gray-dark"];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
) as any;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
})) as any;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{open?: boolean;}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: "20px",
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
})) as any;

const SiteWrapper = (props: any) => {

    const {
        user,
        logout,
        updateUserInfo
    } = useAuthContext() as { user: User, logout: Function, updateUserInfo: Function };
    const features = user?.instance?.license ? user?.instance?.license?.features : {};
    const {t, i18n} = useTranslation();
    const history = useHistory();

    const theme: Theme = useTheme();
    const matches: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXS: boolean = useMediaQuery(theme.breakpoints.only('xs'));

    const [notifications, setNotifications] = React.useState([]);
    const [notificationsSeen, setNotificationsSeen] = React.useState(true);
    const [viewsTypes, setViewsTypes] = React.useState([]);
    const userService = React.useMemo(() => new UserService(), []);
    const notificationService = React.useMemo(() => new NotificationService(), []);
    const instanceService = React.useMemo(() => new InstanceService(), []);
    //const projectService = React.useMemo(() => new ProjectService(), []);
    //const [projects, setProjects] = React.useState([]);
    //const [projectsLoading, setProjectsLoading] = React.useState(true);
    const [loading, setLoading] = useState(false);
    const [instanceAux, setInstanceAux] = React.useState(user.instance);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);
    const [menuNumber, setMenuNumber] = React.useState(0);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [temporizador, setTemporizador] = React.useState(10);
    //const [messageLogout, setMessageLogout] = React.useState("");
    const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);
    const intervalRef = useRef();

    React.useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        window.addEventListener("load", load);
        window.addEventListener("unload", unload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
            window.removeEventListener("load", load);
            window.removeEventListener("unload", unload);
        };
    });

    const load = (ev:any) => {
        let unloadTime = localStorage.getItem("unLoad");
        if (unloadTime) {
            let unloadTimeNumber = Number(unloadTime);
            let now = new Date().getTime();
            let diff = now - unloadTimeNumber;
            //localStorage.setItem("diff", diff.toString());
            if (diff > Number(user.sessionDuration)) {
                logout();
            }
        }
        localStorage.setItem("load", new Date().getTime().toString());
    }

    const unload = (ev:any) => {
        localStorage.setItem("unLoad", new Date().getTime().toString());
    }


    const beforeunload = (ev:any) => {
        localStorage.setItem("beforeunload", new Date().getTime().toString());
    }

    React.useEffect(() => {
        fillViewsTypes();
        if(features.notificationsWeb) {fillNotifications();}
        fillInstance();
        // Consultar notificaciones cada 5 minutos
        const id = features.notificationsWeb ? setInterval(() => fillNotifications(), 300000) 
          : setInterval(() => {}, 12000000);
        const idInstanceInterval = setInterval(() => fillInstance(), 300000);
        intervalRef.current = getIntervalAutomaticClose();
        //intervalRef.idVerifySession = setInterval(() => verifySession(), 2000)
        return () => {
            clearInterval(idInstanceInterval);
            if(features.notificationsWeb) { clearInterval(id); }
            clearInterval(intervalRef.current);
            //clearInterval(intervalRef.idVerifySession);
        };
    }, []);

    /*const verifySession = () => {
        let usJ = JSON.parse(localStorage.getItem(USER_INFO_KEY)+"");
        if (usJ == undefined || usJ?.token == "") {
            clearInterval(intervalRef.idVerifySession);
            confirmAlert({
                message: "Sesión invalida, vuelva a iniciar sesión.",
                buttons: [
                    {
                        label: t("Aceptar"),
                        onClick: () => logout()
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
    }*/

    const getIntervalAutomaticClose = () => {
        const idClose = setInterval(() => automaticClose(), parseInt(user.sessionDuration));
        return idClose;
    }

    const automaticClose = () => {
        //console.log("logout");
        //console.log(intervalRef.current);
        clearInterval(intervalRef.current);
        let idC = setTimeout(() => logout(), 10000);
        //let idTO = setInterval(() => setTemporizador(temporizador-1),1000);
        confirmAlert({
            title: t("sign_out"),
            message: t("msg.warning.sign_out_automatic").replaceAll("{0}", temporizador),
            buttons: [
                {
                    label: t("elements.buttons.accept"),
                    onClick: () => {
                        clearTimeout(idC);
                        intervalRef.current = getIntervalAutomaticClose();
                        //clearInterval(idTO);
                        setTemporizador(10);
                    }
                },
                {
                    label: t("sign_out"),
                    onClick: () => {
                        logout();
                        //clearInterval(idTO);
                    }
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
        //console.log(idC);
    }

    const fillInstance = () => {
        instanceService.get(instanceAux.idInstance).then((response: any) => {
            if (response.data.success) {
                setInstanceAux(response.data.data);
                localStorage.setItem("INSTANCE_INFO", JSON.stringify(response.data.data));
            }
        }).catch((e: any) => console.log(e));
    }

    const fillNotifications = () => {
        setNotificationsSeen(true);
        let arr: any[] = [];
        // @ts-ignore
        notificationService.findLast().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                response.data.map((not: any, k: number) => {
                    let x = not;
                    if (!not.seen) {
                        setNotificationsSeen(false);
                    }
                    try {
                        x["image"] = "";
                        x["paramsJson"] = JSON.parse(not.params);
                    } catch (ex) {
                        x["paramsJson"] = "";
                    }
                    arr.push(x);
                });
                setNotifications(arr);
            }
        }).catch(() => {
            console.error("Error al consultar notificaciones");
        });
    }

    const fillViewsTypes = () => {
        let array: { iconName: string, name: string; idView: any; }[] = [];
        array.push({iconName: "chart-pie", name: t("BoardTypes.General"), idView: 0});
        array.push({iconName: "user", name: t("BoardTypes.Productivity"), idView: 1});
        array.push({iconName: "clock", name: t("BoardTypes.Timeline"), idView: 2});
        setViewsTypes(array);
    }

    const handleLanguageChange = React.useCallback((newLang: string) => {
        if (user.langCode !== newLang) {
            setLoading(true);
            ApiRequest(userService.updateLangCode(user.idUser, newLang)).then((response) => {
                if (response) {
                    i18n.changeLanguage(newLang).then(() => {
                        updateUserInfo({langCode: newLang})
                        setLoading(false);
                    })
                }
            }).catch((err) => console.log("Error #ChangingLangage " + newLang + " #Error " + JSON.stringify(err)));
        }
    }, [i18n, updateUserInfo, user.idUser, userService]);

    const handleTimeFormatChange = React.useCallback((newTimeFormat:string) => {
        if (user.timeFormat !== newTimeFormat) {
            setLoading(true);
            ApiRequest(userService.updateTimeFormat(newTimeFormat)).then((response) => {
                if (response) {
                    updateUserInfo({timeFormat: newTimeFormat})
                    setLoading(false);
                    localStorage.setItem(TIME_FORMAT, newTimeFormat);
                    /*i18n.changeLanguage(newLang).then(() => {
                        updateUserInfo({langCode: newLang})
                        setLoading(false);
                    })*/
                }
            }).catch((err) => console.log("Error #ChangingTimeFormat " + newTimeFormat + " #Error " + JSON.stringify(err)));
        }
    });

    /*const handleChangeDashboard = (id: any) => {
        updateInstance();
        
    }*/

    const handleMarkAsReadAll = () => {
        notificationService.markAsReadAllByIdUser().then((response:any) => {
            fillNotifications();
        });
    }

    const handleClickMenu = (event:any, value:number) => {
        setAnchorEl(event.currentTarget);
        setMenuNumber(value);
    }

    const handleOpenHelp = () => {
        setActiveHelperDialogIndex(0);
    }

    const handleClose = () => {
        setActiveHelperDialogIndex(-1);
    };

    const handleActiveHelperChange = () => {
        if (activeHelperDialogIndex === 9 && user.role.accessLevel == MIN_LEVEL_LEADER) {
            setActiveHelperDialogIndex((prev: number) => prev + 2)
        }else if (activeHelperDialogIndex === helperDialogs['siteWrapper'].length) {
            setActiveHelperDialogIndex(-1)
        } else {
            setActiveHelperDialogIndex((prev: number) => prev + 1)
        }
    }

    return (
        <React.Fragment>
            <AppBar position="static" sx={{background: "white", color: "#07074E", marginBottom: "1rem", boxShadow: "none", zIndex:"99999", width: matchesXS ? '100%' : `calc(100% - ${64}px)`, ml: matchesXS ? 0 : `${64}px`}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpenDrawer(true)}
                        sx={{ display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Link href="/home" sx={{ flexGrow: 1 }}>
                        <img src='./assets/images/new_logo.png' style={{width: "9.375rem"}} alt="TruEffort" />
                    </Link>
                    {!matchesXS &&
                        <HelperDialog
                            title={t(helperDialogs["siteWrapper"][1].title)}
                            body={t(helperDialogs["siteWrapper"][1].body)}
                            dialogIndex={1}
                            totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                helperDialogs["siteWrapper"].length :
                                user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                    helperDialogs["siteWrapper"].length - 1 :
                                    9
                            }
                            handleNext={handleActiveHelperChange}
                            isOpen={activeHelperDialogIndex === 1}
                            handleClose={handleClose}
                        >
                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][0].title)}
                                body={t(helperDialogs["siteWrapper"][0].body)}
                                dialogIndex={0}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                        helperDialogs["siteWrapper"].length - 1 :
                                        9
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 0}
                                handleClose={handleClose}
                            >
                                <IconButton onClick={handleOpenHelp} >
                                    <HelpIcon sx={{ color: '#6875C7' }} />
                                </IconButton>
                            </HelperDialog>

                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][2].title)}
                                body={t(helperDialogs["siteWrapper"][2].body)}
                                dialogIndex={2}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                        helperDialogs["siteWrapper"].length - 1 :
                                        9
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 2}
                                handleClose={handleClose}
                            >
                                <IconButton onClick={(event:any) => handleClickMenu(event, 1)}>
                                    <SettingsIcon className="color-black950" />
                                    <ExpandMoreICon className="color-black950" />
                                </IconButton>
                            </HelperDialog>

                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][3].title)}
                                body={t(helperDialogs["siteWrapper"][3].body)}
                                dialogIndex={3}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                        helperDialogs["siteWrapper"].length - 1 :
                                        9
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 3}
                                handleClose={handleClose}
                            >
                                <IconButton onClick={(event:any) => handleClickMenu(event, 2)}>
                                    <CloudDownloadIcon className="color-black950" />
                                    <ExpandMoreICon className="color-black950" />
                                </IconButton>
                            </HelperDialog>

                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][4].title)}
                                body={t(helperDialogs["siteWrapper"][4].body)}
                                dialogIndex={4}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                        helperDialogs["siteWrapper"].length - 1 :
                                        9
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 4}
                                handleClose={handleClose}
                            >
                                <IconButton onClick={(event:any) => handleClickMenu(event, 3)}>
                                    <LanguageIcon className="color-black950" />
                                    <ExpandMoreICon className="color-black950" />
                                </IconButton>
                            </HelperDialog>

                            <IconButton onClick={(event:any) => handleClickMenu(event, 6)}>
                                <TimerIcon className="color-black950" />
                                <ExpandMoreICon className="color-black950" />
                            </IconButton>

                            <TrueffortChip
                                icon={<HelpIcon/>}
                                color="primary"
                                size="small"
                                label={t("help_center")}
                                onClick={(event: any) => handleClickMenu(event, 7)}
                            />
                        </HelperDialog>
                    }
                    {/*</Box>*/}

                    {features.notificationsWeb && <HelperDialog
                        title={t(helperDialogs["siteWrapper"][5].title)}
                        body={t(helperDialogs["siteWrapper"][5].body)}
                        dialogIndex={5}
                        totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                            helperDialogs["siteWrapper"].length :
                            user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                helperDialogs["siteWrapper"].length - 1 :
                                9
                        }
                        handleNext={handleActiveHelperChange}
                        isOpen={activeHelperDialogIndex === 5}
                        handleClose={handleClose}
                    >
                      <IconButton onClick={(event:any) => handleClickMenu(event, 4)}>
                        <NotificationsIcon className="color-black950" />
                      </IconButton>
                    </HelperDialog>}

                    <HelperDialog
                        title={t(helperDialogs["siteWrapper"][6].title)}
                        body={t(helperDialogs["siteWrapper"][6].body)}
                        dialogIndex={6}
                        totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                            helperDialogs["siteWrapper"].length :
                            user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                helperDialogs["siteWrapper"].length - 1 :
                                9
                        }
                        handleNext={handleActiveHelperChange}
                        isOpen={activeHelperDialogIndex === 6}
                        handleClose={handleClose}
                    >
                      <Box className="btn-avatar-profile" onClick={(event:any) => handleClickMenu(event, 5)}>
                        <Avatar alt={user.name} src={user.image?.url} sx={{ width: 35, height: 35, marginLeft: ".6rem" }} />
                      </Box>
                    </HelperDialog>
                    <Box sx={{paddingLeft: ".6rem", fontSize: "0.875rem"}} className="btn-avatar-profile color-sand600" onClick={(event:any) => handleClickMenu(event, 5)}>
                      <Box sx={{fontWeight: "bold"}}>{user.name}</Box>
                      <Box>{t("role."+user.role.code)}</Box>
                    </Box>

                    <Menu
                        id="menu-settings"
                        anchorEl={anchorEl}
                        open={openOptions}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {menuNumber === 1 &&
                            <div>
                            {user.instance.idInstance == ID_INSTANCE_SPINGERE && user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                <MenuItem onClick={() => history.push("/instances")}>{t("Instances")}</MenuItem> : null
                            }
                            {user.instance.idInstance == ID_INSTANCE_SPINGERE && user.role.accessLevel >= MIN_LEVEL_ADMIN && 
                              <MenuItem onClick={() => history.push("/licenses")}>{t("Licenses")}</MenuItem>
                            }
                            {/*{user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                                <MenuItem onClick={() => history.push("/projectTypes")}>{t("Projects.project_templates")}</MenuItem>    
                            }*/}
                            {user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                <MenuItem onClick={() => history.push("/configurationCategory")}>{t("config_page.title")}</MenuItem> : null
                            }
                            {user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                <MenuItem onClick={() => history.push("/catalogs")}>{t("catalogs_page.title")}</MenuItem> : null
                            }
                            {features.logTrueffort && user.instance.instanceType.idInstanceType == ID_INSTANCE_TYPE_CORPORATE &&
                                user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                <MenuItem onClick={() => history.push("/logbook")}>{t("logbook_page.title")}</MenuItem> : null
                            }
                          </div>
                        }
                        {menuNumber === 2 &&
                            <div>
                            {download_options(t).map((op, k) => (
                              <MenuItem key={k} onClick={op.onClick}>
                                  <Avatar height={24} size={"sm"}
                                    sx={{ width: 28, height: 28, marginRight: ".4rem"}}
                                    src={"./assets/images/" + op.icon} />
                                  {op.value}
                              </MenuItem>
                            ))}
                            <Divider />
                            <div style={{padding:'0 0.4em'}}>{"* Activa X11 para soporte completo en linux"}</div>
                            </div>
                        }
                        {menuNumber === 3 &&
                            <div>
                            <MenuItem key="es" onClick={loading === true ? null : () => handleLanguageChange("es")}>
                                {t("Spanish")} {i18n.language === "es" ? <CheckBoxIcon /> : null}
                            </MenuItem>
                            <MenuItem key="en" checked onClick={loading === true ? null : () => handleLanguageChange("en")}>
                                {t("English")} {i18n.language === "en" ? <CheckBoxIcon /> : null}
                            </MenuItem>
                            </div>
                        }
                        {menuNumber === 4 &&
                            <div>
                                {notifications.length > 0 ?
                                    <MenuItem key={-1} onClick={handleMarkAsReadAll}>
                                        {t("notification.clean_notification")}
                                    </MenuItem> :
                                    <MenuItem key={-1} >
                                        {t("notification.not_exists")}
                                    </MenuItem>
                                }
                                {notifications.map((not: any, k: number) => (
                                    <MenuItem key={k} className={k % 2 ? "font-size-global": "font-size-global odd"}>
                                        <NotificationTRUE notification={not} fillNotifications={fillNotifications}/>
                                    </MenuItem>
                                ))}
                            </div>
                        }
                        {menuNumber === 5 &&
                            <div>
                                <MenuItem onClick={() => history.push("profile")}>
                                    {t("user_page.profile")}
                                </MenuItem>
                                <MenuItem onClick={() => history.push("forgot-password")}>
                                    {t("change_password")}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => window.location.href = 'https://trueffort.com/guia'}>
                                    {t("need_help")}
                                </MenuItem>
                                <MenuItem onClick={() => logout()}>
                                    {t("sign_out")}
                                </MenuItem>
                            </div>
                        }
                        {menuNumber === 6 &&
                            <div>
                            <MenuItem key="dec" onClick={loading === true ? null : () => handleTimeFormatChange("dec")}>
                                {t("timeFormat.dec") + (" (H.m)")} {user.timeFormat === "dec" ? <CheckBoxIcon /> : null}
                            </MenuItem>
                            <MenuItem key="hrs" checked onClick={loading === true ? null : () => handleTimeFormatChange("hrs")}>
                                {t("timeFormat.hrs") + (" (HH:mm)")} {user.timeFormat === "hrs" ? <CheckBoxIcon /> : null}
                            </MenuItem>
                            </div>
                        }
                        {menuNumber === 7 &&
                            <div>
                                <MenuItem key="videotutorials" onClick={() => window.open("https://youtube.com/@trueffort-tool?si=oPjAsO0zGH-lR832")} >
                                    <YouTubeIcon/>&nbsp; {t("tutorials")}
                                </MenuItem>
                            </div>
                        }
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                id='mobile-drawer'
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open={openDrawer}
            >
                <Box sx={{paddingTop: "10px", paddingBottom: "20px", marginLeft: "10px"}}>
                    <IconButton onClick={() => setOpenDrawer(false)} sx={{...(!openDrawer && { display: 'none' }) }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>

                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => history.push("home")}>
                            <ListItemIcon>
                                <Tooltip title={t("Projects.global_view")} placement="right">
                                    <HomeIcon color='primary' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("Projects.global_view")} sx={{ opacity: openDrawer ? 1 : 0}}
                                          primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                            />
                        </ListItemButton>
                    </ListItem>
                    {(features.reportProductivityToolsUsed || features.productivityUsers || features.timeline) 
                       &&
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => history.push("dashboard")}>
                                <ListItemIcon>
                                    <Tooltip title={t("BoardTypes.General")} placement="right">
                                        <PieChartIcon color='primary' />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("BoardTypes.General")} sx={{ opacity: openDrawer ? 1 : 0}}
                                              primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                />
                            </ListItemButton>
                        </ListItem>
                    }
                    {(features.userProductivityReportItemsPerformed || features.worksheetsUsers ||
                          features.benchmark || features.globalReportProjectsStatus || 
                          features.reportProjectsEvaluation) && <ListItem disablePadding>
                        <ListItemButton onClick={() => history.push("globalReport")}>
                            <ListItemIcon>
                                <Tooltip title={t("Reports")} placement="right">
                                    <Assignment color='primary' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("Reports")} sx={{ opacity: openDrawer ? 1 : 0}}
                                            primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                            />
                        </ListItemButton>
                    </ListItem>}
                    {features.estimationProject && user.role.accessLevel >= MIN_LEVEL_LEADER &&
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => history.push("estimation")}>
                                <ListItemIcon>
                                    <Tooltip title={t("project_tasks_page.estimate")} placement="right">
                                        <PieChartOutlineIcon color='primary' />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("project_tasks_page.estimate")} sx={{ opacity: openDrawer ? 1 : 0}}
                                              primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                />
                            </ListItemButton>
                        </ListItem>
                    }
                    {user.role.accessLevel >= MIN_LEVEL_ADMIN && <ListItem disablePadding>
                        <ListItemButton onClick={() => history.push("projectTypes")}>
                            <ListItemIcon>
                                <Tooltip title={t("project_page.templates")} placement="right">
                                    <DashboardIcon color='primary' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("project_page.templates")} sx={{ opacity: openDrawer ? 1 : 0}}
                                          primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                            />
                        </ListItemButton>
                    </ListItem>}

                    {(features.users || features.manageWorkSchedules) && user.role.accessLevel >= MIN_LEVEL_LEADER &&
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => history.push("/configurationUsers")}>
                                <ListItemIcon>
                                    <Tooltip title={t("Users.title")} placement="right">
                                        <PersonIcon color='primary' />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Users.title")} sx={{ opacity: openDrawer ? 1 : 0}}
                                              primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                />
                            </ListItemButton>
                        </ListItem>
                    }

                    {(features.dailyProjectTracking || features.dailyProjectTrackingDragAndDrop) && 
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => history.push("/daily")}>
                            <ListItemIcon>
                                <Tooltip title={t("daily_page.title")} placement="right">
                                    <FactCheckIcon color='primary' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("daily_page.title")} sx={{ opacity: openDrawer ? 1 : 0}}
                                          primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                            />
                        </ListItemButton>
                    </ListItem>}
                    {features.interoperabilityAll && user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => history.push("/interoperability")}>
                                <ListItemIcon>
                                    <Tooltip title={t("Herramienta.interoperability")} placement="right">
                                        <ApiIcon color='primary' />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Herramienta.interoperability")} sx={{ opacity: openDrawer ? 1 : 0}}
                                              primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                />
                            </ListItemButton>
                        </ListItem>
                    }
                    <ListItem disablePadding >
                        <ListItemButton onClick={(event: any) => handleClickMenu(event, 7)} >
                            <ListItemIcon>
                                <Tooltip title={t("tutorials")} placement="right" >
                                    <HelpIcon color='primary' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("help_center")} sx={{ opacity: openDrawer ? 1 : 0}}
                                          primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>

                <Divider />

                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={(event:any) => handleClickMenu(event, 1)}>
                            <ListItemIcon>
                                <SettingsIcon className="color-black950" />
                                <ExpandMoreICon className="color-black950" />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={(event:any) => handleClickMenu(event, 2)}>
                            <ListItemIcon>
                                <CloudDownloadIcon className="color-black950" />
                                <ExpandMoreICon className="color-black950" />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={(event:any) => handleClickMenu(event, 3)}>
                            <ListItemIcon>
                                <LanguageIcon className="color-black950" />
                                <ExpandMoreICon className="color-black950" />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={(event:any) => handleClickMenu(event, 6)}>
                            <ListItemIcon>
                                <TimerIcon className="color-black950" />
                                <ExpandMoreICon className="color-black950" />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <HelperDialog
                title={t(helperDialogs["siteWrapper"][7].title)}
                body={t(helperDialogs["siteWrapper"][7].body)}
                dialogIndex={7}
                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                    helperDialogs["siteWrapper"].length :
                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                        helperDialogs["siteWrapper"].length - 1 :
                        9
                }
                handleNext={handleActiveHelperChange}
                isOpen={activeHelperDialogIndex === 7}
                handleClose={handleClose}
                position='right'
            >
                <CustomDrawer
                    variant="permanent"
                    open={openDrawer}
                    id="menu-drawer"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                    <Box sx={{paddingTop: "10px", paddingBottom: "20px", marginLeft: "10px"}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpenDrawer(true)}
                            sx={{...(openDrawer && { display: 'none' })}}
                          >
                            <MenuIcon />
                          </IconButton>
                        <IconButton onClick={() => setOpenDrawer(false)} sx={{...(!openDrawer && { display: 'none' }) }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Box>
                    <List>
                        <ListItem disablePadding>
                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][8].title)}
                                body={t(helperDialogs["siteWrapper"][8].body)}
                                dialogIndex={8}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    user.role.accessLevel >= MIN_LEVEL_LEADER ?
                                        helperDialogs["siteWrapper"].length - 1 :
                                        9
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 8}
                                handleClose={handleClose}
                                position='right'
                            >
                                <ListItemButton onClick={() => history.push("home")}>
                                    <ListItemIcon>
                                        <Tooltip title={t("Projects.global_view")} placement="right">
                                            <HomeIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("Projects.global_view")} sx={{ opacity: openDrawer ? 1 : 0}}
                                        primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                     />
                                </ListItemButton>
                            </HelperDialog>
                        </ListItem>
                        {(features.reportProductivityToolsUsed || features.productivityUsers || features.reportTimeline) &&
                          <ListItem disablePadding>
                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][9].title)}
                                body={t(helperDialogs["siteWrapper"][9].body)}
                                dialogIndex={9}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    helperDialogs["siteWrapper"].length - 1
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 9}
                                handleClose={handleClose}
                                position='right'
                            >
                                <ListItemButton onClick={() => history.push('dashboard')}>
                                    <ListItemIcon>
                                        <Tooltip title={t("BoardTypes.General")} placement="right">
                                            <PieChartIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("BoardTypes.General")} sx={{ opacity: openDrawer ? 1 : 0}}
                                                    primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                    />
                                </ListItemButton>
                            </HelperDialog>
                        </ListItem>}
                        {(features.userProductivityReportItemsPerformed || features.worksheetsUsers ||
                          features.benchmark || features.globalReportProjectsStatus || 
                          features.reportProjectsEvaluation) && <ListItem disablePadding>
                            <ListItemButton onClick={() => history.push("globalReport")}>
                                <ListItemIcon>
                                    <Tooltip title={t("Reports")} placement="right">
                                        <Assignment />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Reports")} sx={{ opacity: openDrawer ? 1 : 0}}
                                    primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                />
                            </ListItemButton>
                        </ListItem>}
                        {features.estimationProject && user.role.accessLevel >= MIN_LEVEL_LEADER &&
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => history.push("estimation")}>
                                    <ListItemIcon>
                                        <Tooltip title={t("project_tasks_page.estimate")} placement="right">
                                            <PieChartOutlineIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("project_tasks_page.estimate")} sx={{ opacity: openDrawer ? 1 : 0}}
                                                  primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                    />
                                </ListItemButton>
                            </ListItem>
                        }
                        {user.role.accessLevel >= MIN_LEVEL_ADMIN && <ListItem disablePadding>
                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][10].title)}
                                body={t(helperDialogs["siteWrapper"][10].body)}
                                dialogIndex={10}
                                totalDialogs={helperDialogs["siteWrapper"].length}
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 10}
                                handleClose={handleClose}
                                position='right'
                            >
                                <ListItemButton onClick={() => history.push("projectTypes")}>
                                    <ListItemIcon>
                                        <Tooltip title={t("project_page.templates")} placement="right">
                                        <DashboardIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("project_page.templates")} sx={{ opacity: openDrawer ? 1 : 0}}
                                        primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                     />
                                </ListItemButton>
                            </HelperDialog>
                        </ListItem>}
                        {(features.users || features.manageWorkSchedules) && user.role.accessLevel >= MIN_LEVEL_LEADER &&
                            <ListItem disablePadding>
                                <HelperDialog
                                    title={t(helperDialogs["siteWrapper"][11].title)}
                                    body={t(helperDialogs["siteWrapper"][11].body)}
                                    dialogIndex={user.role.accessLevel >= MIN_LEVEL_ADMIN ? 11 : 10}
                                    totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                        helperDialogs["siteWrapper"].length :
                                        helperDialogs["siteWrapper"].length - 1
                                    }
                                    handleNext={handleActiveHelperChange}
                                    isOpen={activeHelperDialogIndex === 11}
                                    handleClose={handleClose}
                                    position='right'
                                >
                                    <ListItemButton onClick={() => history.push("/configurationUsers")}>
                                        <ListItemIcon>
                                            <Tooltip title={t("Users.title")} placement="right">
                                            <PersonIcon />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={t("Users.title")} sx={{ opacity: openDrawer ? 1 : 0}}
                                            primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                        />
                                    </ListItemButton>
                                </HelperDialog>
                            </ListItem>
                        }

                        {(features.dailyProjectTracking || features.dailyProjectTrackingDragAndDrop) && 
                        <ListItem disablePadding>
                            <HelperDialog
                                title={t(helperDialogs["siteWrapper"][12].title)}
                                body={t(helperDialogs["siteWrapper"][12].body)}
                                dialogIndex={user.role.accessLevel >= MIN_LEVEL_ADMIN ? 12 : 11}
                                totalDialogs={user.role.accessLevel >= MIN_LEVEL_ADMIN ?
                                    helperDialogs["siteWrapper"].length :
                                    helperDialogs["siteWrapper"].length - 1
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 12}
                                handleClose={handleClose}
                                position='right'
                            >
                                <ListItemButton onClick={() => history.push("/daily")}>
                                    <ListItemIcon>
                                        <Tooltip title={t("daily_page.title")} placement="right">
                                            <FactCheckIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("daily_page.title")} sx={{ opacity: openDrawer ? 1 : 0}}
                                        primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                    />
                                </ListItemButton>
                            </HelperDialog>
                        </ListItem>}
                        {features.interoperabilityAll && user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => history.push("/interoperability")}>
                                    <ListItemIcon>
                                        <Tooltip title={t("Herramienta.interoperability")} placement="right">
                                            <ApiIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={t("Herramienta.interoperability")} sx={{ opacity: openDrawer ? 1 : 0}}
                                                  primaryTypographyProps={{fontSize: ".84rem", marginLeft: "-1rem"}}
                                    />
                                </ListItemButton>
                            </ListItem>
                        }
                        <ListItem disablePadding >
                            <ListItemButton onClick={(event: any) => handleClickMenu(event, 7)} >
                                <ListItemIcon>
                                    <Tooltip title={t("help_center")} placement="right" >
                                        <HelpIcon />
                                    </Tooltip>
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </CustomDrawer>
            </HelperDialog>

            <Main
                open={openDrawer}
                sx={{
                    flexGrow: 1,
                    paddingLeft: matchesXS ? 0 : 3,
                    paddingTop: 3, paddingBottom: 3,
                    paddingRight: matches ? 0 : 3,
                    background: "white",
                    minHeight: "85%",
                    marginLeft: matchesXS ? 0 : "20px"
                }}
            >
                {props.children}
                <ToastContainer theme="colored" />
            </Main>

            <Box className="main-footer" sx={{ paddingLeft: matchesXS ? 0 : '50px' }}>
                <Box>{t("dashboard.privacy_note")}</Box>
                <Box>Copyright © 2023 <span className="color-red">TruEffort</span>. {" " + t("Version") + " " + user.version + ". " + t("dashboard.all_rights")}</Box>
            </Box>
        </React.Fragment>
    );
}

export default SiteWrapper;
