import {useTranslation} from "react-i18next";
import {getWindowsBySite} from "../../util/ActivitiesProcessorUtil";
import {formatSeconds} from "../../util/GlobalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Alert, Box, Card, Chip, Menu, MenuItem } from "@mui/material";
import ImageService from "../../services/image.service";
import { TrueffortButton, TrueffortSelect } from "../../MUIstyle/MUICustom";
import CustomAvatar from "../custom/CustomAvatar";
import { useState, useMemo, useEffect } from "react";
import { Pending as PendingIcon, Remove as RemoveIcon } from "@mui/icons-material";
import SiteService from "../../services/site.service";

const DashboardAppSitesTRUE = ({id, activity, idUser, idProfile, totalSeconds, listUsers, listAllActivities, 
    className, setRefreshPage, makeRelevant, listProfiles, listCategories, setRefreshCategorySite}) => {
  //console.log('DashboardAppSitesTRUE');
  //console.log(activity);
  const {t} = useTranslation();
  const [showWindowsList, setShowWindowsList] = useState(false);
  const [windowsList, setWindowsList] = useState([]);
  const [showMore, setShowMore] = useState(false);
  //const [imageB64, setImageB64] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const imageService = useMemo(() => new ImageService(), []);
  const siteService = useMemo(() => new SiteService(), []);
  const [anchorElemFilter, setAnchorElemFilter] = useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = useState(0);

  const [selectedName, setSelectedName] = useState("");
  const [idSelectedSite, setIdSelectedSite] = useState(0);

  const [idSelectedUsers, setIdSelectedUsers] = useState([-1]);
  const [idSelectedProfile, setIdSelectedProfile] = useState("");
  const [idSelectedCategory, setIdSelectedCategory] = useState("");


  const [showForm, setShowForm] = useState({user: false, userRequired: false, 
    profile: false, showProfileRequired:false, category:false});
  //const [usersValues, setUsersValues] = useState([]);


  useEffect(() => {
    if (typeof activity.application.idImage != "undefined" && activity.application.idImage != null) {
      imageService.get(activity.application.idImage).then(({data: response}) => {
        setImageUrl(response.url);
      });
    }
  }, []);

  const getWindows = () => {
    let windows = [];
    if (!showWindowsList) {
      windows = getWindowsBySite(listAllActivities, idUser, activity.application.name);
      setWindowsList(windows);
    }
    setShowWindowsList(!showWindowsList);
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const assignToUser = () => {
    if (idSelectedUsers.length == 0) {
      setShowForm({...showForm, userRequired: false});
    } else {
      setAssignToUser(idSelectedSite, idSelectedUsers);
    }
  }

  const setAssignToUser = (idSite, ids) => {
    siteService.setRelevantSitesByIdsUser(idSite, makeRelevant, ids).then(({data: response}) => {
      setShowForm({...showForm, user:false, userRequired: false});
      setIdSelectedUsers([]);
      setRefreshPage(Math.random());
    }).catch(({data: response}) => { console.log(response); }); 
  }

  const handleShowFormUser = (idSite) => {
    setAnchorElemFilter(null);
    setIdSelectedSite(idSite);
    setShowForm({user: true, userRequired: false, profile: false, category: false});
  }

  const handleChangeUser = (event) => {
    const { target: { value } } = event;
    let val = value.length > 1 ? value.filter(n => n != -1) : value;
    let names = "";
    val.forEach((id) => {
      let us = listUsers.find(us => us.idUser == id);
      names += us.fullName + ",";
    });
    if (names.length > 0) { names = names.substring(0, names.length - 1);}
    if (val.length == 0) { val.push(-1); }
    setSelectedName(names);
    setIdSelectedUsers(val);
  };

  const handleShowFormProfile = (idSite) => {
    //console.log("idSite: " + idSite);
    setAnchorElemFilter(null);
    setIdSelectedSite(idSite);
    setShowForm({user: false, userRequired: false, 
      profile: true, showProfileRequired: false,
      category: false, categoryRequired: false});
  }

  const assignToProfile = () => {
    //console.log("assignToProfile");
    if (idSelectedProfile == 0 || idSelectedProfile == "") {
        setShowForm({...showForm, showProfileRequired: true});
        return;
    }

    siteService.setRelevantSitesByIdsProfile(idSelectedSite, makeRelevant, [idSelectedProfile]).then(({data: response}) => {
        //handleProfileRelevant();
        setShowForm({...showForm, showProfileRequired: false, profile: false});
        setIdSelectedProfile("");
        setRefreshPage(Math.random());
    }).catch((error) => {
        console.log(error);
    });
  }

  const handleShowFormCategory = (idSite) => {
    //console.log("idSite: " + idSite);
    setAnchorElemFilter(null);
    setShowForm({profile:false, profileRequired:false, 
      user:false, userRequired: false, category:true, categoryRequired: false });
    setIdSelectedSite(idSite);
  }

  const assignToCategory = () => {
    //console.log("assignToCategory");
    if (idSelectedCategory == 0 || idSelectedCategory == "") {
      setShowForm({...showForm, categoryRequired: true});
        return;
    }

    siteService.categorize(idSelectedSite, idSelectedCategory).then(({data: response}) => {
        //console.log(response);
      setShowForm({...showForm, category: false, categoryRequired: false});
      setRefreshCategorySite(Math.random());
    }).catch(({data: response}) => {
        console.log(response);
    });
}

  return(
    <div className={className} 
      style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%'}}>
        <div style={{display:'flex', width:'100%'}}>
          <div id={id} className="div-app-window-clean-new" style={{paddingLeft: className?.includes("div-app-windows-clean") ? "3em" : "", display:'flex', alignItems:'center', height:'3em', cursor:'pointer'}} onClick={getWindows}>
            <CustomAvatar url={imageUrl} />
            <span className="name-app">{activity.application.name}</span>
            <span className="time-app-wrapper" style={{background: '#F3F3F3'}}>
                <span className="time-app" style={{width: (activity.totalSeconds * 100 /totalSeconds) + "%"}}>
                  <span> {formatSeconds(activity.totalSeconds, false, false, true)}</span></span>
            </span>
            <FontAwesomeIcon className="caret-icon" icon={['fas', showWindowsList ? 'caret-down' : 'caret-right']}/>
          </div>
          {<TrueffortButton
              onClick={(event) => handleClickMenu(event, 1)}>
              <PendingIcon sx={{color: anchorElemFilter == null ? "#7C6924" : "#ED9A55"}}/>
          </TrueffortButton>}
          <Menu id={id + "_idElementsAssignSites"}
            anchorEl={anchorElemFilter}
            open={openOptions}
            onClose={() => setAnchorElemFilter(null)}
            sx={{
              '& .MuiPaper-root': {
                background: '#EFEFEF',
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
              }
            }}
          >

            {(menuNumber == 1) && <div>
              <MenuItem id={id + "_idElementsAssignSites_0"} key={0} sx={{fontSize: ".8rem!important", background:'#EFEFEF'}} 
                onClick={() => handleShowFormUser(activity.idSite)}>
                {activity.isRelevant ? t("dashboard.assign_to_user_not_relevant") : t("dashboard.assign_to_user_relevant")}
              </MenuItem>
              <MenuItem id={id + "_idElementsAssignSites_1"} key={1} sx={{fontSize: ".8rem!important", background:'#EFEFEF'}} 
                onClick={() => handleShowFormProfile(activity.idSite)}>
                {activity.isRelevant ? t("dashboard.assign_to_profile_not_relevant") : t("dashboard.assign_to_profile_relevant")}
              </MenuItem>
              <MenuItem id={id + "_idElementAssignCategory"} key={2} sx={{fontSize: ".8rem!important", background:'#EFEFEF'}} 
                onClick={() => handleShowFormCategory(activity.idSite)}>
                  {t("dashboard.assign_to_category")}
              </MenuItem>
              {idUser != "" && <MenuItem id={id + "_idElementAssignRelAndNotRel"} key={3}
                sx={{ fontSize: ".8rem!important", background: '#EFEFEF', color: '#E4555A' }}
                onClick={() => {
                  setAnchorElemFilter(null);
                  setAssignToUser(activity.idSite, [idUser]);
                }}>
                <RemoveIcon />{t("dashboard.turn") + (activity.isRelevant ? "No " : "") + t("Relevant")}
              </MenuItem>}
            </div>}
          </Menu>
        </div>
        {showForm?.user &&
          <Card sx={{width:'90%', margin:'1em', padding:'0.5em'}}>
            <TrueffortSelect
                id={id+"_idSelectedUser"}
                name="idUser"
                size="small"
                placeholder={t("dashboard.select_user")}
                fullWidth
                renderValue={(selected) => (
                  <>
                  {selectedName == "" ? <>{t("dashboard.select_user")}</> :
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedName.split(",").map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>}
                  </>
                )}
                value={idSelectedUsers}
                onChange={handleChangeUser}
                style={{marginBottom:'0.5em'}}
                labelstring={t("User")}
                multiple={true}
            >
                <MenuItem id={id+"_idSelectedUser_0"} value={-1} disabled>{t("dashboard.select_user")}</MenuItem>
                {listUsers.map((u, k) => (
                    <MenuItem id={id + "_idSelectedUser_" + (k + 1)}
                        key={u.idUser}
                        value={u.idUser}
                    >{u.fullName}
                    </MenuItem>
                ))}
            </TrueffortSelect>
            {/*<TrueffortInputLabel shrink labelString={t("User")} />
            <div>
              <Select
                  name="role"
                  required
                  placeholder={t("dashboard.select_user")}
                  value={usersValues}
                  options={Array.isArray(users) &&
                    users.map((r) => { return ({ value: r.idUser, label: r.fullName }) })
                  }
                  onChange={(option) => setUsersValues(option)}
                  //error={formik.errors?.idRole && formik.touched?.idRole ? t(formik.errors.idRole) : null}
                  styles={styleReactSelect}
                  isMulti
                  placement="auto"
              />
                </div>*/}
            {showForm?.userRequired && <Alert id={id + "_alertErrorUser"} severity="error">{t("dashboard.user_required")}</Alert>}
            <TrueffortButton 
                id={id + "_idBtnAssignToUser"}
                style={{float:'right', marginLeft:'0.5em'}} 
                color="secondary"
                variant={"contained"} 
                onClick={assignToUser}>{t("Assign")}
            </TrueffortButton>
            <TrueffortButton 
                id={id + "_btnCancelAssignToUser"}
                style={{float:'right'}} 
                color="error"
                variant={"outlined"}
                onClick={() => {
                    setIdSelectedUsers([]);
                    setShowForm({...showForm, user:false});
                    }}>{t("Cancel")}
            </TrueffortButton>
          </Card>
        }
        {showForm?.profile &&
          <Card sx={{width:'90%', margin:'1em', padding:'0.5em'}}>
            <TrueffortSelect
                id={id+"_idSelectedProfile"}
                name="idProfile"
                size="small"
                placeholder={t("dashboard.select_user")}
                displayEmpty
                fullWidth
                value={idSelectedProfile}
                onChange={(e) => setIdSelectedProfile(e.target.value)}
                style={{marginBottom:'0.5em'}}
                labelstring={t("Profile")}
            >
                <MenuItem id={id+"_idSelectedProfile_0"} value="" disabled>{t("dashboard.select_profile")}</MenuItem>
                  {listProfiles.map((p, k) => (
                      <MenuItem id={id + "_idSelectedProfile_" + (k+1)}
                          key={p.idProfile}
                          value={p.idProfile}
                      >{p.name}
                      </MenuItem>
                  ))}
            </TrueffortSelect>
            {showForm?.showProfileRequired && <Alert id={id + "_alertErrorProfile"} severity="error">{t("dashboard.profile_required")}</Alert>}
            <TrueffortButton 
                id={id + "_idBtnAssignToProfile"}
                style={{float:'right', marginLeft:'0.5em'}} 
                color="secondary"
                variant={"contained"} 
                onClick={assignToProfile}>{t("Assign")}
            </TrueffortButton>
            <TrueffortButton 
                id={id + "_btnCancelAssignToUser"}
                style={{float:'right'}} 
                color="error"
                variant={"outlined"}
                onClick={() => {
                    setIdSelectedProfile("");
                    setShowForm({...showForm, profile:false, showProfileRequired: false});
                    }}>{t("Cancel")}
            </TrueffortButton>
          </Card>
        }
        {showForm?.category &&
          <Card sx={{width:'90%', margin:'1em', padding:'0.5em'}}>
              <TrueffortSelect
                  id={id + "_idSelectedCategory"}
                  name="idSelectedCategory"
                  size="small"
                  placeholder={t("dashboard.select_category")}
                  displayEmpty
                  fullWidth
                  value={idSelectedCategory}
                  onChange={(e) => setIdSelectedCategory(e.target.value)}
                  style={{marginBottom:'0.5em'}}
                  labelstring={t("category")}
              >
                  <MenuItem id={id + "_idSelectedCategory_0"} value="" disabled>{t("dashboard.select_category")}</MenuItem>
                  {listCategories.map((c, k) => (
                      <MenuItem id={id + "_idSelectedCategory_" + (k+1)}
                          key={c.idCategorysite}
                          value={c.idCategorysite}
                      >{c.name}
                      </MenuItem>
                  ))}                    
              </TrueffortSelect>
              {showForm?.categoryRequired && <Alert severity="danger">{t("dashboard.category_required")}</Alert>}
              <TrueffortButton  
                  id={id + "_idBtnAssignToCategory"}
                  style={{float:'right', marginLeft:'0.5em'}} 
                  color="secondary"
                  variant={"contained"}
                  onClick={assignToCategory}
                  >{t("Assign")}
              </TrueffortButton>
              <TrueffortButton 
                  id={id + "_idBtnCancelToCategory"}
                  style={{float:'right'}} 
                  color="error"
                  variant={"outlined"}
                  onClick={() => {
                      setIdSelectedCategory("");
                      setShowForm({...showForm, category:false, categoryRequired: false});
                      }}>{t("Cancel")}
              </TrueffortButton>
          </Card>
          }
        {showWindowsList &&
          <div id={id + "_WindowsNotes"} className="div-windows-parent">
            {windowsList.map((w, k) => (
              showMore || k < 5 ?
                <div id={id + "_Notes_" + k} key={k} className="item-window" style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', height:'2.5em'}}>
                  <div style={{width:'10em', fontWeight:'bold'}}>{formatSeconds(w.totalSeconds, false, false, true)}</div>
                  <div><li>{w.notes.length > 60 ? w.notes.substring(0,60)+"..." : w.notes}</li></div>
                </div> : null
            ))}

            {!showMore && windowsList.length > 5 &&
              <div id={id+"_idBtnShowMoreNotes"} className="item-window show-more" onClick={() => setShowMore(true)}>
                <FontAwesomeIcon icon={['fas', 'chevron-down']}/> {t("show_more")}
              </div>
            }
            {showMore &&
              <div id={id+"_idBtnShowLessNotes"} className="item-window show-more" onClick={() => setShowMore(false)}>
                <FontAwesomeIcon icon={['fas', 'chevron-up']}/> {t("show_less")}
              </div>
            }
          </div>
          }
      </div>
  );
}

export default DashboardAppSitesTRUE;
