// @flow
import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import {useTranslation} from "react-i18next";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TrueffortButtonSmall } from "../MUIstyle/MUICustom";
import { useAuthContext } from "../contexts/AuthenticationContext";
import DepartamentsTab from "../components/catalogs/DepartamentsTab";

function ConfigurationCatalogs() {

    const {user} = useAuthContext();
    const features = user?.instance?.license ? user?.instance?.license?.features : {};
    const {t} = useTranslation();
    const [tabName, setTabName] = React.useState("departments");

    return (
        <SiteWrapper>
            <Container maxWidth="xl" sx={{marginTop:'20px'}}>
                <div className="title">
                    <h2 id="idTitle">{t("catalogs_page.title")}</h2>
                </div>
                <Grid container spacing={1} rowSpacing={1} >
                    {features.categorizeApplications && <Grid item>
                        <TrueffortButtonSmall
                            id="idTabConfigCategory"
                            color="secondary"
                            variant={tabName == "departments" ? "contained" : "outlined"}
                            onClick={() => setTabName("departments")}
                            style={{marginRight: "0.2em"}}>
                            {t("catalogs_page.departaments.title")}
                        </TrueffortButtonSmall>
                    </Grid>}
                </Grid>
                <Grid style={{marginTop:"1em"}}>
                    {tabName == "departments" && <DepartamentsTab />}
                </Grid>
            </Container>
        </SiteWrapper>
    );
}

export default ConfigurationCatalogs;
