import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class DepartamentService {

    getAll(idInstance:number) {
        return http?.get(baseURL.readApi.departament + "/" + idInstance);
    }

    create(data:any) {
      return http?.post(baseURL.activitiesapi.departament, data);
    }

    update(data:any) {
      return http?.put(baseURL.activitiesapi.departament, data);
    }

    delete (idDepartament:number) {
      return http?.delete(baseURL.activitiesapi.departament+'/'+idDepartament);
    }
}

export default DepartamentService;