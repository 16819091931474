import * as React from "react";
import ActivityService from "../services/activity.service";
import { Menu, MenuItem, Card, Alert, Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ApplicationService from "../services/application.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageService from "../services/image.service";
import { dateToDateLocale, formatSeconds } from "../util/GlobalFunctions";
import { TrueffortButton, TrueffortSelect } from "../MUIstyle/MUICustom";
import { Pending as PendingIcon, Remove as RemoveIcon } from "@mui/icons-material";
import CustomAvatar from "./custom/CustomAvatar";
import { useAuthContext } from "../contexts/AuthenticationContext";
import { useState } from "react";

const DashboardAppWindowsTRUE = ({ id, activity, startDate, endDate, idUser, idProfile,
  idSelectedProject, totalSeconds, handleDecideRelevant, users, listProfiles,
  listCategories, setRefreshCategory, className, isLarge, handleProfileRelevant, makeRelevant, setRefreshPage, asRelevant }) => {
  //console.log(activity);
  let sizeAvatar = isLarge ? '36px' : '20px';
  let sizeName = isLarge ? '16px' : '11px';
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const [showMore, setShowMore] = useState(false);
  const [idSelectedCategory, setIdSelectedCategory] = useState("");
  const [idSelectedProfile, setIdSelectedProfile] = useState("");
  const [idSelectedUser, setIdSelectedUser] = useState([-1]);
  const [selectedName, setSelectedName] = useState("");
  const [showFormUser, setShowFormUser] = useState(false);
  const [showFormProfile, setShowFormProfile] = useState(false);
  const [showFormCategory, setShowFormCategory] = useState(false);
  const [showUserRequired, setShowUserRequired] = useState(false);
  const [showProfileRequired, setShowProfileRequired] = useState(false);
  const [showCategoryRequired, setShowCategoryRequired] = useState(false);
  const [idSelectedApplication, setIdSelectedApplication] = useState(0);
  const [showWindowsList, setShowWindowsList] = useState(false);
  const [windowsList, setWindowsList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const activityService = React.useMemo(() => new ActivityService(), []);
  const applicationService = React.useMemo(() => new ApplicationService(), []);
  const imageService = React.useMemo(() => new ImageService(), []);
  const [anchorElemFilter, setAnchorElemFilter] = useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = useState(0);

  React.useEffect(() => {
    // Para que al cambiar de usuario, si está la lista desplegada, se actualice
    if (showWindowsList) {
      getWindowsByApp();
    }

    // Obtener la imagen de la aplicación
    if (typeof activity.application.idImage != "undefined" && activity.application.idImage != null) {
      imageService.get(activity.application.idImage).then(({ data: response }) => {
        setImageUrl(response.url);
      });
    }
  }, [idUser]);

  const getWindowsByApp = () => {
    //console.log("start getWindowsByApp");
    let start = dateToDateLocale(startDate).toISOString();
    let end = dateToDateLocale(endDate).toISOString();
    // Consultar del servicio para obtener las actividades de esta app.
    activityService.findWindowsByApplication(start.slice(0, 10), end.slice(0, 10),
      idUser, idProfile, idSelectedProject, activity.application.idApplication, asRelevant ? 1 : 0,
      activity.application.includedSitesNull != undefined ? activity.application.includedSitesNull : 1)
      .then(({ data: response }) => {
        let arrActivities = response;

        /* FILTRAR por perfil, si aplica */
        if (idProfile != "") {
          // Se tiene que obtener el tiempo, sólo de las actividades de los usuarios que tengan ese perfil.
          // Entonces en realidad se filtra por los usuarios de ese perfil.
          arrActivities = arrActivities.filter((data) => {
            let findIdProfile = users.filter(u => u.profile.find(p => p.idProfile == idProfile) != undefined);
            if (findIdProfile) {
              return data;
            }
          });
        }

        /* FILTRAR por usuario, si aplica */
        if (idUser != "") {
          arrActivities = arrActivities.filter(data => data.idUser == idUser);
        }

        // Agrupar por nombre ventana para sumar los tiempos
        let arrGrouped = {};
        for (let { notes, ...fields } of arrActivities) {
          if (!arrGrouped[notes]) arrGrouped[notes] = [];
          arrGrouped[notes].push({ ...fields });
        }

        let arrFinal = [];
        Object.keys(arrGrouped).forEach(function (key) {
          let seconds = 0;

          // Recorrer los registros de la misma ventana para determinar el tiempo
          arrGrouped[key].forEach((act, j) => {
            if (act.totalSeconds > 0) {
              seconds += act.totalSeconds;
              let dt = new Date(act.startTime);
              dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
              let dtEnd = new Date(dt);
              dtEnd.setSeconds(dtEnd.getSeconds() + act.totalSeconds);
            }
          });

          let obj = {
            application: { name: key },
            totalSeconds: seconds,
            notes: key
          }
          arrFinal.push(obj);
        });

        // Ordenar por tiempo
        arrFinal.sort((a, b) => a.totalSeconds < b.totalSeconds ? 1 : -1);
        setWindowsList(arrFinal);
      }).catch(({ data: response }) => {
        console.log("Error al consultar findWindowsByApplication in DashboardAppWindowsTRUE");
      });
  }

  const toggleWindowsList = () => {
    if (!showWindowsList) {
      getWindowsByApp();
    }
    setShowWindowsList(!showWindowsList);
  }

  const handleShowFormUser = (idApplication) => {
    //console.log("idApplication: " + idApplication);
    setAnchorElemFilter(null);
    setShowUserRequired(false);
    setShowFormProfile(false);
    setShowFormCategory(false);
    setIdSelectedApplication(idApplication);
    setShowFormUser(true);
  }

  const handleShowFormProfile = (idApplication) => {
    //console.log("idApplication: " + idApplication);
    setAnchorElemFilter(null);
    setShowProfileRequired(false);
    setShowFormUser(false);
    setShowFormCategory(false);
    setIdSelectedApplication(idApplication);
    setShowFormProfile(true);
  }

  const handleShowFormCategory = (idApplication) => {
    //console.log("idApplication: " + idApplication);
    setAnchorElemFilter(null);
    setShowFormProfile(false);
    setShowFormUser(false);
    setIdSelectedApplication(idApplication);
    setShowFormCategory(true);
  }

  const assignToUser = () => {
    //console.log("assignToUser");
    if (idSelectedUser.length == 0) {
      setShowUserRequired(true);
      return;
    }

    let idApp = idSelectedApplication;
    //let idUser = idSelectedUser;
    if (makeRelevant) {
      applicationService.setRelevantsApplicationByUsers(idApp, idSelectedUser).then(({ data: response }) => {
        setShowFormUser(false);
        setIdSelectedUser([]);
        setRefreshPage(Math.random());
      }).catch(({ data: response }) => { console.log(response); });
    } else {
      applicationService.setNotRelevantsApplicationByUsers(idApp, idSelectedUser).then(({ data: response }) => {
        setShowFormUser(false);
        setIdSelectedUser([]);
        setRefreshPage(Math.random());
      }).catch(({ data: response }) => { console.log(response); });
    }
  }

  const assignToProfile = () => {
    //console.log("assignToProfile");
    if (idSelectedProfile == 0 || idSelectedProfile == "") {
      setShowProfileRequired(true);
      return;
    }

    let idApp = idSelectedApplication;
    let idProfile = idSelectedProfile;
    applicationService.setRelevantProfile(idApp, idProfile).then(({ data: response }) => {
      //console.log(response);
      handleProfileRelevant();
      setShowFormProfile(false);
    }).catch((error) => {
      console.log(error);
    });
  }

  const assignToCategory = () => {
    //console.log("assignToCategory");
    if (idSelectedCategory == 0 || idSelectedCategory == "") {
      setShowCategoryRequired(true);
      return;
    }

    let idApp = idSelectedApplication;
    let idCat = idSelectedCategory;
    applicationService.categorize(idApp, idCat).then(({ data: response }) => {
      //console.log(response);
      setShowFormCategory(false);
      setRefreshCategory(Math.random());
    }).catch(({ data: response }) => {
      console.log(response);
    });
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const handleChangeUser = (event) => {
    const { target: { value } } = event;
    let val = value.length > 1 ? value.filter(n => n != -1) : value;
    let names = "";
    val.forEach((id) => {
      let us = users.find(us => us.idUser == id);
      names += us.fullName + ",";
    });
    if (names.length > 0) { names = names.substring(0, names.length - 1); }
    if (val.length == 0) { val.push(-1); }
    setSelectedName(names);
    setIdSelectedUser(val);
  };

  return (
    <div className={className}
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div id={id} className="div-app-window-clean-new" style={{
          paddingLeft: className?.includes("div-app-windows-clean") && isLarge ? "3em" : "1em",
          display: 'flex', alignItems: 'center', height: isLarge ? '3em' : '1.2em', cursor: 'pointer'
        }} onClick={toggleWindowsList}>
          <CustomAvatar size={sizeAvatar} src={imageUrl} />
          <span className="name-app" style={{ fontSize: sizeName }}>{activity.application.name}</span>
          <span className="time-app-wrapper" style={{ background: '#F3F3F3' }}>
            <span className="time-app" style={{
              fontSize: sizeName,
              width: (className?.includes("div-app-windows-clean") ? '40%' : (activity.totalSeconds * 100 / totalSeconds) + "%")
            }}>
              <span> {formatSeconds(activity.totalSeconds, false, false, true)} </span>
            </span>
          </span>
          <FontAwesomeIcon className="caret-icon" icon={['fas', showWindowsList ? 'caret-down' : 'caret-right']} />
        </div>
        {features.productivityTools && isLarge &&
          <TrueffortButton
            id={id + "_idBtnAssign"}
            onClick={(event) => handleClickMenu(event, idUser == "" ? 1 : 2)}>
            <PendingIcon sx={{ color: anchorElemFilter == null ? "#7C6924" : "#ED9A55" }} />
          </TrueffortButton>}
        <Menu id={id + "_idElementsAssign"}
          anchorEl={anchorElemFilter}
          open={openOptions}
          onClose={() => setAnchorElemFilter(null)}
          sx={{
            '& .MuiPaper-root': {
              background: '#EFEFEF',
              boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            }
          }}
        >
          {(menuNumber == 1 || menuNumber == 2) && <div>
            <MenuItem id={id + "_idElementAssignProfile"} key={0} sx={{ fontSize: ".8rem!important", background: '#EFEFEF' }}
              onClick={() => handleShowFormProfile(activity.application.idApplication)}>
              {activity.isRelevant ? t("dashboard.assign_to_profile_not_relevant") : t("dashboard.assign_to_profile_relevant")}
            </MenuItem>
            <MenuItem id={id + "_idElementAssignUser"} key={1} sx={{ fontSize: ".8rem!important", background: '#EFEFEF' }}
              onClick={() => handleShowFormUser(activity.application.idApplication)}>
              {activity.isRelevant ? t("dashboard.assign_to_user_not_relevant") : t("dashboard.assign_to_user_relevant")}
            </MenuItem>
            <MenuItem id={id + "_idElementAssignCategory"} key={2} sx={{ fontSize: ".8rem!important", background: '#EFEFEF' }}
              onClick={() => handleShowFormCategory(activity.application.idApplication)}>
              {t("dashboard.assign_to_category")}
            </MenuItem>
            {menuNumber == 2 &&
              <MenuItem id={id + "_idElementAssignRelAndNotRel"} key={3}
                sx={{ fontSize: ".8rem!important", background: '#EFEFEF', color: '#E4555A' }}
                onClick={() => {
                  setAnchorElemFilter(null);
                  handleDecideRelevant(activity.application.idApplication, activity);
                }}>
                <RemoveIcon />{t("dashboard.turn") + (activity.isRelevant ? "No " : "") + t("Relevant")}
              </MenuItem>
            }
          </div>}
        </Menu>
      </div>
      {showFormUser &&
        <Card sx={{ width: '90%', margin: '1em', padding: '0.5em' }}>
          <TrueffortSelect
            id={id + "_idSelectedUser"}
            name="idUser"
            size="small"
            placeholder={t("dashboard.select_user")}
            //displayEmpty
            fullWidth
            renderValue={(selected) => (
              <>
                {selectedName == "" ? <>{t("dashboard.select_user")}</> :
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedName.split(",").map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>}
              </>
            )}
            value={idSelectedUser}
            onChange={handleChangeUser}
            style={{ marginBottom: '0.5em' }}
            labelstring={t("User")}
            multiple={true}
          >
            <MenuItem id={id + "_idSelectedUser_0"} value={-1} disabled>{t("dashboard.select_user")}</MenuItem>
            {users.map((u, k) => (
              <MenuItem id={id + "_idSelectedUser_" + (k + 1)}
                key={u.idUser}
                value={u.idUser}
              >{u.fullName}
              </MenuItem>
            ))}
          </TrueffortSelect>
          {showUserRequired && <Alert id={id + "_alertErrorUser"} severity="error">{t("dashboard.user_required")}</Alert>}
          <TrueffortButton
            id={id + "_idBtnAssignToUser"}
            style={{ float: 'right', marginLeft: '0.5em' }}
            color="secondary"
            variant={"contained"}
            onClick={assignToUser}>{t("Assign")}
          </TrueffortButton>
          <TrueffortButton
            id={id + "_btnCancelAssignToUser"}
            style={{ float: 'right' }}
            color="error"
            variant={"outlined"}
            onClick={() => {
              setIdSelectedUser([]);
              setShowFormUser(false);
            }}>{t("Cancel")}
          </TrueffortButton>
        </Card>
      }

      {showFormProfile &&
        <Card sx={{ width: '90%', margin: '1em', padding: '0.5em' }}>
          <TrueffortSelect
            id={id + "_idSelectedProfile"}
            name="idProfile"
            size="small"
            placeholder={t("dashboard.select_profile")}
            displayEmpty
            fullWidth
            value={idSelectedProfile}
            onChange={(e) => setIdSelectedProfile(e.target.value)}
            style={{ marginBottom: '0.5em' }}
            labelstring={t("Profile")}
          >
            <MenuItem id={id + "_idSelectedProfile_0"} value="" disabled>{t("dashboard.select_profile")}</MenuItem>
            {listProfiles.map((p, k) => (
              <MenuItem id={id + "_idSelectedProfile_" + (k + 1)}
                key={p.idProfile}
                value={p.idProfile}
              >{p.name}
              </MenuItem>
            ))}
          </TrueffortSelect>
          {showProfileRequired && <Alert id={id + "_alertErrorProfile"} severity="error">{t("dashboard.profile_required")}</Alert>}
          <TrueffortButton
            id={id + "_idBtnAssignToProfile"}
            style={{ float: 'right', marginLeft: '0.5em' }}
            color="secondary"
            variant={"contained"}
            onClick={assignToProfile}
          >{t("Assign")}
          </TrueffortButton>
          <TrueffortButton
            id={id + "_idBtnCancelToProfile"}
            style={{ float: 'right' }}
            color="error"
            variant={"outlined"}
            onClick={() => {
              setIdSelectedProfile("");
              setShowFormProfile(false)
            }
            }>{t("Cancel")}
          </TrueffortButton>
        </Card>
      }

      {showFormCategory &&
        <Card sx={{ width: '90%', margin: '1em', padding: '0.5em' }}>
          <TrueffortSelect
            id={id + "_idSelectedCategory"}
            name="idSelectedCategory"
            size="small"
            placeholder={t("dashboard.select_category")}
            displayEmpty
            fullWidth
            value={idSelectedCategory}
            onChange={(e) => setIdSelectedCategory(e.target.value)}
            style={{ marginBottom: '0.5em' }}
            labelstring={t("category")}
          >
            <MenuItem id={id + "_idSelectedCategory_0"} value="" disabled>{t("dashboard.select_category")}</MenuItem>
            {listCategories.map((c, k) => (
              <MenuItem id={id + "_idSelectedCategory_" + (k + 1)}
                key={c.idCategory}
                value={c.idCategory}
              >{c.name}
              </MenuItem>
            ))}
          </TrueffortSelect>
          {showCategoryRequired && <Alert severity="danger">{t("dashboard.category_required")}</Alert>}
          <TrueffortButton
            id={id + "_idBtnAssignToCategory"}
            style={{ float: 'right', marginLeft: '0.5em' }}
            color="secondary"
            variant={"contained"}
            onClick={assignToCategory}
          >{t("Assign")}
          </TrueffortButton>
          <TrueffortButton
            id={id + "_idBtnCancelToCategory"}
            style={{ float: 'right' }}
            color="error"
            variant={"outlined"}
            onClick={() => {
              setIdSelectedCategory("");
              setShowFormCategory(false);
            }}>{t("Cancel")}
          </TrueffortButton>
        </Card>
      }

      {showWindowsList &&
        <div id={id + "_WindowsNotes"} className="div-windows-parent">
          {windowsList.map((w, k) => (
            showMore || k < 5 ?
              <div id={id + "_Notes_" + k} key={k} className="item-window"
                style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', paddingLeft: isLarge ? "" : "1.2em",
                  fontSize: sizeName, marginBottom: isLarge ? '0.2em' : "0"
                }}>
                <div style={{ width: '30%', fontWeight: 'bold' }}>{formatSeconds(w.totalSeconds, false, false, true)}</div>
                <div style={{ width: '70%' }}>&#8226;{w.notes.length > 60 ? w.notes.substring(0, 60) + "..." : w.notes}</div>
              </div> :
              null
          ))}
          {windowsList.length == 0 &&
            <div id={id + "_Notes_0"} key={"_0"} className="item-window" style={{
              fontSize: sizeName,
              marginBottom: isLarge ? '0.2em' : "0"
            }}>&#8226;{t("dashboard.not_exist_summary")}</div>
          }

          {!showMore && windowsList.length > 5 &&
            <div id={id + "_idBtnShowMoreNotes"} className="item-window show-more" style={{ fontSize: sizeName }}
              onClick={() => setShowMore(true)}>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />{t("show_more")}
            </div>
          }
          {showMore &&
            <div id={id + "_idBtnShowLessNotes"} className="item-window show-more" style={{ fontSize: sizeName }}
              onClick={() => setShowMore(false)}>
              <FontAwesomeIcon icon={['fas', 'chevron-up']} />{t("show_less")}
            </div>
          }
        </div>
      }
    </div>
  )
};

export default DashboardAppWindowsTRUE;