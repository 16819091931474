import {
  Card, CardActions, CardContent, Fab, Grid, InputLabel, MenuItem, Typography
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  HelperDialog,
  TrueffortButton,
  TrueffortConfirmAlert,
  TrueffortDate,
  TrueffortFABButton, TrueffortInputLabel,
  TrueffortSelect,
  TrueffortTextField
} from "../../MUIstyle/MUICustom";
import { EditNote as EditNoteIcon, Timelapse as TimelapseIcon } from "@mui/icons-material";
import Select from "react-select";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {confirmAlert} from "react-confirm-alert";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {useProjectPlan, useProjectPlanDispatch} from "./ProjectPlanContext";
import {complexityOptions, measurementStandards, sizeOptions} from "./EstimationPageUtils";
import {useTheme} from "@mui/material/styles";
import { styleReactSelect, styleReactSelectRequired } from "../../MUIstyle/GlobalStyles";
import {helperDialogs} from "../../util/HelperDialogs";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import { dateToDateLocale } from "../../util/GlobalFunctions";

export default function GenerateProjectForm({t, fetchedData, getEstimate, cancelProjectEstimation, 
  fillElementTypes, setElementTypes, helperDialogsConfig, projectTypeBase}: any) {

    const projectPlan = useProjectPlan();
    const projectPlanDispatch = useProjectPlanDispatch();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [registredDate, setRegistredDate] = useState(dateToDateLocale(new Date()));
    const [projectName, setProjectName] = React.useState("");    
    const [selectedProjectType, setSelectedProjectType] = React.useState({value: projectTypeBase?.idProjectType, label: projectTypeBase?.name});
    const [idSelectedMeasurement, setIdSelectedMeasurement] = React.useState("");
    const [idsLeader, setIdsLeader] = React.useState([]);
    const [required, setRequired] = React.useState(false);
    const [elementsForm, setElementsForms] = React.useState(
        [{idElemento: "", tipoElemento: "", numeroElementos: 1, tamanio: 2, complejidad: 2}]
    );

    useEffect(() => {
        if(projectTypeBase) {
          handleProjectTypeChange({value: projectTypeBase.idProjectType, label: projectTypeBase.name});
        }
    }, [projectTypeBase]);

    const handleEstimate = () => {
        if (!projectName || !selectedProjectType?.value) {
            setRequired(true);
            return;
        }

        for (let i = 0; i < elementsForm.length; i++) {
            if (elementsForm[i].tipoElemento === "" ||
                elementsForm[i].numeroElementos === 0 ||
                elementsForm[i].tamanio === "" ||
                elementsForm[i].complejidad === ""
            ) {
                setRequired(true);
                return;
            }
        }

        const formData = {
            projectName: projectName,
            idSelectedProjectType: selectedProjectType?.value,
            idSelectedMeasurement: idSelectedMeasurement,
            leadersIds: idsLeader,
            elementsForm: elementsForm,
            registredDate
        }

        getEstimate(formData);
        setRequired(false);
        //setShowForm(false);
    }

    useEffect(() => {
      if (fetchedData.elementTypes.length == 1) {
        handleElementTypeChange(fetchedData.elementTypes[0].idElementType, 0);
      }
    }, [fetchedData]);

    const handleNewEstimate = () => {
        setElementsForms(
            [{idElemento: "", tipoElemento: "", numeroElementos: 1, tamanio: 2, complejidad: 2}]
        );
        setIdsLeader([]);
        setSelectedProjectType(null);
        setProjectName("");
        setRegistredDate(dateToDateLocale(new Date()));
        setIdSelectedMeasurement("");
        setElementTypes([]);
        cancelProjectEstimation();
    }

    const newEstimateWarning = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return(
                    <TrueffortConfirmAlert
                        title={
                            <Typography color='error' variant='h6' fontWeight={700} marginBottom='20px' >
                                <ReportProblemIcon color='error' /> &nbsp;&nbsp;{t("Warning")}
                            </Typography>
                        }
                        alertColor='error'
                        messages={[
                            t("estimation_page.msg_new_estimate_confirm1"),
                            t("estimation_page.msg_new_estimate_confirm2"),
                            t("estimation_page.msg_new_estimate_confirm3")
                        ]}
                        buttons={[
                            {
                                label: t("Cancel"),
                                onClick: onClose
                            },
                            {
                                label: t("accept"),
                                onClick: () => {
                                    handleNewEstimate();
                                    onClose();
                                }
                            }
                        ]}
                    />
                );
            }
        });
    }

    const handleProjectNameChange = (event: any) => {
        if (projectPlan.name !== undefined) {
            projectPlanDispatch({
                type: 'changeProjectName',
                newProjectName: event.target.value
            });
        }
        setProjectName(event.target.value);
    }

    const handleProjectTypeChange = (proType: any) => {
        if (projectPlan.idProjectType !== undefined) {
            cancelProjectEstimation();
        }

        setElementsForms([{idElemento: "", tipoElemento: "", numeroElementos: 1, tamanio: 2, complejidad: 2}]);
        setSelectedProjectType(proType);
        fillElementTypes(proType.value);
    }

    const handleMeasurementChange = (event: any) => {
        if (projectPlan.idProjectType !== undefined) {
            projectPlanDispatch({
                type: 'changeMeasurementStandard',
                newMeasurementStandard: event.target.value === "" ? null : Number(event.target.value)
            });
        }
        setIdSelectedMeasurement(event.target.value);
    }

    const handleElementTypeChange = (idElementType: number, index: number) => {
        let elems = elementsForm.map((elemForm: any) => ({...elemForm}));
        elems[index].idElemento = idElementType;
        elems[index].tipoElemento = fetchedData.elementTypes.find((x: any) => x.idElementType == idElementType).name;
        setElementsForms(elems);
    }

    const handleNoElementsChange = (num: number, index: number) => {
        let elems = elementsForm.map((elemForm: any) => ({...elemForm}));
        elems[index].numeroElementos = num;
        setElementsForms(elems);
    }

    const handleElementSizeChange = (idSize: number, index: number) => {
        const elems = elementsForm.map((elemForm: any) => ({...elemForm}));
        elems[index].tamanio = idSize;
        setElementsForms(elems);
    }

    const handleElementComplexityChange = (idComplexity: number, index: number) => {
        const elems = elementsForm.map((elemForm: any) => ({...elemForm}));
        elems[index].complejidad = idComplexity;
        setElementsForms(elems);
    }

    const validElementSizeComplexity = (idElement: number, idSize: number, idComplexity: number) => {
        return elementsForm.every(
            (elementForm: any) =>
                elementForm.idElemento !== idElement ||
                elementForm.tamanio !== idSize ||
                elementForm.complejidad !== idComplexity,
        );
    }

    const addElementTypeForm = (e: any) => {
        //e.preventDefault();
        let elems = [...elementsForm, {idElemento: "", tipoElemento: "", numeroElementos: 1, tamanio: "", complejidad: ""}];
        setElementsForms(elems);
    }

    const removeElementTypeForm = (e: any, index: number) => {
        let elems = elementsForm.map((elemForm: any) => ({...elemForm}));
        elems.splice(index, 1);
        setElementsForms(elems);
    }

    return(
        <Card elevation={2} >
            <CardContent>
                <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end" >
                    <Grid container item xs={12} md={12} alignItems="center" justifyContent='space-between' >
                        <Typography variant="h5" color='#050536' >
                            {t("estimation_page.generate_project")}
                        </Typography>

                        <HelperDialog
                            title={t(helperDialogs["estimation"][4].title)}
                            body={t(helperDialogs["estimation"][4].body)}
                            dialogIndex={4}
                            totalDialogs={helperDialogs["estimation"].length}
                            handleNext={helperDialogsConfig.handleActiveHelperChange}
                            isOpen={helperDialogsConfig.activeHelperDialogIndex === 4}
                            handleClose={helperDialogsConfig.handleClose}
                            position='left'
                        >
                            <div>
                                <TrueffortButton
                                    id="new-template-button"
                                    size="small"
                                    variant="outlined"
                                    color="indigo"
                                    fullWidth={matches}
                                    startIcon={<EditNoteIcon />}
                                    onClick={() =>
                                        window.location.replace("/projectTypes")
                                    }
                                >
                                    {t("estimation_page.create_template")}
                                </TrueffortButton>
                            </div>
                        </HelperDialog>
                    </Grid>
                    <HelperDialog
                        title={t(helperDialogs["estimation"][1].title)}
                        body={t(helperDialogs["estimation"][1].body)}
                        dialogIndex={1}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 1}
                        handleClose={helperDialogsConfig.handleClose}
                        position='right'
                    >
                        <Grid item xs={12} md={4} >
                            <InputLabel
                                sx={{
                                    fontWeight: 700,
                                    color: '#4A4A4A',
                                    fontSize: '0.75rem'
                                }}
                            >
                                {t("leader.assign")}
                            </InputLabel>
                            <Select
                                name="idLeader"
                                placeholder={t("leader.assign")}
                                isMulti
                                value={idsLeader}
                                onChange={(option: any) => {
                                    //console.log(option);
                                    setIdsLeader(option)}
                                }
                                options={
                                    fetchedData.leaders.map((leader: any, k: number) => {
                                        return ({
                                            value: leader.idUser,
                                            label: (leader?.name ? leader.name : '') + ' ' + 
                                              (leader?.lastName ? leader.lastName : '') + ' ' +
                                              (leader?.email ? '('+ leader.email + ')' : '')
                                        })
                                    })
                                }
                                styles={styleReactSelect}
                            >
                            </Select>
                        </Grid>
                    </HelperDialog>

                    <HelperDialog
                        title={t(helperDialogs["estimation"][2].title)}
                        body={t(helperDialogs["estimation"][2].body)}
                        dialogIndex={2}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 2}
                        handleClose={helperDialogsConfig.handleClose}
                        position='right'
                    >
                        <Grid item xs={12} md={4} >
                            <TrueffortTextField
                                id="project-name"
                                name="name"
                                size="small"
                                fullWidth
                                placeholder={t("project_page.name_placeholder") + '...'}
                                value={projectName}
                                error={required && !projectName}
                                onChange={handleProjectNameChange}
                                requiredlabel={true}
                                labelstring={t("project_tasks_page.title")}
                            />
                        </Grid>
                    </HelperDialog>

                    <HelperDialog
                        title={t(helperDialogs["estimation"][3].title)}
                        body={t(helperDialogs["estimation"][3].body)}
                        dialogIndex={3}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 3}
                        handleClose={helperDialogsConfig.handleClose}
                    >
                        <Grid item xs={12} md={4} >
                            <InputLabel
                              required
                                sx={{
                                    fontWeight: 700,
                                    color: '#4A4A4A',
                                    '& .MuiInputLabel-asterisk': {
                                        color: "#E4555A"
                                    },
                                    fontSize: '0.75rem'
                                }}
                            >
                                {t("estimation_page.project_type")}
                            </InputLabel>
                            <Select 
                              name='idLeaderopt'
                              value={selectedProjectType}
                              onChange={(option:any) => handleProjectTypeChange(option)}
                              options={fetchedData.projectTypes
                                .filter((projectType: any) => projectType.active)
                                .map((projectType: any, k: number) => {
                                  return ({value: projectType.idProjectType, label:projectType.name})
                                })}
                              styles={required && !selectedProjectType?.value ? styleReactSelectRequired : styleReactSelect}
                            />
                        </Grid>
                    </HelperDialog>
                    <Grid item xs={12} md={4} >
                      <DatePicker
                        style={{width:'100%'}}
                        dateFormat="dd/MM/yyyy"
                        selected={registredDate}
                        onChange={(date:any) => setRegistredDate(dateToDateLocale(date))}
                        customInput={<TrueffortTextField 
                          size="small" 
                          name="registredDate"
                          fullWidth
                          labelstring={t("estimation_page.registred_date")}
                        />}
                      />
                    </Grid>
                    <HelperDialog
                        title={t(helperDialogs["estimation"][5].title)}
                        body={t(helperDialogs["estimation"][5].body)}
                        dialogIndex={5}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 5}
                        handleClose={helperDialogsConfig.handleClose}
                        position='right'
                    >
                        <Grid item xs={12} md={4} >
                            <TrueffortSelect
                                id="measurement-stantard"
                                size="small"
                                fullWidth
                                value={idSelectedMeasurement}
                                displayEmpty
                                onChange={handleMeasurementChange}
                                labelstring={t("estimation_page.measurement_standard")}
                            >
                                <MenuItem value="" >{t("Select")}</MenuItem>
                                {measurementStandards.map((estandar: { id: number, nombre: string }) =>
                                    <MenuItem
                                        key={estandar.id}
                                        value={estandar.id}
                                    >
                                        {estandar.nombre}
                                    </MenuItem>
                                )}
                            </TrueffortSelect>
                        </Grid>
                    </HelperDialog>

                    <Grid item xs={12} md={12} >
                        <Typography variant="h5" color='#050536' >
                            {t("estimation_page.new_element_types")}
                        </Typography>
                    </Grid>

                    {elementsForm.map((elem: any, k: number) => (
                        <ElementsForm
                            key={'elemNo.' + k}
                            t={t}
                            index={k}
                            requiredTag={required}
                            elementsFormSize={elementsForm.length}
                            elementForm={elementsForm[k]}
                            elementTypes={fetchedData.elementTypes}
                            changeHandlers={{
                                handleElementTypeChange: handleElementTypeChange,
                                handleNoElementsChange: handleNoElementsChange,
                                handleElementSizeChange: handleElementSizeChange,
                                handleElementComplexityChange: handleElementComplexityChange
                            }}
                            addElementTypeForm={addElementTypeForm}
                            removeElementTypeForm={removeElementTypeForm}
                            validOption={validElementSizeComplexity}
                            projectCreated={projectPlan.idProjectType !== undefined}
                            helperDialogsConfig={helperDialogsConfig}
                        />
                    ))}
                </Grid>
            </CardContent>

            <CardActions sx={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }} >
                {projectPlan.idProjectType === undefined ?
                    <HelperDialog
                        title={t(helperDialogs["estimation"][12].title)}
                        body={t(helperDialogs["estimation"][12].body)}
                        dialogIndex={12}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 12}
                        handleClose={helperDialogsConfig.handleClose}
                    >
                        <div>
                            <TrueffortButton
                                id='estimate-button'
                                color='primary'
                                variant='contained'
                                fullWidth={matches}
                                startIcon={<TimelapseIcon />}
                                onClick={handleEstimate}
                            >
                                {t("estimation_page.estimate")}
                            </TrueffortButton>
                        </div>
                    </HelperDialog> :
                    <HelperDialog
                        title={t(helperDialogs["estimation"][12].title_alt)}
                        body={t(helperDialogs["estimation"][12].body_alt)}
                        dialogIndex={12}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={helperDialogsConfig.handleActiveHelperChange}
                        isOpen={helperDialogsConfig.activeHelperDialogIndex === 12}
                        handleClose={helperDialogsConfig.handleClose}
                    >
                        <div>
                            <TrueffortButton
                                id='new-estimate-button'
                                color='primary'
                                variant='contained'
                                fullWidth={matches}
                                startIcon={<TimelapseIcon />}
                                onClick={newEstimateWarning}
                            >
                                {t("estimation_page.new_estimate")}
                            </TrueffortButton>
                        </div>
                    </HelperDialog>
                }
            </CardActions>
        </Card>
    );
}

function ElementsForm(props: any) {
  //console.log(props);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [idSelectedElementType, setIdSelectedElementType] = React.useState(props.elementForm.idElemento);

    if (props.elementForm.idElemento !== idSelectedElementType) {
        setIdSelectedElementType(props.elementForm.idElemento);
    }

    const handleElementTypeChange = (event: any) => {
        if (props.validOption(event.target.value, props.elementForm.tamanio, props.elementForm.complejidad) ||
            props.elementForm.tamanio === "" || props.elementForm.complejidad === "") {
            setIdSelectedElementType(event.target.value);
            props.changeHandlers.handleElementTypeChange(event.target.value, props.index);
        }
    }

    const handleElementSizeChange = (event: any) => {
        if (props.validOption(props.elementForm.idElemento, event.target.value, props.elementForm.complejidad) ||
            props.elementForm.idElemento === "" || props.elementForm.complejidad === "") {
            props.changeHandlers.handleElementSizeChange(event.target.value, props.index);
        }
    }

    const handleElementComplexityChange = (event: any) => {
        if (props.validOption(props.elementForm.idElemento, props.elementForm.tamanio, event.target.value) ||
            props.elementForm.idElemento === "" || props.elementForm.tamanio === "") {
            props.changeHandlers.handleElementComplexityChange(event.target.value, props.index)
        }
    }

    return (
        <>
            {(props.index === 0)  ?
                <>
                    <Grid item xs={12} md={4} >
                        <TrueffortSelect
                            id="element-type-select-base"
                            size="small"
                            value={idSelectedElementType}
                            fullWidth
                            displayEmpty
                            disabled={props.projectCreated}
                            error={props.requiredTag && !idSelectedElementType}
                            onChange={handleElementTypeChange}
                            requiredlabel={true}
                            labelstring={props.t("project_page.elem_type")}
                        >
                            <MenuItem
                                value=""
                                disabled
                            >
                                {props.t("Select")}
                            </MenuItem>
                            {props.elementTypes.map((elementType: any, k: number) =>
                                <MenuItem
                                    key={elementType.idElementType}
                                    value={elementType.idElementType}
                                >
                                    {elementType.name}
                                </MenuItem>
                            )}
                        </TrueffortSelect>
                    </Grid>

                    <HelperDialog
                        title={props.t(helperDialogs["estimation"][6].title)}
                        body={props.t(helperDialogs["estimation"][6].body)}
                        dialogIndex={6}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={props.helperDialogsConfig.handleActiveHelperChange}
                        isOpen={props.helperDialogsConfig.activeHelperDialogIndex === 6}
                        handleClose={props.helperDialogsConfig.handleClose}
                        position='right'
                    >
                        <Grid item xs={12} md={2} >
                            <TrueffortTextField
                                id='base-elements-quantity'
                                type="number"
                                size="small"
                                fullWidth
                                disabled={props.projectCreated}
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 10,
                                        style: { background:'#F5F5F5' }
                                    }
                                }}
                                placeholder={props.t("estimation_page.number_elements")}
                                value={props.elementForm.numeroElementos}
                                error={props.requiredTag && !props.elementForm.numeroElementos}
                                onChange={
                                    (event: any) =>
                                        props.changeHandlers.handleNoElementsChange(parseInt(event.target.value), 0)
                                }
                                requiredlabel={false}
                                labelstring={props.t("estimation_page.number_elements")}
                            />
                        </Grid>
                    </HelperDialog>

                    <HelperDialog
                        title={props.t(helperDialogs["estimation"][7].title)}
                        body={props.t(helperDialogs["estimation"][7].body)}
                        dialogIndex={7}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={props.helperDialogsConfig.handleActiveHelperChange}
                        isOpen={props.helperDialogsConfig.activeHelperDialogIndex === 7}
                        handleClose={props.helperDialogsConfig.handleClose}
                    >
                        <Grid item xs={12} md={2} >
                            <TrueffortSelect
                                id='base-elements-size'
                                size="small"
                                fullWidth
                                disabled={props.projectCreated}
                                value={props.elementForm.tamanio}
                                placeholder={props.t("estimation_page.element_size")}
                                labelstring={props.t("estimation_page.element_size")}
                                onChange={handleElementSizeChange}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                >
                                    {props.t("Select")}
                                </MenuItem>
                                {sizeOptions.map((opcion: {idSize: number, size: string}) =>
                                    <MenuItem
                                        key={opcion.idSize}
                                        value={opcion.idSize}
                                    >
                                        {props.t(opcion.size)}
                                    </MenuItem>
                                )}
                            </TrueffortSelect>
                        </Grid>
                    </HelperDialog>

                    <HelperDialog
                        title={props.t(helperDialogs["estimation"][8].title)}
                        body={props.t(helperDialogs["estimation"][8].body)}
                        dialogIndex={8}
                        totalDialogs={helperDialogs["estimation"].length}
                        handleNext={props.helperDialogsConfig.handleActiveHelperChange}
                        isOpen={props.helperDialogsConfig.activeHelperDialogIndex === 8}
                        handleClose={props.helperDialogsConfig.handleClose}
                    >
                        <Grid item xs={12} md={2} >
                            <TrueffortSelect
                                id='base-elements-complexity'
                                size="small"
                                fullWidth
                                displayEmpty
                                disabled={props.projectCreated}
                                value={props.elementForm.complejidad}
                                placeholder={props.t("estimation_page.element_complexity")}
                                labelstring={props.t("estimation_page.element_complexity")}
                                onChange={handleElementComplexityChange}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                >
                                    {props.t("Select")}
                                </MenuItem>

                                {complexityOptions.map((opcion: {idComplexity: number, complexity: string}) =>
                                    <MenuItem
                                        key={opcion.idComplexity}
                                        value={opcion.idComplexity}
                                    >
                                        {props.t(opcion.complexity)}
                                    </MenuItem>
                                )}
                            </TrueffortSelect>
                        </Grid>
                    </HelperDialog>

                    <Grid container item xs={12} md={1} justifyContent={matches ? "flex-end" : "flex-start"} >
                        <HelperDialog
                            title={props.t(helperDialogs["estimation"][9].title)}
                            body={props.t(helperDialogs["estimation"][9].body)}
                            dialogIndex={9}
                            totalDialogs={helperDialogs["estimation"].length}
                            handleNext={props.helperDialogsConfig.handleActiveHelperChange}
                            isOpen={props.helperDialogsConfig.activeHelperDialogIndex === 9}
                            handleClose={props.helperDialogsConfig.handleClose}
                        >
                            <div>
                                <TrueffortFABButton
                                    size="small"
                                    color='primary'
                                    sx={{ boxShadow: 0 }}
                                    disabled={props.projectCreated}
                                    onClick={(e: any) => props.addElementTypeForm(e)}
                                >
                                    <AddIcon />
                                </TrueffortFABButton>
                            </div>
                        </HelperDialog>
                    </Grid>
                </> :

                <>
                    <Grid item xs={12} md={4} >
                        {matches &&
                            <TrueffortInputLabel
                                requiredlabel={true}
                                labelstring={props.t("project_page.elem_type")}
                            />
                        }
                        <TrueffortSelect
                            id="element-type-select-base"
                            size="small"
                            value={idSelectedElementType}
                            fullWidth
                            displayEmpty
                            disabled={props.projectCreated}
                            error={props.requiredTag && !idSelectedElementType}
                            onChange={handleElementTypeChange}
                        >
                            <MenuItem
                                value=""
                                disabled
                            >
                                {props.t("Select")}
                            </MenuItem>
                            {props.elementTypes.map((elementType: any, k: number) =>
                                <MenuItem
                                    key={elementType.idElementType}
                                    value={elementType.idElementType}
                                >
                                    {elementType.name}
                                </MenuItem>
                            )}
                        </TrueffortSelect>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        {matches &&
                            <TrueffortInputLabel
                                labelstring={props.t("estimation_page.number_elements")}
                            />
                        }
                        <TrueffortTextField
                            id='base-elements-quantity'
                            type="number"
                            size="small"
                            fullWidth
                            disabled={props.projectCreated}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: 10,
                                    style: { background:'#F5F5F5' }
                                }
                            }}
                            placeholder={props.t("estimation_page.number_elements")}
                            value={props.elementForm.numeroElementos}
                            error={props.requiredTag && !props.elementForm.numeroElementos}
                            onChange={
                                (event: any) =>
                                    props.changeHandlers.handleNoElementsChange(parseInt(event.target.value), props.index)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={2} >
                        {matches &&
                            <TrueffortInputLabel
                                labelstring={props.t("estimation_page.element_size")}
                            />
                        }
                        <TrueffortSelect
                            id='base-elements-size'
                            size="small"
                            fullWidth
                            displayEmpty
                            disabled={props.projectCreated}
                            value={props.elementForm.tamanio}
                            error={props.requiredTag && !props.elementForm.tamanio}
                            placeholder={props.t("estimation_page.element_size")}
                            onChange={handleElementSizeChange}
                        >
                            <MenuItem
                                value=""
                                disabled
                            >
                                {props.t("Select")}
                            </MenuItem>
                            {sizeOptions.map((opcion: {idSize: number, size: string}) =>
                                <MenuItem
                                    key={opcion.idSize}
                                    value={opcion.idSize}
                                >
                                    {props.t(opcion.size)}
                                </MenuItem>
                            )}

                        </TrueffortSelect>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        {matches &&
                            <TrueffortInputLabel
                                labelstring={props.t("estimation_page.element_complexity")}
                            />
                        }
                        <TrueffortSelect
                            id='base-elements-complexity'
                            size="small"
                            fullWidth
                            displayEmpty
                            disabled={props.projectCreated}
                            value={props.elementForm.complejidad}
                            error={props.requiredTag && !props.elementForm.complejidad}
                            placeholder={props.t("estimation_page.element_complexity")}
                            onChange={handleElementComplexityChange}
                        >
                            <MenuItem
                                value=""
                                disabled
                            >
                                {props.t("Select")}
                            </MenuItem>

                            {complexityOptions.map((opcion: { idComplexity: number, complexity: string }) =>
                                <MenuItem
                                    key={opcion.idComplexity}
                                    value={opcion.idComplexity}
                                >
                                    {props.t(opcion.complexity)}
                                </MenuItem>
                            )}
                        </TrueffortSelect>
                    </Grid>
                    <Grid container item xs={12} md={1} justifyContent={matches ? "flex-end" : "flex-start"} >
                        {!props.projectCreated &&
                            <Fab
                                size="small"
                                color='default'
                                sx={{ boxShadow: 0 }}
                                onClick={(e: any) => props.removeElementTypeForm(e, props.index)}
                            >
                                <RemoveIcon />
                            </Fab>
                        }
                    </Grid>
                </>
            }
        </>
    );

}