import { ApiProtectedWS as http } from "../api/axios";
import { baseURL } from "../util/Constants";

class SiteService {

  findAll() {
    return http?.get(baseURL.activitiesapi.sites);
  }

  create(data: any) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }, transformRequest: () => data
    }
    //http?.options("/", args)
    return http?.post(baseURL.activitiesapi.sites + "/", data, options);
  }

  updateImage(id: string, data: any) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }, transformRequest: () => data
    }
    //http?.options("/", args)
    return http?.put(baseURL.activitiesapi.sites + `/${id}`, data, options);
  }

  delete(id: number) {
    return http?.delete(baseURL.activitiesapi.sites + `/${id}`);
  }

  setRelevantSitesByIdsUser(idSite: number, isRelevant: boolean, idsUsers: any) {
    return http?.put(baseURL.activitiesapi.sites + "/setRelevant/" + idSite + '/' + isRelevant, { ids: idsUsers });
  }

  setRelevantSitesByIdsProfile(idSite: number, isRelevant: boolean, idsProfile: any) {
    return http?.put(baseURL.activitiesapi.sites + "/setRelevantprofile/" + idSite + '/' + isRelevant, { ids: idsProfile });
  }

  categorize(idSite: number, idCategorysite: number) {
    return http?.put(baseURL.activitiesapi.sites + "/categorize/" + idSite + '/category/' + idCategorysite);
  }
}
export default SiteService;