import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class WorkstationService {

    getAll() {
        return http?.get(baseURL.activitiesapi.workstations + "/");
    }

    get(id: number) {
        return http?.get(baseURL.activitiesapi.workstations + `/${id}`);
    }

    getByInstance(id: number) {
        return http?.get(baseURL.activitiesapi.workstations + `/findByInstance/${id}`);
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.workstations + "/", data);
    }

    update(idInstance: number, id: number, data: any) {
        return http?.patch(baseURL.activitiesapi.workstations + `/${idInstance}/${id}`, data);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.workstations + `/${id}`);
    }

    getAllInvisible() {
      return http?.get(baseURL.activitiesapi.workstations + `/invisibleAll`);
    }

    getUpdateInvisible(data:any) {
      return http?.put(baseURL.activitiesapi.workstations + '/invworkstation', data)
    }

    getCreateInvisible(data:any) {
      return http?.post(baseURL.activitiesapi.workstations + '/invworkstation', data)
    }
}

export default WorkstationService;
