import SiteWrapper from "../SiteWrapper.react";
import React, {createContext} from "react";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../contexts/AuthenticationContext";
import {User} from "../util/Interfaces";
import {
    Container, Card, Box, MenuItem, CircularProgress, Menu, Divider, TablePagination
} from "@mui/material";
import EstimationService from "../services/estimations.service";
import {toast} from "react-toastify";
import { TrueffortSearchInput, TrueffortNavButton, TrueffortButtonSmall} from "../MUIstyle/MUICustom";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ProjectService from "../services/project.service";
import UserService from "../services/user.service";
import TaskStateService from "../services/taskState.service";
import {List as ListIcon, AutoAwesomeMosaic as AutoAwesomeMosaicIcon, Groups as GroupsIcon,
    KeyboardArrowDown, Dashboard as DashboardIcon, CheckCircle as CheckCircleIcon} from '@mui/icons-material';
import DragAndDropView from "../components/projectDaily/DragAndDropView";
import TaskCard from "../components/projectDaily/TaskCard";
import CustomIconTrueffortScreen from "../components/custom/CustomIconTrueffortScreen";
import "../css/ProjectDaily.css";
import ProjectTaskService from "../services/projectTask.service";


function ProjectDailyPage() {
    const {t} = useTranslation();
    const {user} = useAuthContext() as { user: User };
    const features = user?.instance?.license ? user?.instance?.license?.features : {};
    const estimationService = React.useMemo(() => new EstimationService(), []);
    const projectTaskService = React.useMemo(() => new ProjectTaskService(), []);
    const projectService = React.useMemo(() => new ProjectService(), []);
    const userService = React.useMemo(() => new UserService(), []);
    const taskStateService = React.useMemo(() => new TaskStateService(), []);
    const [loading, setLoading] = React.useState(true);
    const [taskStates, setTaskStates] = React.useState([]);
    const [taskTypes, setTaskTypes] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [responseProjects, setResponseProjects] = React.useState([]);
    const [idView, setIdView] = React.useState(1); // 1=list, 2=detail, 3=drag&drop
    const [selectedIndexTask, setSelectedIndexTask] = React.useState("");
    const [selectedTask, setSelectedTask] = React.useState("");
    const [selectedIndexTaskForChangeStatus, setSelectedIndexTaskForChangeStatus] = React.useState("");
    const [searchFilterValue, setSearchFilterValue] = React.useState("");
    const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
    const openOptions = Boolean(anchorElemFilter);
    const [menuNumber, setMenuNumber] = React.useState(0);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [selectedProjects, setSelectedProjects] = React.useState([]);
    const [tasksFiltered, setTasksFiltered] = React.useState([]);
    const [tasksAll, setTasksAll] = React.useState([]);
    const [tasksPage, setTasksPage] = React.useState(0);
    const [tasksCount, setTasksCount] = React.useState(0);

    React.useEffect(() => {
        fillProjects();
        fillUsers();
        fillTaskStates();
    }, []);

    React.useEffect(() => {
        if (taskStates.length > 1) {
            setSelectedStatuses([taskStates[1]]);
        }
    }, [taskStates]);

    React.useEffect(() => {
        fillProjectInfo();
    }, [selectedProjects]);

    React.useEffect(() => {
        if (selectedUsers.length > 0 || selectedStatuses.length > 0) {
            handleFilterTasks();
        }
    }, [responseProjects]);

    React.useEffect(() => {
        handleFilterTasks();
    }, [selectedStatuses, selectedUsers, searchFilterValue]);

    React.useEffect(() => {
        fillTaskTypes();
    }, [projects]);

    const fillProjectInfo = () => {
      setLoading(true);
      let req = { listProjectsId: getArrProjectsFilterIdsFromValues() };
      projectTaskService.getByProjects(req).then(({data: response}:any) => {
        if (Array.isArray(response.data)) {
          setResponseProjects(response.data);
          setTasksAll(response.data);
          //console.log("TASKS", response.data);
          if (selectedStatuses.length == 0 && selectedUsers.length == 0) {
            setTasksFiltered(response.data);
          }

          // Si ya está una tarea seleccionada, tomarla del arr actualizado
          if (selectedIndexTask != "") {
            let tk = response.data.find((tk:any) => tk.idProjectTask == selectedIndexTask);
            setSelectedTask(tk);
          }
          setTimeout(function(){setLoading(false);}, 1000);
        } else {
          //toast.warning(t(response.code));
          setLoading(false);
        }
      });
    }

    const fillTaskStates = () => {
      // @ts-ignore
      taskStateService.getAll().then(({data: response}) => {
        if (Array.isArray(response.data)) {
          setTaskStates(response.data);
        }
      });
    }

    const fillTaskTypes = () => {
        let projectTypesIds = getArrProjectsTypesFilterIdsFromValues();
        if (projectTypesIds.length == 0) {
            projectTypesIds = projects.map((st: any) => (st.projectType.idProjectType));
        }
        //console.log("projectTypesIds", projectTypesIds);
        if (projectTypesIds.length > 0) {
            const entrada = {
                project_types: projectTypesIds,
                root_level: "tasktypes"
            }

            // @ts-ignore
            estimationService.getAccumulatedEstimate(entrada).then(({data: response}) => {
                //console.log("response.tasktypes", response.tasktypes);
                if (response.status === "warning") {
                    toast.error(t(response.code));
                } else {
                    setTaskTypes(response.tasktypes);
                }
                setLoading(false);
            });
        }
    }

    const fillUsers = () => {
        // @ts-ignore
        userService.getAllComplete().then(({data: response}) => {
            if (Array.isArray(response)) {
                setUsers(response.filter((u: any) => u.isEnabled));
            }
        });
    }

    const fillProjects = () => {
      // @ts-ignore
      projectService.getAll().then(({data: response}) => {
        if (Array.isArray(response.data)) {
          setProjects(response.data);
        }
      });
    }

    function getArrProjectsFilterIdsFromValues() {
      let arr: number[] = [];
      selectedProjects.map((st: any) => {
        arr.push(st.idProject);
      });
      return arr;
    }

    function getArrProjectsTypesFilterIdsFromValues() {
      let arr: number[] = [];
      selectedProjects.map((st: any) => {
        arr.push(st.projectType.idProjectType);
      });
      return arr;
    }

    function getArrStatusFilterIdsFromValues() {
      let arr: number[] = [];
      selectedStatuses.map((st: any) => {
        arr.push(st.idStatus);
      });
      return arr;
    }

    function getArrUsersFilterIdsFromValues() {
      let arr: number[] = [];
      selectedUsers.map((usr: any) => {
        arr.push(usr.idUser);
      });
      return arr;
    }

    function handleFilterTasks() {
        let arrStatus = getArrStatusFilterIdsFromValues();
        let arrUsersSelectedIds = getArrUsersFilterIdsFromValues();
        let tasksAux:any[] = [];
        if (arrStatus.length > 0 || arrUsersSelectedIds.length > 0) {
            tasksAll.forEach((tk:any) => {
                // Aplican los 2 filtros
                if (arrStatus.length > 0 && arrUsersSelectedIds.length > 0) {
                    if (tk.userList.map((u:any) => u.idUser).some((r:any) => arrUsersSelectedIds.includes(r)) && arrStatus.includes(tk.idStatus)) {
                        tasksAux.push(tk);
                    }
                } else if (arrUsersSelectedIds.length > 0) {  // Aplica filtro de usuarios
                    let minIdStatus = 0;
                    for (let i = 0; i < arrUsersSelectedIds.length; i++) {
                      for (let j = 0; j < tk.userProjectTaskList.length; j++) {
                        if (arrUsersSelectedIds[i] == tk.userProjectTaskList[j].idUser) {
                          minIdStatus = minIdStatus == 0 ? tk.userProjectTaskList[j].status.idStatus :
                            tk.userProjectTaskList[j].status.idStatus < minIdStatus ? tk.userProjectTaskList[j].status.idStatus : minIdStatus;
                        }
                      }
                    }
                    //if (tk.userList.map((u:any) => u.idUser).some((r:any) => arrUsersSelectedIds.includes(r))) {
                    if (minIdStatus != 0) {
                      tk.idStatus = minIdStatus;
                      tasksAux.push(tk);
                    }
                    //}
                } else {    // Aplica filtro de status
                    if (arrStatus.includes(tk.idStatus)) {
                        tasksAux.push(tk);
                    }
                }
            });

            // Filtro de búsqueda
            if (searchFilterValue != "") {
                let items = tasksAux.filter((data : any) => {
                    if (data.taskTypeName.toLowerCase().includes(searchFilterValue.toLowerCase())
                        || data.elementName.toLowerCase().includes(searchFilterValue.toLowerCase()) ) {
                        return data;
                    }
                });
                tasksAux = items;
            }

            setTasksCount(tasksAux.length);
            setTasksPage(0);
            setSelectedTask("");
            setSelectedIndexTask("");
            setTasksFiltered(tasksAux);
        } else {
            setTasksCount(tasksAll.length);
            setTasksPage(0);
            setTasksFiltered(tasksAll);
        }
    }

    const handleClickMenu = (event:any, value:any) => {
        setAnchorElemFilter(event.currentTarget);
        setMenuNumber(value);
    }

    const handleChangeUsers = (id:any) => {
        setAnchorElemFilter(null);
        let us = users.find((us:any) => us.idUser == id);
        let aux = selectedUsers.map((u:any) => u);
        let alreadySelected = selectedUsers.find((u:any) => u.idUser == id);
        if (typeof alreadySelected == "undefined") {
            aux.push(us);
        } else {    // Si ya estaba seleccionarlo, deseleccionarlo
            aux = aux.filter((item:any) => item.idUser != id);
        }
        setSelectedUsers(aux);
    }

    const handleChangeStatus = (id:any) => {
        setAnchorElemFilter(null);
        let us = taskStates.find((s:any) => s.idStatus == id);
        let aux = selectedStatuses.map((s:any) => s);
        let alreadySelected = selectedStatuses.find((s:any) => s.idStatus == id);
        if (typeof alreadySelected == "undefined") {
            aux.push(us);
        } else {    // Si ya estaba seleccionarlo, deseleccionarlo
            aux = aux.filter((item:any) => item.idStatus != id);
        }
        setSelectedStatuses(aux);
    }

    const handleChangeProject = (id:any) => {
        let aux;
        setAnchorElemFilter(null);
        if (idView != 3) {
            let us = projects.find((p:any) => p.idProject == id);
            aux = selectedProjects.map((p:any) => p);
            let alreadySelected = selectedProjects.find((p:any) => p.idProject == id);
            if (typeof alreadySelected == "undefined") {
                aux.push(us);
            } else {    // Si ya estaba seleccionarlo, deseleccionarlo
                aux = aux.filter((item:any) => item.idProject != id);
            }
        } else {
            // Si es la vista drag and drop, sólo permitir seleccionar uno
            aux = [projects.find((p:any) => p.idProject == id)];
        }

        setSelectedProjects(aux);
    }

    const handleChangeTaskStatus = (idTaskChange:number, idStatus:number) => {
        setAnchorElemFilter(null);
        // @ts-ignore
        projectTaskService.changeStatus(idTaskChange, idStatus).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                toast.success(t("daily_page.msg_status_change_success"));
                // Actualizar la lista de tareas
                fillProjectInfo();
            }
        });
    }

    const handleChangeTasksPage = (event:any, newPage:number) => {
        setTasksPage(newPage);
    }

    return (
        <SiteWrapper>
            <Container>
                <Grid container>
                    <Grid xs={12}>
                        <h3>{t("daily_page.title")}</h3>
                    </Grid>
                    <Grid xs={12}>
                      {features.dailyProjectTracking && idView == 1 ?
                        <TrueffortButtonSmall
                          id="idBtnListView"
                          color="secondary"
                          variant="contained"
                          size="small"
                          >
                          <ListIcon sx={{fontSize: ".8rem"}} />
                          {t("daily_page.list_view")}
                        </TrueffortButtonSmall> : features.dailyProjectTracking ?
                        <TrueffortButtonSmall
                          id="idBtnListView"
                          color="secondary"
                          variant="outlined"
                          size="small"
                          onClick={() => setIdView(1)}
                          >
                          <ListIcon sx={{fontSize: ".8rem"}} />
                          {t("daily_page.list_view")}
                        </TrueffortButtonSmall> : null
                      }
                      {features.dailyProjectTrackingDragAndDrop && idView == 3 ?
                        <TrueffortButtonSmall
                          id="idBtnDragAndDropView"
                          color="primary"
                          variant="contained"
                          size="small"
                          >
                          <AutoAwesomeMosaicIcon sx={{fontSize: ".8rem"}} />
                          {t("daily_page.drag_and_drop_view")}
                        </TrueffortButtonSmall> : features.dailyProjectTrackingDragAndDrop ?
                        <TrueffortButtonSmall
                            id="idBtnDragAndDropView"
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                if (selectedStatuses.length > 0) {
                                    setSelectedStatuses([]);
                                }
                                if (selectedProjects.length > 1) {
                                    setSelectedProjects([selectedProjects[0]]);
                                } else if (selectedProjects.length == 0 && projects.length > 0) {
                                    setSelectedProjects([projects[0]]);
                                }
                                setIdView(3);
                            }}
                            >
                            <AutoAwesomeMosaicIcon sx={{fontSize: ".8rem"}} />
                            {t("daily_page.drag_and_drop_view")}
                        </TrueffortButtonSmall> : null
                      }
                    </Grid>
                </Grid>

                <Card sx={{marginTop: "1rem", marginBottom: "1rem", backgroundColor: "#ffffff", paddingLeft: ".4rem"}}>
                <Grid container spacing={1} sx={{marginTop: ".4rem", marginBottom: ".2rem"}}>
                    <Grid xs={12} md={3}>
                        <TrueffortSearchInput
                            id="idSearchInput"
                            placeholder={t("search")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(e: any) => setSearchFilterValue(e.target.value)}
                        />
                    </Grid>
                    <Grid xs={12} md={selectedProjects.length >= 1 ? 3 : 2}>
                        <TrueffortNavButton
                            id="idSelectedProject"
                            size="small"
                            fullWidth
                            variant="contained"
                            onClick={(event:any) => handleClickMenu(event, 3)}
                            className={selectedProjects.length != 0 ?"active" : ""}
                            noneHeight={selectedProjects.length > 1 ? true: false}
                        >
                            <CustomIconTrueffortScreen icon={<DashboardIcon />} />
                            {selectedProjects.length == 0 ? t("dashboard.all_projects") : selectedProjects.map((u:any, i:number) => u.name + (i < (selectedProjects.length-1) ? ", " : ""))}
                            <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                        </TrueffortNavButton>
                    </Grid>
                    <Grid xs={12} md={selectedStatuses.length > 1 ? 3 : 2}>
                        <TrueffortNavButton
                            id="idSelectedStatuses"
                            size="small"
                            fullWidth
                            variant="contained"
                            onClick={(event:any) => handleClickMenu(event, 4)}
                            className={selectedStatuses.length != 0 ? "active" : ""}
                            noneHeight={selectedStatuses.length > 1 ? true: false}
                        >
                            <CustomIconTrueffortScreen icon={<CheckCircleIcon />} />
                            {selectedStatuses.length == 0 ? t("daily_page.all_status") : selectedStatuses.map((u:any, i:number) => u.name + (i < (selectedStatuses.length-1) ? ", " : ""))}
                            <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                        </TrueffortNavButton>
                    </Grid>
                    <Grid xs={12} md={selectedUsers.length > 1 ? 3 : 2}>
                        <TrueffortNavButton
                            id="idSelectedUsers"
                            size="small"
                            fullWidth
                            variant="contained"
                            onClick={(event:any) => handleClickMenu(event, 5)}
                            className={selectedUsers.length != 0 ? "active" : ""}
                            noneHeight={selectedUsers.length > 1 ? true: false}
                        >
                            <CustomIconTrueffortScreen icon={<GroupsIcon />} />
                            {selectedUsers.length == 0 ? t("dashboard.all_users") : selectedUsers.map((u:any, i:number) => u.name + (i < (selectedUsers.length-1) ? ", " : ""))}
                            <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                        </TrueffortNavButton>
                    </Grid>
                    <Grid md={3}></Grid>
                </Grid>
                </Card>

                <Menu id="idElementsFilterDashboards"
                    anchorEl={anchorElemFilter}
                    open={openOptions}
                    onClose={() => setAnchorElemFilter(null)}
                >
                    {menuNumber == 3 && <div>
                        <MenuItem id="idProject_0" key={-1} disabled={idView == 3} sx={{fontSize: ".8rem!important"}} onClick={() => setSelectedProjects([])}>
                            {t("all")}
                        </MenuItem>
                        <Divider />
                        {projects.map((p:any, i:number) => (
                            <MenuItem id={"idProject_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject(p.idProject)}>
                                {p.name}
                            </MenuItem>
                        ))}
                    </div>}
                    {menuNumber == 4 && <div>
                        <MenuItem id="idStatus_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setSelectedStatuses([])}>
                            {t("all")}
                        </MenuItem>
                        <Divider />
                        {taskStates.map((s:any, i:number) => (
                            <MenuItem id={"idStatus_"+(i+1)} key={i}
                                sx={{fontSize: ".8rem!important", color: selectedStatuses.findIndex((st:any) => st.idStatus == s.idStatus) != -1 ? "#777777" : ""}}
                                onClick={() => handleChangeStatus(s.idStatus)}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </div>}
                    {menuNumber == 5 && <div>
                        <MenuItem id="idUsers_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setSelectedUsers([])}>
                          {t("dashboard.all_users")}
                        </MenuItem>
                        <Divider />
                        {users.map((u:any, i:number) => (
                            <MenuItem id={"idUsers_"+(i+1)} key={i}
                                sx={{fontSize: ".8rem!important", color: selectedUsers.findIndex((us:any) => us.idUser == u.idUser) != -1 ? "#777777" : ""}}
                                onClick={() => handleChangeUsers(u.idUser)}>
                                {u.name + " " + u.lastName}
                            </MenuItem>
                        ))}
                    </div>}
                    {menuNumber == 6 && <div>
                        {taskStates.map((s:any, i:number) => (
                            <MenuItem id={"idStatus_"+(i+1)} key={i}
                                className={"item-status-" + s.idStatus}
                                sx={{fontSize: ".8rem!important"}}
                                onClick={() => handleChangeTaskStatus(selectedIndexTaskForChangeStatus, s.idStatus)}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </div>}
                </Menu>

                {loading &&
                  <div style={{margin: "0 auto", width: "100px"}}><CircularProgress /></div>
                }

                {!loading &&
                <Card sx={{padding: "0.6875rem 0.375rem"}}>
                    {idView == 1 &&
                    <React.Fragment>
                        {tasksFiltered
                            .slice(tasksPage * 5, tasksPage * 5 + 5)
                            .map((tk:any, i:number) =>
                            <TaskCard
                                key={tk.idProjectTask}
                                task={tk}
                                usersAll={users}
                                taskType={taskTypes?.find((taskT: any) => taskT.id === tk.idTaskType)}
                                statusList={taskStates}
                                _updateTaskStatus={fillProjectInfo}
                                _handleChangeTaskStatus={handleChangeTaskStatus}
                            />
                        )}
                        <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', justifyContent: "flex-end",
                                alignItems: "center", marginTop: '1em' }}>
                                <TablePagination
                                    labelRowsPerPage={t("elements.pagination.label")}
                                    component={"div"}
                                    onPageChange={handleChangeTasksPage}
                                    count={tasksCount}
                                    rowsPerPage={5}
                                    rowsPerPageOptions={[5]}
                                    page={tasksPage}
                                />
                            </Box>
                        </div>
                    </React.Fragment>
                    }
                    {idView == 3 &&
                        <DragAndDropView
                            tasks={tasksFiltered}
                            taskStates={taskStates}
                            selectedProjects={selectedProjects}
                            _setSelectedProjects={setSelectedProjects}
                            projects={projects}
                            _setSelectedIndexTask={setSelectedIndexTask}
                            _setSelectedTask={setSelectedTask}
                            _setIdView={setIdView}
                         />
                    }
                </Card>
                }
            </Container>
        </SiteWrapper>
    );
}

export default ProjectDailyPage;