// @flow
import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import UsersTabTRUE from "../components/configuration/UsersTabTRUE";
import ProfilesTabTRUE from "../components/configuration/ProfilesTabTRUE";
import SchedulesPage from './SchedulesPage';
//import { MIN_LEVEL_ADMIN } from "../util/Constants";
import { useAuthContext } from "../contexts/AuthenticationContext";

import "../MUIstyle/MUICustom.css";
import { TrueffortButtonSmall } from "../MUIstyle/MUICustom";
import Group from "@mui/icons-material/Group";
import Work from "@mui/icons-material/Work";
import WatchLater from "@mui/icons-material/WatchLater";
import { MIN_LEVEL_ADMIN } from "../util/Constants";
import { User } from "../util/Interfaces";
import WorkstationInvisibleTab from "../components/configuration/WorkstationInvisibleTab";

function Configuration() {
  const { t } = useTranslation();
  const { user } = useAuthContext() as { user: User };
  const [tabName, setTabName] = React.useState("users");
  return (
    <SiteWrapper>
      <Container maxWidth='xl' sx={{ marginTop: '20px' }}>
        <div className="title">
          <div className="title-small">{t("complete_view")}</div>
          <h2>{tabName == "users" ? t("user_page.title") :
              tabName == "profiles" ? t("Profiles") : t("Users.work_schedules")}</h2>
        </div>
        <Grid container spacing={1} rowSpacing={3} >
          <Grid xs={4} sm={2} md={1.5} lg={1} >
            <TrueffortButtonSmall fullWidth
                color="secondary"
                variant={tabName == "users" ? "contained" : "outlined"}
                onClick={() => setTabName("users")}
                startIcon={<Group />}
            >{t("user_page.title")}</TrueffortButtonSmall>
          </Grid>
          {user.role.accessLevel >= MIN_LEVEL_ADMIN &&
            <Grid xs={4} sm={2} md={1.5} lg={1} >
              <TrueffortButtonSmall fullWidth
                color="secondary"
                variant={tabName == "profiles" ? "contained" : "outlined"}
                onClick={() => setTabName("profiles")}
                startIcon={<Work />}
              >
                {t("Profiles")}
              </TrueffortButtonSmall>
            </Grid>
          }
          {user.role.accessLevel >= MIN_LEVEL_ADMIN &&
            <Grid xs={4} sm={2} md={1.5} lg={1} >
              <TrueffortButtonSmall fullWidth
                color="secondary"
                variant={tabName == "schedules" ? "contained" : "outlined"}
                onClick={() => setTabName("schedules")}
                startIcon={<WatchLater />}
              >
                {t("Users.work_schedules")}
              </TrueffortButtonSmall>
            </Grid>
          }
          {user.role.accessLevel >= MIN_LEVEL_ADMIN && (user.instance.idInstance == 2 || user.instance.idInstance == 156) &&
            <Grid xs={4} sm={2} md={1.5} lg={1} >
              <TrueffortButtonSmall fullWidth
                color="secondary"
                variant={tabName == "workstation" ? "contained" : "outlined"}
                onClick={() => setTabName("workstation")}
                startIcon={<WatchLater />}
              >
                {"Workstation"}
              </TrueffortButtonSmall>
            </Grid>
          }
        </Grid>
        <Grid style={{ marginTop: "1em" }}>
          {tabName == "users" && <UsersTabTRUE />}
          {tabName == "profiles" && <ProfilesTabTRUE />}
          {tabName == "schedules" && <SchedulesPage />}
          {tabName == "workstation" && <WorkstationInvisibleTab />}
        </Grid>
      </Container>
    </SiteWrapper>
  );
}

export default Configuration;
