import React from "react";
import {useTranslation} from "react-i18next";
import {Card, Paper, TableContainer, Table, TableHead, TableBody, Chip, Box, TablePagination} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useFormik} from "formik";
import ApplicationService from "../../services/application.service";
import { StyledTableCell, StyledTableRow, TrueffortCardTable, TrueffortSearchInput, TrueffortTableHeadCell, TrueffortInputLabel } from "../../MUIstyle/MUICustom";
import { Clear, Edit, Save } from "@mui/icons-material";
import {USER_LANGUAGE_KEY} from "../../util/Constants";
import { toast } from "react-toastify";
import CustomAvatar from "../custom/CustomAvatar";

function ApplicationsTabTRUE() {
    const {t} = useTranslation();
    const imageFile = React.useRef();
    const [imageInfo, setImageInfo] = React.useState();
    const [applications, setApplications] = React.useState([]);
    const [applicationsFiltered, setApplicationsFiltered] = React.useState([]);
    const applicationService = React.useMemo(() => new ApplicationService(), []);
    const [state, setState] = React.useState({isEditing: false, showFormAdd: false});
    let ROWS_PER_PAGE = 10;
    const [rowsPerPageApplications, setRowsPerPageApplications] = React.useState(ROWS_PER_PAGE);
    const [applicationsPage, setApplicationsPage] = React.useState(0);
    const [applicationsCount, setApplicationsCount] = React.useState(0);//Cantidad de categorias

    React.useEffect(() => {
      fillApplications();
    }, []);

    const fillApplications = () => {
      // @ts-ignore
      applicationService.findAll().then(({data: response}) => {
        if (Array.isArray(response.data)) {
          let arr = response.data;
          arr.sort((a, b) => a.name > b.name ? 1 : -1);
          setApplications(response.data);
          setApplicationsFiltered(response.data);
          setApplicationsCount(response.data.length);
        }
      });
    }

    const handleChangeSearchInput = (searchFilter) => {
      let items = searchFilter == "" ? applications.flat() : 
        applications.filter((data) => data.name.toLowerCase().includes(searchFilter.toLowerCase()));
      setApplicationsFiltered(items);
      setApplicationsCount(items.length);
    }

    const validate = (values) => {
        let errors = {};

        if (imageInfo) {
            if (imageInfo?.type !== "image/png" && imageInfo?.type !== "image/jpeg") {
                errors.image = 'forms.photoTypeValidation';
            } else if (imageInfo?.size > 1000000) {
                errors.image = 'forms.photoSizeValidation';
            }
        }

        return errors;
    };

    const handleEdit = (values) => {
      let dataRequest = {
        idApplication: values.idApplication,
        name: values.name
      }

      var formData = new FormData();
      if (imageInfo) {
        formData.append("image", imageFile.current.files[0], imageFile.current.files[0].name);
      } else {
        formData.append("image", new Blob([JSON.stringify(null)], {
          type: "application/json"
        }));
      }
      formData.append('applicationDto', new Blob([JSON.stringify(dataRequest)], {
        type: "application/json"
      }));

      // @ts-ignore
      applicationService.updateImage(formData).then(({data: response}) => {
        //console.log(response);
        formik.setSubmitting(false);
        //console.log("finish submiting")
        if (response.internalError != null && response.internalError && response.message != null) {
          toast.error(t(response.message));
        } else {
          toast.success(t("applications.msg_success_update"));
        }
        setState({...state, isEditing: false});
        fillApplications();
      });
    }

    const formik = useFormik({
      initialValues: {
        idApplication: 0,
        name: '',
        iconUrl: ''
      },
      validate: validate,
      onSubmit: (values, {setSubmitting, setValues}) => {
        handleEdit(values);
      }
    });

    const prepareEdit = (pro) => {
      pro["isEditing"] = true;
      formik.setValues(pro);
      setState({...state, isEditing: true});
    }

    function onChangeImage() {
        setImageInfo({
            name: imageFile.current?.files[0].name,
            size: imageFile.current?.files[0].size,
            type: imageFile.current?.files[0].type
        });
    }

    const handleChangeApplicationsRowsPerPage = (event) => {
        setRowsPerPageApplications(parseInt(event.target.value, 10));
        setApplicationsPage(0);
    }
    
    const handleChangeApplicationsPage = (event, newPage) => {
        setApplicationsPage(newPage);
    }

    return (
        <Card sx={{padding:'8px', background:'#EFEFEF', overflow:'unset'}}>
            <Grid container spacing={1} style={{marginBottom:'0.4em'}}>
                <Grid item lg={6} md={6}>
                    {/*<FormControl fullWidth>*/}
                        <TrueffortSearchInput
                            id="idSearchInputApplications" 
                            placeholder={t("applications.search")}
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleChangeSearchInput(e.target.value)}
                        />
                    {/*</FormControl>*/}
                </Grid>
            <TrueffortCardTable>
                <Grid container spacing={1} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                <TableContainer component={Paper} onSubmit={formik.handleSubmit} >
                    <Table responsive>
                        <TableHead>
                            <StyledTableRow>
                                <TrueffortTableHeadCell></TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>{t("Name")}</TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {applicationsFiltered.slice(applicationsPage * rowsPerPageApplications,
                                applicationsPage * rowsPerPageApplications + rowsPerPageApplications).map((app, k) => (
                                !app.isEditing ?
                                <StyledTableRow key={app.idApplication}>
                                    <StyledTableCell style={{width:'10%'}}>
                                      <CustomAvatar 
                                        url={app.imageDto?.url}
                                        code={app.name}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell style={{width:'80%'}}>
                                        {app.name}
                                    </StyledTableCell>
                                    <StyledTableCell style={{width:'10%'}}>
                                        <Chip size="small"
                                            icon={<Edit fontSize="small" />}
                                            variant="outlined"
                                            color='primary'
                                            disabled={state.isEditing || state.isCreating}
                                            onClick={() => prepareEdit(app)}
                                            label={t("elements.buttons.edit")}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow> :
                                <StyledTableRow key={app.idApplication}>
                                    <StyledTableCell colSpan={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6} md={4} style={{display: 'flex', flexDirection:'column', justifyContent:'space-around'/*, paddingLeft: '1em'*/}}>
                                                <TrueffortInputLabel labelstring={t("Name")} />
                                                {app.name}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TrueffortInputLabel labelstring={t("forms.change_image")} />
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        ref={imageFile}
                                                        className="custom-file-input"
                                                        lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                                        name="image"
                                                        disabled={formik.isSubmitting}
                                                        readOnly={formik.isSubmitting}
                                                        onChange={onChangeImage}
                                                        onBlur={formik.handleBlur}
                                                        accept="image/png, image/jpeg"
                                                    />
                                                    <label
                                                        className="custom-file-label"
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            display: "block",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {imageInfo?.name || t("forms.choose_file")}
                                                    </label>
                                                </div>
                                                {formik.errors?.image && imageInfo && !formik.isSubmitting ?
                                                        <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} style={{float:'right', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'end'}}>
                                                <Chip size="small"
                                                    icon={<Save fontSize="small" />}
                                                    variant="outlined"
                                                    color='success'
                                                    disabled={state.isSubmitting}
                                                    onClick={formik.handleSubmit}
                                                    label={t("elements.buttons.save")}
                                                />
                                                <div style={{ marginLeft: "0.5em" }} />
                                                <Chip size="small"
                                                    icon={<Clear fontSize="small" />}
                                                    onClick={() => {
                                                        app["isEditing"] = false;
                                                        formik.resetForm();
                                                        setState({...state, isEditing: false});
                                                    }}
                                                    label={t("elements.buttons.cancel")}
                                                    variant="outlined"
                                                    color="error"
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', justifyContent: "flex-end",
                        alignItems: "center", marginTop: '1em' }}>
                        <TablePagination
                            labelRowsPerPage={t("elements.pagination.label")}
                            rowsPerPageOptions={[5,10,15]}
                            component={"div"}
                            onRowsPerPageChange={handleChangeApplicationsRowsPerPage}
                            onPageChange={handleChangeApplicationsPage}
                            count={applicationsCount}
                            rowsPerPage={rowsPerPageApplications}
                            page={applicationsPage}
                        />
                    </Box>
                </div>
                </Grid>
            </TrueffortCardTable>
            </Grid>
        </Card>
    );
}

export default ApplicationsTabTRUE;