// @flow
import React from "react";
import { useFormik } from "formik";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import ProfileService from "../../services/profile.service";
import { useTranslation } from "react-i18next";
import ApplicationService from "../../services/application.service";
import { confirmAlert } from "react-confirm-alert";
// @ts-ignore
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card, FormControl, TableContainer, Table, TableHead,
  TableBody, Paper, TextField, Chip,
  Box, CardContent, TablePagination, Stack,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TrueffortButton, TrueffortButtonSmall, TrueffortSearchInput, TrueffortChip, TrueffortTableHeadCell, TrueffortCardTable, TrueffortTextField, TrueffortInputLabel, StyledTableRow, StyledTableCell } from "../../MUIstyle/MUICustom";
import { Add, DeleteOutline, Clear, Edit, Delete, Save } from '@mui/icons-material';
import Select from "react-select";
import { NUMBER_ITEMS_BY_PAGE } from "../../util/Constants";
import { styleReactSelect } from "../../MUIstyle/GlobalStyles";
import CardEmpty from "../custom/CardEmpty";
import CustomAvatar from "../custom/CustomAvatar";
import WorkstationService from "../../services/workstation.service";
import UserService from "../../services/user.service";
import { useState, useMemo } from "react";

const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = 'Required';
    } else if (values.name.length > 25) {
        errors.name = 'forms.nameLengthValidation25';
    }

    return errors;
};

const defaultStrings = {
    nameLabel: "Name",
    namePlaceholder: "Enter name",
    descriptionLabel: "Description",
    descriptionPlaceholder: "Enter description",
    toolsLabel: "Tools",
    toolsPlaceholder: "Select tools",
};

function WorkstationInvisibleTab() {
    const { user } = useAuthContext();
    const { t } = useTranslation();
    const profileService = React.useMemo(() => new ProfileService(), []);
    const applicationService = React.useMemo(() => new ApplicationService(), []);
    const workstationService = React.useMemo(() => new WorkstationService(), []);
    const userService = useMemo(() => new UserService(), []);
    const [applications, setApplications] = React.useState([]);
    const [applicationsValues, setApplicationsValues] = React.useState([]);
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState(null);
    const [newAppsValue, setNewAppsValue] = React.useState([]);
    const [profiles, setProfiles] = React.useState([]);
    const [profilesFiltered, setProfilesFiltered] = React.useState([]);
    const [showFormAdd, setShowFormAdd] = React.useState(false);
    const [state, setState] = React.useState({ loading: false, isEditing: false, isUpdating: false });
    const [rowsPerPageProfiles, setRowsPerPageProfiles] = React.useState(NUMBER_ITEMS_BY_PAGE);
    const [profilesPage, setProfilesPage] = React.useState(0);
    const [profilesCount, setProfilesCount] = React.useState(0);//Cantidad de categorias
    const [showLoader, setShowLoader] = React.useState(false);
    const [workstations, setWorkstations] = React.useState([]);
    const [isEnabledW, setIsEnabledW] = useState(false);

    React.useEffect(() => {
        fillWorkstation();
        fillUsers();
    }, [])

    const fillUsers = () => {
      // @ts-ignore
      userService.getAll().then(({ data: response }) => {
          if (Array.isArray(response)) {
            console.log(response);
              setUsers(response.filter((u) => u.isInvisible).map((usr) => {
                  return {
                      value: usr.idUser, label: usr.fullName
                  }
              }));
          }
      }).catch(({data:response}) => {
          console.error(response);
      }).finally(() => setShowLoader(false));
  }


    const fillWorkstation = () => {
      workstationService.getAllInvisible().then(({ data: response }) => {
        if (Array.isArray(response.data)) {
          setWorkstations(response.data)
        }
    });
    }

    const handleSearchKeyword = (search) => {
        let items = profiles.filter((data) => {
            if (search == "") {
                return profiles;
            } else {
                if (data.name?.toLowerCase().includes(search.toLowerCase()) ||
                    data.description?.toLowerCase().includes(search.toLowerCase())) {
                    return data;
                }
            }
        });
        setProfilesFiltered(items);
    }

    const handleApplicationSelect = (event) => {
        //console.log("handleApplicationSelect");
        //console.log(event);
        if (Array.isArray(event)) {
            setApplications(applications.map((item) => {
                return event.filter(item_ => item_.value === item.value).length !== 0 ? {
                    ...item,
                    isSelected: true
                } : { ...item, isSelected: false }
            }));
        }
    };

    const initialValues = { uuid: '', hostname: '', idUser: 0, isEnabled: false };

    const handleSubmit = (values) => {
        const dataRequest = {
          uuid: values.uuid,
          isEnabled: values.isEnabled,
          idUser: userValue?.value,
          idInstance: null
        };
        workstationService.getCreateInvisible(dataRequest).then(({ data: response }) => {
          formik.setSubmitting(false);
          if (response.internalError != null && response.internalError && response.message != null) {
              toast.error(response.message);
              console.log("Error #Profiles handleEdit" + JSON.stringify(response));
          } else {
              toast.success("Se creo correctamente correctamente");
          }
          setState({ ...state, isUpdating: false, isEditing: false });
          fillWorkstation();
      });
    }

    const handleEdit = (values) => {
        //console.log("submit");
        const dataRequest = {
          uuid: values.uuid,
          isEnabled: values.isEnabled,
          idUser: userValue.value,
          idInstance: null
        };

        workstationService.getUpdateInvisible(dataRequest).then(({ data: response }) => {
          formik.setSubmitting(false);
          if (response.internalError != null && response.internalError && response.message != null) {
              toast.error(response.message);
              console.log("Error #Profiles handleEdit" + JSON.stringify(response));
          } else {
              toast.success("Se actualizo correctamente");
          }
          setState({ ...state, isUpdating: false, isEditing: false });
          fillWorkstation();
      });
    }

    const formik = useFormik({
        initialValues: {
            uuid: '',
            hostname: '',
            idUser: 0,
            isEnabled: false
        },
        validate: validate,
        onSubmit: (values, { setSubmitting, setValues }) => {
          console.log(values);
            if (!state.isUpdating) {
                handleSubmit(values);
            } else {
                handleEdit(values);
            }
        },
    });

    /*const handleDelete = async (profile) => {
        confirmAlert({
            title: t("profile_page.msg_delete_confirm1"),
            message: t("profile_page.msg_delete_confirm2") + profile.name + "?",
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            profileService.delete(profile.idProfile).then(({ data: response }) => {
                                setProfiles(profiles.filter((profile_) => profile_.idProfile !== profile.idProfile));
                                setProfilesFiltered(profiles.filter((profile_) => profile_.idProfile !== profile.idProfile));
                                //setInfoMessage(t("profile_page.msg_success_delete"));
                                toast.success(t("profile_page.msg_success_delete"));
                            });
                        } catch (error) {
                            console.log("Error #Users handleDelete" + JSON.stringify(error));
                            //setErrorMessage("Ocurrió un error al eliminar perfil.")
                            toast.error("Ocurrió un error al eliminar perfil.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => { return; }
                }
            ]
        });
    }*/

    const handleCancel = () => {
        setNewAppsValue(null);
        setShowFormAdd(false);
        formik.setValues({ ...initialValues, isLoading: false, isCreating: false, isEditing: false });
        setState({...state, isEditing: false, isUpdating: false, isCreating: false});
    };

    const prepareEdit = (pro) => {
        //console.log(pro);
        pro["isEditing"] = true;
        formik.setValues({uuid: pro.uuid, isEnabled: pro.isEnabled, hostname: pro.hostname, idUser: pro?.user?.idUser});
        setIsEnabledW(pro.isEnabled);
        setState({ ...state, isUpdating: true, isEditing: true });
        let us = users.find((usr) => usr.value == pro?.user?.idUser && pro?.user?.idUser != null);
        if (us) {
          setUserValue({value: us.value, label: us.label});
        }
        
    }

    const handleChangeProfilesRowsPerPage = (event) => {
        setRowsPerPageProfiles(parseInt(event.target.value, 10));
        setProfilesPage(0);
    }
    
    const handleChangeProfilesPage = (event, newPage) => {
        setProfilesPage(newPage);
    }

    return (
        <Card sx={{ padding: "8px", background: "#F5F5F5", overflow: "unset" }}>
            <Grid container spacing={1} rowSpacing={3} style={{ marginBottom: "0.4em" }} justifyContent='space-evenly'>
                <Grid item xs={12} sm md lg={6}>
                    <FormControl fullWidth size="small">
                        {/*<TrueffortSearchInput
                            id="search-profile-input"
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(event) => handleSearchKeyword(event.target.value)}
                            placeholder={t("profile_page.search")}
                        />*/}
                    </FormControl>
                </Grid>

                {/*<Grid item xs={12} sm md lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <TrueffortButtonSmall
                        color="secondary"
                        variant={"contained"}
                        onClick={() => {
                            setShowFormAdd(true);
                            setState({ ...state, isUpdating: false, isCreating: true });
                        }}
                        disabled={state.isEditing}
                        startIcon={<Add />}
                        style={{ fontSize: "11px" }}
                    >{"Añadir estación de trabajo"}
                    </TrueffortButtonSmall>
                      </Grid>*/}
                {/*showFormAdd &&
                <Grid item xs={12}>
                    <Paper style={{ marginBottom: "0.4em" }}>
                        <CardContent>
                            <p style={{fontSize: '16px'}}>{t("profile_page.create_profile")}</p>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <TrueffortTextField 
                                        id={"field-name-profile-new"}
                                        name="name"
                                        size="small"
                                        required
                                        fullWidth
                                        value={formik.values.name}
                                        placeholder={t("profile_page.profile_name")}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                        labelString={t(defaultStrings.nameLabel)} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TrueffortInputLabel shrink labelString={t("Description")}></TrueffortInputLabel>
                                    <TextField 
                                        id={"field-description-profile-new"}
                                        name="description"
                                        variant="outlined"
                                        size="small"
                                        value={formik.values.description}
                                        placeholder={t("profile_page.profile_description")}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        multiline
                                        fullWidth
                                        sx={{background:'#F5F5F5'}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TrueffortInputLabel shrink
                                    labelString={t("Applications")} />
                                    <Select
                                        placeholder={t("profile_page.choose_tool")}
                                        isMulti
                                        value={newAppsValue}
                                        options={applications}
                                        onChange={(option) => {
                                            setNewAppsValue(option);
                                        }}
                                        styles={styleReactSelect}
                                    />
                                </Grid>
                                <Grid item sm={12} md={2} lg={2} >
                                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
                                        <TrueffortButton
                                            id="new-profile-button"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={formik.handleSubmit}
                                        >
                                            {t("elements.buttons.save")}
                                        </TrueffortButton>

                                        <TrueffortButton
                                            id="cancel-profile-button"
                                            size="small"
                                            color="secondary"
                                            variant={"outlined"}
                                            onClick={handleCancel}
                                            style={{ fontSize: "11px" }}
                                        >
                                            <Clear size="small" />
                                        </TrueffortButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Paper>
                </Grid>*/}
                {showLoader && 
                    <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
                        <CircularProgress />
                    </Stack>
                }
                {!showLoader && workstations.length == 0 && 
                    <CardEmpty><div>{t("msg.info.empty_response")}</div></CardEmpty>}
                {!showLoader && workstations.length > 0 && <TrueffortCardTable>
                    <Grid container spacing={1} rowSpacing={3} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                        <TableContainer component={Paper} sx={{ overflow: "unset" }}
                            onSubmit={formik.handleSubmit}
                        
                        >
                            <Table
                                responsive="true"
                            >
                                <TableHead>
                                    <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                        <TrueffortTableHeadCell>{"Identificador"}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{"Hostname"}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{"Usuario"}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{"Estado"}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {workstations.map((wo) => (
                                    !wo.isEditing ?
                                    <StyledTableRow key={wo.uuid}>
                                      <StyledTableCell >
                                          {wo.uuid}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                          {wo.hostname}
                                      </StyledTableCell>
                                      <StyledTableCell >
                                          {wo?.user?.email}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {wo.isEnabled ? "Activo" : "Inactivo"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <div style={{ display: 'flex' }}>
                                            <Chip size="small"
                                                icon={<Edit fontSize="small" />}
                                                variant="outlined"
                                                color='primary'
                                                disabled={state.isEditing || state.isCreating}
                                                onClick={() => prepareEdit(wo)}
                                                label={t("elements.buttons.edit")}
                                            />{/*
                                            <div style={{ marginLeft: "0.5em" }} />
                                            <Chip size="small"
                                                icon={<Delete fontSize="small" />}
                                                onClick={() => handleDelete(wo)}
                                                label={t("elements.buttons.delete")}
                                                disabled={state.isEditing || state.isCreating}
                                                variant="outlined"
                                                color="error"
                                            >
                                                <DeleteOutline fontSize="small" />
                                            </Chip>
                                            */}
                                        </div>
                                    </StyledTableCell>
                                  </StyledTableRow> :
                                  <StyledTableRow key={wo.uuid}>
                                    <StyledTableCell colSpan={5}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={3.5}>
                                          <TextField 
                                              id={"field-uuid-edi"}
                                              name="name"
                                              size="small"
                                              disabled
                                              value={formik.values.uuid}
                                              fullWidth
                                              sx={{ background: '#F5F5F5'}}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <TextField 
                                              id={"field-uuid-edi"}
                                              name="name"
                                              size="small"
                                              disabled
                                              value={formik.values.hostname}
                                              fullWidth
                                              sx={{ background: '#F5F5F5'}}
                                          />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                          <TrueffortInputLabel shrink labelString={"Usuarios"} />
                                          <Select
                                              placeholder={"Seleccione el usuario"}
                                              value={userValue}
                                              options={users}
                                              onChange={(option) => setUserValue(option)}
                                              styles={styleReactSelect}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <FormControlLabel control={<Switch color='success'
                                            checked={formik.values.isEnabled}
                                            onChange={(ev) => formik.setFieldValue('isEnabled',ev.target.checked)}
                                          />} label="Habilitado" />
                                        </Grid>
                                        <Grid item xs={2} style={{
                                            display: "flex", alignItems: "flex-end", justifyContent: "center"
                                        }}>
                                            <Stack spacing={1} direction={{ xs: 'column', lg: 'row' }} >
                                                <Chip size="small"
                                                    icon={<Save fontSize="small" />}
                                                    variant="outlined"
                                                    color='success'
                                                    onClick={() => handleEdit(formik.values)}
                                                    label={t("elements.buttons.save")}
                                                />

                                                <Chip size="small"
                                                    icon={<Clear fontSize="small" />}
                                                    onClick={() => {
                                                        wo["isEditing"] = false;
                                                        formik.resetForm();
                                                        setState({ ...state, isEditing: false });
                                                    }}
                                                    label={t("elements.buttons.cancel")}
                                                    variant="outlined"
                                                    color="error"
                                                />
                                            </Stack>
                                        </Grid>
                                      </Grid>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                            alignItems: "center", marginTop: '1em' }}>
                            <TablePagination
                                labelRowsPerPage={t("elements.pagination.label")}
                                rowsPerPageOptions={[5,10,15]}
                                component={"div"}
                                onRowsPerPageChange={handleChangeProfilesRowsPerPage}
                                onPageChange={handleChangeProfilesPage}
                                count={profilesCount}
                                rowsPerPage={rowsPerPageProfiles}
                                page={profilesPage}
                            />
                        </Box>
                        </div>
                    </Grid>
                </TrueffortCardTable>}
            </Grid>
        </Card>
    );
}

export default WorkstationInvisibleTab;
